import { DirectUpload } from '@rails/activestorage';
import * as Dropzone from 'dropzone';

Dropzone.options.dropzone = {
  maxFiles: 100,
  url: '/uploads/dropzone',
  clickable: true,
  addRemoveLinks: true,
  // timeout: 25000,
  init() {
    const form = this.element.closest('form');
    let submits = [];
    if (form) {
      submits = form.querySelectorAll("[type='submit']");
    }

    this.on('addedfile', function (file) {
      const url = this.element.querySelector('.file-field-upload').dataset.directUploadUrl;
      const upload = new DirectUpload(file, url);
      submits.forEach((submit) => submit.disabled = true);

      upload.create((error, blob) => {
        if (error) {
          const errorMarks = this.element.querySelectorAll('.dz-error-mark');
          const lastErrorMark = errorMarks[errorMarks.length - 1];
          if (!lastErrorMark) {
            return;
          }
          lastErrorMark.style.opacity = 1;
        } else {
          // Add an appropriately-named hidden input to the form with a value of blob.signed_id
          const input = document.createElement('input');
          input.setAttribute('type', 'hidden');
          input.setAttribute('name', 'file_uploads[]');
          input.setAttribute('value', blob.signed_id);
          input.dataset.file = blob.filename;
          this.element.appendChild(input);
        }
      });
    });

    this.on('queuecomplete', () => {
      submits.forEach((submit) => submit.disabled = false);
    });

    this.on('removedfile', (file) => {
      this.element.querySelector(`input[data-file="${file.name}"]`).remove();
    });
  },
};
