import { Controller } from 'stimulus';
import consumer from '../../channels/consumer';
import { replaceOrAppendHtmlFromString } from '../../utils';

export default class RemindersController extends Controller {
  static targets = [
    'reminderCount',
    'todayContainer',
    'tomorrowContainer',
    'laterContainer',
    'todayEmptyMessage',
    'tomorrowEmptyMessage',
    'laterEmptyMessage',
    'reminderCountToday',
    'reminderCountTomorrow',
    'reminderCountLater',
  ];

  flatPicker = null;

  connect() {
    const remindersController = this;
    const { userUuid } = this.element.dataset;

    consumer.subscriptions.create(
      { channel: 'RemindersChannel', user_uuid: userUuid },
      {
        received(data) {
          remindersController.received(data);
        },
      },
    );
  }

  keepOpen(event) {
    event.stopPropagation();
  }

  async received(data) {
    const { action } = data;

    if (action === 'new_reminder') {
      const { payload } = data;
      const partialUrl = payload.partial_url;
      const reminderCount = payload.reminder_count;
      const reminderPosition = payload.reminder_position;
      const notificationId = payload.notification_id;
      const reminderCountToday = payload.reminder_count_today;
      const reminderCountTomorrow = payload.reminder_count_tomorrow;
      const reminderCountLater = payload.reminder_count_later;

      const response = await fetch(partialUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        locals: { create_reminder: false },
      });

      if (response.status !== 204) {
        const json = await response.json();
        const { html } = json;

        const existingReminder = document.getElementById(`reminder_action_plan_alert_receipt_${notificationId}`);

        if (existingReminder) {
          existingReminder.remove();
        }

        switch (reminderPosition) {
          case 'today':
            replaceOrAppendHtmlFromString(html, this.todayContainerTarget);
            break;
          case 'tomorrow':
            replaceOrAppendHtmlFromString(html, this.tomorrowContainerTarget);
            break;
          default:
            replaceOrAppendHtmlFromString(html, this.laterContainerTarget);
        }
      }

      if (reminderCountToday > 0) {
        this.todayEmptyMessageTarget.classList.remove('show');
      } else {
        this.todayEmptyMessageTarget.classList.add('show');
      }

      if (reminderCountTomorrow > 0) {
        this.tomorrowEmptyMessageTarget.classList.remove('show');
      } else {
        this.tomorrowEmptyMessageTarget.classList.add('show');
      }

      if (reminderCountLater > 0) {
        this.laterEmptyMessageTarget.classList.remove('show');
      } else {
        this.laterEmptyMessageTarget.classList.add('show');
      }

      this.reminderCountTarget.innerHTML = reminderCount;
      this.reminderCountTodayTarget.innerHTML = reminderCountToday;
      this.reminderCountTomorrowTarget.innerHTML = reminderCountTomorrow;
      this.reminderCountLaterTarget.innerHTML = reminderCountLater;
    }
  }
}
