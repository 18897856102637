import { Controller } from 'stimulus';
import {FlashAlert} from '../packs/flash_alerts';

export default class ApiCredentialsController extends Controller {
  static targets = ['providerType', 'providerHtml'];

  providerTypeSelected() {
    if (this.providerTypeTarget.value) {
      this.loadForm();
    } else {
      this.providerHtmlTarget.innerHTML = '';
    }
  }

  async loadForm() {
    await Rails.ajax({
      type: 'GET',
      url: '/administration/api_credentials/render_form',
      data: `provider_type=${this.providerTypeTarget.value}`,
      success: (response) => {
        this.providerHtmlTarget.innerHTML = response.provider.html;
      },
      error: (error) => {
        console.warn(error);
      },
    });
  }
}
