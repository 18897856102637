import tippy from 'tippy.js';

document.addEventListener('DOMContentLoaded', () => {
  const tipTargetNodes = document.querySelectorAll('[data-tip]');
  const tipTargetElements = Array.from(tipTargetNodes);
  tipTargetElements.forEach((tipElement) => {
    const template = document.getElementById(tipElement.dataset.tip);
    if (!template) {
      return;
    }
    const container = document.createElement('div');
    container.appendChild(document.importNode(template.content, true));
    tippy(tipElement, {
      content: container.innerHTML,
      arrow: true,
      arrowType: 'round',
    });
  });
});
