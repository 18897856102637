import { Controller } from 'stimulus';
import * as basicLightbox from 'basiclightbox';

export default class LightboxController extends Controller {
  connect() {
    this.lightbox = basicLightbox.create(this.element.cloneNode(true));
  }

  show(event) {
    this.lightbox.show();
  }
}
