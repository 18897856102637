import { Controller } from 'stimulus';

export default class ThumbnailController extends Controller {
  static classes = ['notSelected', 'selected']

  static targets = ['checkbox', 'icon', 'screenshot', 'controls']

  static values = {
    selected: { type: Boolean, default: false },
    uuid: String,
  }

  applyChange({ target: { checked } }) {
    this.selectedValue = checked;
  }

  deselect() {
    this.setChecked(false);
  }

  selectedValueChanged() {
    this.dispatch(
      'change',
      {
        detail: {
          selected: this.selectedValue,
          uuid: this.uuidValue,
        },
      },
    );
  }

  select() {
    this.setChecked(true);
  }

  // Bootstrap switches use a JS DOM atrribute to determine their checked value,
  // not the standard 'checked' HTML attribute
  setChecked(val) {
    document.querySelector(`#${this.checkboxTarget.id}`).checked = val;
    this.selectedValue = val;
  }

  setSelected({ target: { checked } }) {
    this.selectedValue = checked;
  }

  viewSensitive() {
    this.screenshotTarget.classList.remove('is-sensitive');
    this.controlsTarget.remove();
  }
}
