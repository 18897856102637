import { Controller } from 'stimulus';

export default class extends Controller {
  static classes = ['hide']

  static targets = [
    'captureCard',
    'displayOnSelected',
    'form',
    'formField',
    'hideOnSelectAll',
    'selectedCount',
  ]

  static values = {
    selectedUuids: { type: Array, default: [] },
  }

  addSelectedUuid(uuid) {
    if (this.selectedUuidsValue.includes(uuid)) { return; }

    this.selectedUuidsValue = [...this.selectedUuidsValue, uuid];
  }

  buildField(uuid) {
    const field = document.createElement('input');
    field.type = 'hidden';
    field.name = 'uuids[]';
    field.multiple = true;
    field.value = uuid;
    field.setAttribute('data-captures--thumbnail-selection-target', 'formField');

    return field;
  }

  buildFields() {
    return this.selectedUuidsValue.map((uuid) => this.buildField(uuid));
  }

  cards() {
    if (this.hasCaptureCardTarget) {
      return this.captureCardTargets.map((t) => {
        this.application.getControllerForElementAndIdentifier(
          t,
          'captures--thumbnail',
        );
      });
    }

    return [];
  }

  removeDeselectedUuid(uuid) {
    const newUuids = [];
    this.selectedUuidsValue.forEach((selectedUuid) => {
      if (selectedUuid !== uuid) {
        newUuids.push(selectedUuid);
      }
    });

    this.selectedUuidsValue = newUuids;
  }

  selectAll() {
    this.dispatch(
      'selectAll',
      {
        prefix: 'capturesThumbnailSelection',
      },
    );
  }

  selectNone() {
    this.dispatch(
      'selectNone',
      {
        prefix: 'capturesThumbnailSelection',
      },
    );
  }

  selectedUuidsValueChanged() {
    this.dispatch(
      'selectedUuidsChange',
      {
        detail: {
          selectedUuids: this.selectedUuidsValue,
        },
        bubbles: true,
        cancelable: false,
        prefix: 'capturesThumbnailSelection',
      },
    );
    this.updateCountTargets();
    this.updateDisplayOnSelectedTargets();
    this.updateDisplayOnHideOnSelectAllTargets();
    this.updateForms();
  }

  thumbnailChange({ detail: { selected, uuid } }) {
    if (selected) {
      this.addSelectedUuid(uuid);
    } else {
      this.removeDeselectedUuid(uuid);
    }
  }

  updateCountTargets() {
    this.selectedCountTargets.forEach((t) => {
      t.innerHTML = this.selectedUuidsValue.length;
    });
  }

  updateDisplayOnHideOnSelectAllTargets() {
    if (this.selectedUuidsValue.length === this.captureCardTargets.length) {
      this.hideOnSelectAllTargets.forEach((t) => t.classList.add(this.hideClass));
    } else {
      this.hideOnSelectAllTargets.forEach((t) => t.classList.remove(this.hideClass));
    }
  }

  updateDisplayOnSelectedTargets() {
    if (this.selectedUuidsValue.length > 0) {
      this.displayOnSelectedTargets.forEach((t) => t.classList.remove(this.hideClass));
    } else {
      this.displayOnSelectedTargets.forEach((t) => t.classList.add(this.hideClass));
    }
  }

  updateForms() {
    if (this.hasFormTarget) {
      this.formFieldTargets.forEach((ff) => ff.remove());
      this.formTargets.forEach((form) => {
        const fields = this.buildFields();
        fields.forEach((field) => form.appendChild(field));
      });
    }
  }
}
