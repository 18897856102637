import { Controller } from 'stimulus';
import Peer from 'peerjs';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { datadogLogs } from '@datadog/browser-logs';
import html2canvas from 'html2canvas';
import consumer from '../channels/consumer';

export default class DeviceSessionController extends Controller {
  static targets = [
    'video',
    'viewers',
    'offlineThumbnail',
    'onlineThumbnail',
    'deviceSession',
  ];

  static CONNECTION_STATES = {
    ONLINE: 'connected',
    OFFLINE: 'disconnected',
    ERROR: 'error',
  };

  static MAX_RECONNECTS = 2;

  static VIDEO_TIME_REFRESH = 30000;

  static OFFLINE_WAIT_TIME = 2500;

  peerInitialised = false;

  cableInitialised = false;

  deviceChannel;

  peer;

  peerConnection;

  peerConfigResolver;

  peerConfigPromise;

  unloading = false;

  reconnects = 0;

  viewers = [];

  videoInterval;

  offlineInterval;

  reconnectAllowed = true;

  peerSent = false;

  get peerConfig() {
    if (this.peerConfigPromise) return this.peerConfigPromise;

    this.peerConfigPromise = new Promise((resolve) => {
      this.peerConfigResolver = resolve;
      let res = false;
      const retry = setInterval(() => {
        if (res && this.deviceChannel) {
          clearInterval(retry);
          return;
        }
        res = this.deviceChannel.perform('request_config');
      }, 300);
    });

    return this.peerConfigPromise;
  }

  get currentUserId() {
    return this.data.get('current-user-id');
  }

  get deviceUuid() {
    return this.deviceSessionTarget.dataset.deviceUuid;
  }

  get apiBase() {
    return this.deviceSessionTarget.getAttribute('data-hub-url');
  }

  get lastSeenAt() {
    const lastSeenAtData = this.data.get('lastSeenAt');
    if (lastSeenAtData) return moment(lastSeenAtData);

    return null;
  }

  get deviceSessionId() {
    return this.data.get('id');
  }

  get deviceSessionUuid() {
    return this.data.get('uuid');
  }

  get connectionState() {
    return this.data.get('connection');
  }

  get videoCurrentTime() {
    const time = this.data.get('videoCurrentTime');
    return time ? parseInt(time, 10) : 0;
  }

  set connectionState(state) {
    this.selectable = state === DeviceSessionController.CONNECTION_STATES.ONLINE;
    this.data.set('connection', state);
    this.toggleOfflineThumbnail();

    const deviceOffline = state === DeviceSessionController.CONNECTION_STATES.OFFLINE;
    const connectionError = state === DeviceSessionController.CONNECTION_STATES.ERROR;

    if (deviceOffline || connectionError) {
      if (this.unloading) return;
      this.setOfflineInterval();
    } else if (this.offlineInterval) {
      clearInterval(this.offlineInterval);
      this.offlineInterval = null;
    }
  }

  set videoCurrentTime(time) {
    const flooredTime = Math.floor(time);
    if (flooredTime > this.videoCurrentTime) this.lastSeenAt = Date.now();
    this.data.set('videoCurrentTime', flooredTime);
  }

  set lastSeenAt(timestamp) {
    const momentTimestamp = moment(timestamp);
    if (!this.lastSeenAt) this.data.set('lastSeenAt', momentTimestamp.toISOString());
    if (momentTimestamp.isAfter(this.lastSeenAt)) this.data.set('lastSeenAt', momentTimestamp.toISOString());
  }

  connect() {
    window.addEventListener('beforeunload', () => this.pageUnloading());
    this.getLatestThumbnailImage();
    this.establishConnection();
  }

  async establishConnection() {
    let retryCount = 0;

    while (!this.cableInitialised || !this.peerInitialised) {
      if (retryCount >= 5) throw new Error('Unable to establish connection');
      // eslint-disable-next-line no-await-in-loop
      if (!this.cableInitialised) await this.initialiseCable();
      // eslint-disable-next-line no-await-in-loop
      if (!this.peerInitialised) await this.initialisePeer();
      retryCount += 1;
    }
    this.sendPeerIdToDevice();
  }

  initialiseCable() {
    return new Promise((resolve, reject) => {
      // We've already created a Cable connection
      if (this.deviceChannel) {
        resolve('Cable Already Initialised');
        return;
      }

      const deviceController = this;
      this.deviceChannel = consumer.subscriptions.create(
        {
          channel: 'DeviceSessionChannel',
          device_session_id: deviceController.deviceSessionId,
        },
        {
          connected() {
            deviceController.cableInitialised = true;
            resolve('Cable Initialised');
          },
          received(data) {
            deviceController.cableReceived(data);
          },
          rejected() {
            reject(new Error('Cable Rejected'));
          },
        },
      );
    });
  }

  async initialisePeer() {
    const peerjsConfig = await this.peerConfig;
    const { path, config, host } = peerjsConfig;
    return new Promise((resolve) => {
      let debug = 2;
      const urlParams = new URLSearchParams(window.location.search);
      const userDebugLevel = urlParams.get('peerJsDebug');

      if (userDebugLevel) debug = userDebugLevel;

      const peerData = {
        host,
        path,
        debug,
        config,
      };

      this.peer = new Peer(uuidv4(), peerData);

      this.peer.on('open', (id) => {
        this.peerOnOpen(id);
        resolve('Peer Initialised');
        this.peerInitialised = true;
      });

      this.peer.on('connection', this.peerOnConnection.bind(this));
      this.peer.on('call', this.peerOnCall.bind(this));
      this.peer.on('close', this.peerOnClose.bind(this));
      this.peer.on('disconnected', this.peerOnDisconnected.bind(this));
      this.peer.on('error', this.peerOnError.bind(this));
    });
  }

  // Sending our peer id down notifies the device it should call us with video
  async sendPeerIdToDevice() {
    if (this.peerSent || !this.reconnectAllowed || this.unloading) return;

    // If peer hasn't been set or the ID has become invalid,
    // initialise peer and early exit - this part will be looped back to
    if (!this.peer?.id) {
      datadogLogs.logger.warn(
        `sendPeerIdToDevice: peer or peer.id missing (deviceSessionId: ${this.deviceSessionId})`,
        { deviceSessionId: this.deviceSessionId },
      );
      await this.initialisePeer();
      return;
    }

    // eslint-disable-next-line camelcase
    const peer_id = this.peer.id;
    this.deviceChannel.perform('send_video', { peer_id });
  }

  cableReceived({ action, payload }) {
    switch (action) {
      case 'peer_config': {
        this.peerConfigResolver(payload.config);
        break;
      }
      case 'video_status': {
        this.videoStatusReceived(payload);
        break;
      }
      case 'seen': {
        this.deviceSessionSeen(payload);
        break;
      }
      default: {
        break;
      }
    }
  }

  peerOnOpen(id) {
    if (!this.peer) {
      datadogLogs.logger.warn(
        `peerOnOpen: no peer (deviceSessionId: ${this.deviceSessionId})`,
        { deviceSessionId: this.deviceSessionId },
      );
      return;
    }
    datadogLogs.logger.info(
      `peerOnOpen: peer opened with id ${id} (deviceSessionId: ${this.deviceSessionId})`,
      { deviceSessionId: this.deviceSessionId },
    );
  }

  peerOnConnection(connectionData) {
    // Allow only a single connection
    if (this.peerConnection) {
      connectionData.on('open', () => {
        connectionData.send('Already connected to another client');
        setTimeout(() => {
          connectionData.close();
        }, 500);
      });
      return;
    }
    this.peerConnection = connectionData;
  }

  peerOnDisconnected() {
    this.connectionState = DeviceSessionController.CONNECTION_STATES.OFFLINE;
  }

  peerOnClose() {
    this.peerConnection = null;
    this.connectionState = DeviceSessionController.CONNECTION_STATES.OFFLINE;
  }

  peerOnError() {
    this.connectionState = DeviceSessionController.CONNECTION_STATES.ERROR;
  }

  peerOnCall(mediaConnection) {
    mediaConnection.on('stream', this.mediaOnStream.bind(this));
    // Answer the call, providing our mediaStream
    mediaConnection.answer();
  }

  mediaOnStream(mediaStream) {
    this.connectionState = DeviceSessionController.CONNECTION_STATES.ONLINE;
    const video = this.videoTarget;
    video.srcObject = mediaStream;
    video.onloadedmetadata = () => {
      video.play();
    };

    this.videoInterval = setInterval(() => {
      this.videoCurrentTime = video.currentTime;
    }, DeviceSessionController.VIDEO_TIME_REFRESH);

    this.monitorPeerConnection();
  }

  monitorPeerConnection() {
    const connectionKey = Object.keys(this.peer.connections)[0];
    const connection = this.peer.connections[connectionKey][0];

    // debug
    window.connection = connection;

    if (!connection) return;

    connection.peerConnection.onconnectionstatechange = this.onConnectionStateChange.bind(this);
  }

  // eslint-disable-next-line consistent-return
  onConnectionStateChange(event) {
    const state = event.currentTarget.connectionState;

    // For any state that's not 'connected', set state to offline to trigger a reconnect,
    // and remove the cached peer object
    switch (state) {
      case 'disconnected':
      case 'closed':
      case 'failed':
        datadogLogs.logger.warn(
          `onConnectionStateChange: ${state} (deviceSessionId: ${this.deviceSessionId})`,
          { deviceSessionId: this.deviceSessionId, state },
        );
        this.connectionState = DeviceSessionController.CONNECTION_STATES.OFFLINE;
        break;
      default:
        return null;
    }
  }

  deviceSessionSeen(payload) {
    const { last_seen_at: lastSeen } = payload;
    const lastSeenAt = moment(lastSeen);
    this.lastSeenAt = lastSeenAt;
  }

  // eslint-disable-next-line consistent-return
  videoStatusReceived(payload) {
    const { status } = payload;

    switch (status) {
      case 'offline': {
        this.connectionState = DeviceSessionController.CONNECTION_STATES.OFFLINE;
        break;
      }
      case 'error': {
        this.connectionState = DeviceSessionController.CONNECTION_STATES.ERROR;
        break;
      }
      default:
        return null;
    }
  }

  setOfflineInterval() {
    this.offlineInterval = setInterval(() => {
      this.reconnect();
    }, DeviceSessionController.OFFLINE_WAIT_TIME);
  }

  reconnect() {
    this.sendStopVideoToDevice();
    if (this.reconnects < DeviceSessionController.MAX_RECONNECTS) {
      this.reconnects += 1;
      this.sendPeerIdToDevice();
    } else {
      this.clearCableAndPeer();
      this.establishConnection();
    }
  }

  // Tell a device to stop sending us video, we're no longer showing video stream
  sendStopVideoToDevice() {
    // Don't send if we don't have a peer
    if (!this.peer) return;

    // eslint-disable-next-line camelcase
    const peer_id = this.peer.id;
    this.deviceChannel.perform('stop_video', { peer_id });
  }

  clearCableAndPeer() {
    this.reconnectAllowed = false;
    this.reconnects = 0;
    this.deviceChannel = null;
    if (this.peer) this.peer.destroy();
    this.peer = null;
    this.peerInitialised = false;
    this.cableInitialised = false;

    if (this.videoInterval) {
      clearInterval(this.videoInterval);
      this.videoInterval = null;
    }

    if (this.offlineInterval) {
      clearInterval(this.offlineInterval);
      this.offlineInterval = null;
    }
  }

  toggleOfflineThumbnail() {
    if (this.unloading) return;
    if (
      this.connectionState === DeviceSessionController.CONNECTION_STATES.ONLINE
    ) {
      if (this.videoTarget.classList.contains('thumbnail__state--blurred')) {
        this.videoTarget.classList.remove('thumbnail__state--blurred');
        return;
      }

      if (this.onlineThumbnailTarget.classList.contains('d-none')) {
        this.onlineThumbnailTarget.classList.toggle('d-none');
      }

      if (!this.offlineThumbnailTarget.classList.contains('d-none')) {
        this.offlineThumbnailTarget.classList.toggle('d-none');
      }
    } else {
      if (!this.onlineThumbnailTarget.classList.contains('d-none')) {
        this.onlineThumbnailTarget.classList.toggle('d-none');
      }

      if (this.offlineThumbnailTarget.classList.contains('d-none')) {
        this.offlineThumbnailTarget.classList.toggle('d-none');
      }
    }
  }

  toggleFullscreen() {
    if (!this.videoTarget.fullscreenElement) {
      this.videoTarget.requestFullscreen();
    } else if (this.videoTarget.exitFullscreen) {
      this.videoTarget.exitFullscreen();
    }
  }

  // Called when the window event `beforeUnload' is triggered
  pageUnloading() {
    this.unloading = true;
    this.sendStopVideoToDevice();
    this.clearCableAndPeer();
  }

  handlePresence({ action, payload }) {
    if (action === 'presence_check_in' || action === 'presence_ping') {
      const viewersSet = new Set([
        payload.user_uuid,
        this.currentUserId,
        ...this.viewers,
      ]);
      this.viewers = [...viewersSet];
    } else {
      const filteredViewers = this.viewers.filter((viewer) => viewer !== payload.user_uuid);
      const viewersSet = new Set([this.currentUserId, ...filteredViewers]);
      this.viewers = [...viewersSet];
    }

    if (action === 'presence_check_in') {
      this.deviceChannel.perform('presence_ping');
    }

    const viewersEvent = new CustomEvent('viewers:change', {
      bubbles: true,
      detail: {
        uuid: this.deviceSessionUuid,
        hasOtherViewers: this.viewers.length - 1 > 0,
      },
    });
    this.element.dispatchEvent(viewersEvent);
    this.viewersTarget.dataset.state = this.viewers.length - 1 > 0;
  }

  handleDeviceChange() {
    this.viewersTarget.dataset.state = this.viewers.length - 1 > 0;
  }

  async getLatestThumbnailImage() {
    const response = await fetch(`${this.apiBase}/thumb/${this.deviceUuid}?cachebuster=0`)
      .then((res) => res)
      .then((returnedResponse) => returnedResponse);

    if (response.ok) {
      const blob = await response.blob();
      const fileReader = new FileReader();

      fileReader.onload = (e) => {
        this.videoTarget.poster = e.target.result;
      };

      fileReader.readAsDataURL(blob);
    }
  }

  saveToDevice() {
    this.takeScreenshotAndDownload();
  }

  takeScreenshotAndDownload() {
    this.takeScreenshot((canvas) => {
      canvas.toBlob((blob) => {
        this.downloadBlobAsFile(blob, 'screenshot.png');
      }, 'image/png');
    });
  }

  // eslint-disable-next-line class-methods-use-this
  takeScreenshot(callback) {
    const canvasBody = document.getElementById('canvas');

    html2canvas(canvasBody).then((canvas) => {
      if (callback) {
        callback(canvas);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  downloadBlobAsFile(blob, filename) {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();

    URL.revokeObjectURL(link.href);
  }

  // eslint-disable-next-line class-methods-use-this
  async downloadTabs() {
    const websites = document.querySelectorAll('.activity__subtitle');
    const websiteStrings = Array.from(websites).map(
      (website) => website.innerHTML,
    );
    const params = new URLSearchParams(window.location.search);
    const deviceSessionId = params.get('device_session');
    const groupId = params.get('group_id');
    const url = `/groups/${groupId}/device_sessions/download_tabs`;

    const csvData = {
      deviceSessionId,
      studentName: document.querySelector('h1').innerHTML.trim(),
      websites: websiteStrings,
    };

    const response = await fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
      },
      body: JSON.stringify(csvData),
    });

    const csvBlob = await response.blob();
    const filename = `${csvData.studentName
      .toLowerCase()
      .split(' ')
      .join('_')}_browser_history.csv`;
    this.downloadBlobAsFile(csvBlob, filename);
  }
}
