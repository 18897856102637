import { Controller } from 'stimulus';
import * as chroma from 'chroma-js';

export default class ConcernColoursController extends Controller {
  get defaultColour() {
    const defaultColour = getComputedStyle(document.documentElement)
      .getPropertyValue('--colour-grey-light') || '#B7B7B7';
    return defaultColour.trim();
  }

  connect() {
    const colourMap = this.data.get('colours');
    if (!colourMap) {
      return;
    }
    this.data.delete('colours');
    const defaultColourMap = {
      default: this.defaultColour,
    };
    const parsedColourMap = JSON.parse(colourMap);
    this.colourMap = { ...defaultColourMap, ...parsedColourMap };
    this.createConcernColourStylesheet();
  }

  /**
   * @private
   */
  createConcernColourStylesheet() {
    this.createStylesheet();
    this.addStyles();
  }

  /**
   * @private
   */
  createStylesheet() {
    if (this.stylesheet) {
      return;
    }
    // Create the <style> tag
    const style = document.createElement('style');

    // WebKit hack :(
    style.appendChild(document.createTextNode(''));

    // Add the <style> element to the page
    document.head.appendChild(style);

    this.stylesheet = style.sheet;
  }

  addStyles() {
    Object.entries(this.colourMap).forEach(([concernKindKey, colour]) => {
      if (!colour) {
        return;
      }
      const selector = `.colour-concern--${concernKindKey}`;
      const styles = ConcernColoursController.createStylesFromColour(colour);

      this.stylesheet.addRule(selector, styles.join('; '));
    });
  }

  /**
   *
   * @param colour
   * @returns {string[]}
   */
  static createStylesFromColour(colour) {
    const base = chroma(colour);
    const gradientFrom = 'var(--colour-concern)';
    const gradientTo = base.brighten(0.5);

    const textLight = chroma.mix(base, 'white', 0.85, 'rgb');
    const textDark = chroma.mix(base, 'black', 0.85, 'rgb');

    const backgroundBase = base;
    const textForBackgroundBase = backgroundBase.luminance() > 0.45 ? textDark : textLight;

    const styleBase = `--colour-concern: ${base.css()}`;
    const styleFrom = `--colour-from: ${gradientFrom}`;
    const styleTo = `--colour-to: ${gradientTo.css()}`;

    const styleText = `--colour-text: ${base.css()}`;
    const styleTextLight = `--colour-text-light: ${textLight.css()}`;
    const styleTextDark = `--colour-text-dark: ${textDark.css()}`;

    const styleBgBase = `--colour-background: ${backgroundBase.css()}`;
    const styleTextForBgBase = `--colour-text-for-bg: ${textForBackgroundBase.css()}`;

    const backgroundLight = base.luminance(0.8);
    const textForBackgroundLight = backgroundLight.luminance() > 0.45 ? textDark : textLight;
    const styleBgLight = `--colour-background-light: ${backgroundLight.css()}`;
    const styleTextForBgLight = `--colour-text-for-bg-light: ${textForBackgroundLight.css()}`;

    return [
      styleBase,
      styleFrom,
      styleTo,
      styleText,
      styleTextDark,
      styleTextLight,
      styleBgBase,
      styleTextForBgBase,
      styleBgLight,
      styleTextForBgLight,
    ];
  }
}
