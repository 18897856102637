import { Controller } from 'stimulus';

export default class FilterableController extends Controller {
  static targets = ["filterable"];

  filter(event){
    event.preventDefault()

    this.filterableTargets.forEach((el, i) => {
      const targetFound = !el.dataset.filterKey.includes(event.target.value.toLowerCase())
      el.classList.toggle('d-none', targetFound)
    })
  }
}
