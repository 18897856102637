import { Controller } from 'stimulus';
import * as flatpickr from 'flatpickr';

export default class DatepickerController extends Controller {
  get options() {
    return {
      enableTime: false,
      altInput: true,
      altFormat: 'j F Y',
      dateFormat: 'j F Y',
      time_24hr: false,
      disableMobile: true,
      onOpen: (selectedDates, dateStr, instance) => {
        if (this.element.readOnly) {
          instance.close();
        }
      },
    };
  }

  connect() {
    this.datepicker = flatpickr(this.element, this.options);
  }

  disconnect() {
    this.datepicker.destroy();
  }
}
