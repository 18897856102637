import { Controller } from 'stimulus';
import consumer from '../channels/consumer';

export default class SingleDeviceController extends Controller {
  static targets = ['thumbnail']

  connect() {
    consumer.subscriptions.create({ channel: 'ClassSessionChannel', group_id: this.thumbnailTarget.dataset.groupId });
  }
}
