import {Controller} from 'stimulus';

export default class KeywordController extends Controller {
  static targets = ["severitySelect", "kindSelect", "categorySelect"]

  connect() {
    this.initialiseSelect2();
    if (this.kindSelect !== undefined) {
      const {value: selectedConcernKind} = this.kindSelect;
      this.updateCategories(selectedConcernKind);
    }
  }

  get severitySelect() {
    if (this.hasSeveritySelectTarget) {
      return this.severitySelectTarget;
    }
  }

  get kindSelect() {
    if (this.hasKindSelectTarget) {
      return this.kindSelectTarget;
    }
  }

  get categorySelects() {
    if (this.hasCategorySelectTarget) {
      return this.categorySelectTargets;
    }
    return []
  }

  get linkedSelects() {
    const selects = [this.kindSelect, ...this.categorySelects]
    return selects.filter(select => !!select)
  }

  get selects() {
    const selects = [this.severitySelect]
    return selects.filter(select => !!select)
  }

  initialiseSelect2() {
    $(this.selects).select2({
      allowClear: true
    });

    $(this.linkedSelects).on("change.select2", (event) => {
      const {target} = event;
      const changeEvent = new CustomEvent("linked.change", {bubbles: true})
      target.dispatchEvent(changeEvent)
    })
  }

  /**
   *
   * @param event {CustomEvent}
   */
  setCategories(event) {
    if (!(event instanceof CustomEvent)) {
      return
    }

    const {target} = event;
    if (!target) {
      return;
    }

    // Check to see if the event target is the kind or category
    // The Kind Select will not have a concernKind
    const {dataset: {concernKind}} = target
    if (concernKind) {
      return;
    }

    // Get the concern kind value
    const {value: selectedConcernKind} = target
    this.updateCategories(selectedConcernKind);
  }

  updateCategories(selectedConcernKind) {
    // Hide categorySelects that are not of this kind
    // Uncheck any options that have been selected that are not of this kind
    this.categorySelects.forEach(categorySelect => {
      const {dataset: {concernKind: categoryKind}} = categorySelect
      const kindsMismatch = selectedConcernKind !== categoryKind;
      categorySelect.parentElement.classList.toggle("d-none", kindsMismatch)
      if (kindsMismatch) {
        categorySelect.value = null;
        categorySelect.dispatchEvent(new Event("change"))
      }
    })
  }
}
