import { Controller } from 'stimulus';

export default class DeviceActivitiesController extends Controller {
  static targets = ["activitiesContainer", "indicator", "order", "page", "sortForm"];

  static values = {
    order: String,
    page: Number
  };

  connect() {
    this.order = this.orderTarget.value || "desc";
    this.page = this.pageTarget.value || "1";
    this.setSort(this.order);
  }

  setSort(order) {
    const indicator = this.indicatorTarget;

    this.orderTarget.value = order;
    this.order = order;

    if (order == "asc") {
      indicator.classList.remove("fa-sort-down");
      indicator.classList.add("fa-sort-up");
    } else {
      indicator.classList.remove("fa-sort-up");
      indicator.classList.add("fa-sort-down");
    }
  }

  sorted(event) {
    const [data] = event.detail;
    const { order, page } = this;

    this.activitiesContainerTarget.innerHTML = data.payload.html;

    if (window.history.replaceState) {
      window.history.replaceState({ order }, window.document.title, `?order=${order}&page=${page}`);
    }
  }

  toggleSort(_event) {
    const order = (this.order == "desc" ? "asc" : "desc");

    this.setSort(order)
  }
}
