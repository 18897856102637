import {Controller} from 'stimulus';
import consumer from "../../channels/consumer";

export default class PersonDeviceSessionsController extends Controller {
  get personId() {
    return this.data.get('person-id');
  }

  deviceSessionsChannel;

  connect() {
    this.deviceSessionsChannel = consumer.subscriptions.create(
      { channel: 'Person::DeviceSessionChannel', person: this.personId },
      { async received(data) { } },
    );
  }

  async received(data) { }
}
