import { Controller } from 'stimulus';

export default class extends Controller {
  static classes = ['hide']

  // toggleNav() {
  //   if (this.element.classList.contains(this.hideClass)) {
  //     this.element.classList.remove(this.hideClass);
  //   } else {
  //     this.element.classList.add(this.hideClass);
  //   }
  // }
}
