import { Controller } from 'stimulus';

export default class FormController extends Controller {
  static targets = ['form', 'saveBtn'];

  connect() {
    this.setModalListener();
  }

  setModalListener() {
    $('#label_capture_modal, #flag_capture_modal').on('hidden.bs.modal', e => {
      this.toggleSave();
      this.formTarget.reset();
      this.saveBtnTarget.disabled = true;
    });
  }

  toggleSave() {
    if (this.saveBtnTarget.disabled === false) return;
    this.saveBtnTarget.disabled = false;
  }
}
