import { Controller } from 'stimulus';

export default class ConcernRemindersController extends Controller {
  newReminder() {
    const event = new CustomEvent('concern:new_reminder', {
      detail: {
        fromConcern: true,
        notificationUrl: this.element.dataset.notificationUrl,
      },
    });
    window.dispatchEvent(event);
  }
}
