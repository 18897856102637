import {Controller} from 'stimulus';
import {appendHtmlFromString, replaceOrInsertHtmlFromString, incrementCountElement} from "../../utils";
import SearchController from "../search_controller";

export default class SidebarController extends Controller {
  static targets = ["groupComponent", "toggleable", "membersContainer", "spinner"]

  toggleGroup() {
    this.groupComponentTarget.classList.toggle("expandable--expanded")
    this.toggleableTargets.forEach(target => {
      target.classList.toggle("show");
    })
    const currentPage = +this.groupComponentTarget.dataset.currentPage;

    if (this.groupComponentTarget.classList.contains("expandable--expanded") && currentPage === 0) {
      this.loadGroupMembers(currentPage);
    }
  }

  loadGroupMembers(currentPage) {
    const url = this.groupComponentTarget.dataset.url;
    Rails.ajax({
      url: url,
      type: "get",
      data: `current_page=${currentPage}`,
      dataType: "json",
      success: (data) => {
        this.spinnerTarget.classList.remove("show")
        this.groupComponentTarget.dataset.currentPage = currentPage + 1;

        data.members.forEach((member) => {
          appendHtmlFromString(member, this.membersContainerTarget);
        });
      }
    })
  }

  scroll() {
    const tolerance = 0.8;
    const currentScroll = this.membersContainerTarget.scrollTop;
    const expectedScroll = this.membersContainerTarget.scrollHeight - this.membersContainerTarget.offsetHeight;

    if (Math.abs(currentScroll - expectedScroll) < tolerance) {
      const currentPage = +this.groupComponentTarget.dataset.currentPage;
      this.loadGroupMembers(currentPage);
    }
  }

  /**
   * Bulk add children from a group to form
   */
  addMembersToForm () {
    const groupComponent = this.groupComponentTarget;
    const groupId = groupComponent.dataset.groupId;
    const outerScope = this;
    Rails.ajax({
      url: `/groups/members/single_group?group_id=${groupId}`,
      type: "get",
      dataType: "json",
      success: (data) => {
        const participantsGroup = document.querySelectorAll('[id^=group_participants_group]')[0];
        const parent = participantsGroup.querySelector('.group-members');
        const target = participantsGroup.querySelector('.add-member');
        const membersInner = groupComponent.parentElement.querySelector('.members');

        data.members.forEach(function (html) {
          replaceOrInsertHtmlFromString(html, parent, target, "before");
        });

        let count = document.querySelectorAll('.group_participant').length;
        incrementCountElement(document.getElementById('member-count-number'), +`${count}`, document.getElementById('member-count-text'), true);
        this.toggleGroupAddIcon(groupComponent.querySelector('.group-add'));
        outerScope.updateGroupMembersCount(groupComponent.parentElement, data.members.length);

        // Toggle member icons within group
        if (groupId !== "all") {
          outerScope.toggleMembersIcon(membersInner);
          let allChildrenMemberComponents = this.allChildrenContainer().querySelectorAll('.member-component');
          allChildrenMemberComponents.forEach(function (memberElement) {
            let memberId = memberElement.dataset.memberId;
            outerScope.updateSidebarChildren(memberId, true);
          });

          outerScope.checkFullGroups();
          return;
        }

        // Toggle member icons for all groups, when the bulk add button for All Children is used
        outerScope.membersContainers().forEach(function (membersContainer) {
          let membersInner = membersContainer.querySelector('.members');
          outerScope.toggleGroupAddIcon(membersContainer.querySelector('.group-add'));
          outerScope.toggleMembersIcon(membersInner);
        });
        // Set the number of members added for each group to full
        outerScope.setFullMemberCounts();
      }
    });
  }

  /**
   * Switch all plus icons to check for all members within a members container
   * @param membersInner
   */
  toggleMembersIcon(membersInner) {
   membersInner.querySelectorAll('.fa-check-circle').forEach(function (icon) {
      icon.classList.add('show');
    });
    membersInner.querySelectorAll('.fa-plus-circle').forEach(function (icon) {
      icon.classList.remove('show');
    });
  }

  /**
   * Receive group_form:change event and triggers the necessary processes
   * @param event
   */
  formChanged(event) {
    const counterElement = document.getElementById('member-count-number');
    const counterTextElement = document.getElementById('member-count-text')
    incrementCountElement(counterElement, event.detail.quantityChange, counterTextElement);
    this.updateSidebarChildren(event.detail.memberId, event.detail.quantityChange > 0);
  }

  /**
   * Toggle check/plus icons on all instances of a member
   * @param id
   * @param isAddAction
   */
  updateSidebarChildren(id, isAddAction) {
    const checkedIcons = document.getElementsByClassName(`checked-icon-${id}`);
    const plusIcons = document.getElementsByClassName(`plus-icon-${id}`);

    if (isAddAction) {
      checkedIcons.forEach(icon => {
        icon.classList.add("show");
      });
      plusIcons.forEach(icon => {
        icon.classList.remove("show");
        let groupContainer = icon.parentElement.parentElement.parentElement;
        let count = parseInt(groupContainer.dataset.membersRetrieved) + 1;
        this.updateGroupMembersCount(groupContainer, count);
        this.checkAndToggleFullGroup(groupContainer);
      });
    } else {
      checkedIcons.forEach(icon => {
        icon.classList.remove("show");
      });
      plusIcons.forEach(icon => {
        icon.classList.add("show");
        let groupContainer = icon.parentElement.parentElement.parentElement;
        this.toggleGroupAddIcon(groupContainer.querySelector('.group-add'), true);
        let count = parseInt(groupContainer.dataset.membersRetrieved) - 1;
        this.updateGroupMembersCount(groupContainer, count);
      });
      const allChildrenIcon = this.allChildrenContainer().querySelector('.group-add');
      this.toggleGroupAddIcon(allChildrenIcon, true);
    }
  }

  /**
   * Toggle plus / check icon next to each Group name
   * @param icon
   * @param disable
   */
  toggleGroupAddIcon(icon, disable) {
    if (!icon) {
      return;
    }

    if (disable) {
      icon.classList.add('far');
      icon.classList.add('fa-plus-circle');
      icon.classList.remove('fas');
      icon.classList.remove('fa-check-circle');
      return;
    }

    icon.classList.remove('far');
    icon.classList.remove('fa-plus-circle');
    icon.classList.add('fas');
    icon.classList.add('fa-check-circle');
  }

  /**
   * Used by checkAndToggleFullGroup()
   *
   * Update data attribute on Group container .members-container to keep track of how many members of the group
   * have been added to the form, in order to toggle the appropriate icon next to the group name.
   *
   * This implementation is necessary because you can bulk add members of a group to the form without
   * all of them being visible on the page, which means it wasn't previously possible to keep track of
   * the number of added members from each group
   *
   * @param membersContainer
   * @param value
   */
  updateGroupMembersCount(membersContainer, value) {
    if (!membersContainer) {
      return;
    }

    membersContainer.dataset.membersRetrieved = value;
  }

  /**
   * Calls the above for every group, passing in the full number of members for each group
   */
  setFullMemberCounts() {
    const outerScope = this;
    document.querySelectorAll('.members-container').forEach(function (membersContainer) {
      let count = membersContainer.querySelector('.children-count').innerHTML;
      outerScope.updateGroupMembersCount(membersContainer, count);
    });
  }

  /**
   * Check whether the entirety of a group has been added and toggle the appropriate icon (plus or check) accordingly
   * @param groupContainer
   */
  checkAndToggleFullGroup(groupContainer) {
    if (!groupContainer) {
      return;
    }

    const groupTotalCount = parseInt(groupContainer.querySelector('.children-count').innerHTML);

    // If adding this child means every child within the group has been added, change the plus icon next to group name into a check icon
    if (groupContainer.dataset.membersRetrieved >= groupTotalCount) {
      this.toggleGroupAddIcon(groupContainer.querySelector('.group-add'));
      this.checkFullGroups();
    }
  }

  /**
   * Check if all groups have been added, and toggle the appropriate icon (plus or check) accordingly for All Children group
   */
  checkFullGroups() {
    const membersContainer = this.membersContainers();
    const totalCount = membersContainer.length;
    // -1 to take into account All Children group
    const totalGroupsCount = totalCount - 1;
    const fullGroupsCount = document.querySelectorAll('.group-add.fa-check-circle').length;
    const outerScope = this;

    if (fullGroupsCount >= totalGroupsCount) {
      document.querySelectorAll('.group-add.fa-plus-circle').forEach(function (icon) {
        outerScope.toggleGroupAddIcon(icon);
      });
      return;
    }

    this.toggleGroupAddIcon(this.allChildrenContainer().querySelector('.group-add'), true);
  }

  allChildrenContainer() {
    return document.querySelector('.members-container-all');
  }

  membersContainers() {
    return document.getElementsByClassName('members-container');
  }
}
