import { Controller } from 'stimulus';

export default class ReportsPieChart extends Controller {

  connect() {
    this.keys = JSON.parse(this.data.get('keys'));
  }

  select(e) {
    const category_breakdown_chart = Object.values(Chart.instances).find(instance => instance.chart.canvas.id === "category_breakdown");
    if (!category_breakdown_chart) {
      return
    }
    const activePoints = category_breakdown_chart.getElementsAtEvent(e);
    if (activePoints && activePoints.length > 0) {
      const label = activePoints[0]["_model"]["label"];
      window.location.href = `/reports/concerns/categories/${this.keys[label]}`
    }
  }
}
