import { Controller } from 'stimulus';

export default class NewFeatureModalController extends Controller {
  static targets = ['listItem', 'prevBtn', 'nextBtn', 'acknowledgeCheckbox', 'form'];

  static values = { index: Number };

  connect() {
    $('#updates-notification-modal').modal({
      backdrop: 'static',
      keyboard: false,
    });
  }

  next() {
    this.indexValue++;

    if (this.prevBtnTarget.disabled == true) {
      this.prevBtnTarget.disabled = false;
    }

    if (this.indexValue == this.listItemTargets.length - 1) {
      this.nextBtnTarget.disabled = true;
    }
  }

  previous() {
    this.indexValue--;

    if (this.nextBtnTarget.disabled == true) {
      this.nextBtnTarget.disabled = false;
    }

    if (this.indexValue == 0 && this.prevBtnTarget.disabled == false) {
      this.prevBtnTarget.disabled = true;
    }
  }

  indexValueChanged() {
    this.showListItem();
  }

  showListItem() {
    this.listItemTargets.forEach((element, index) => {
      element.hidden = index !== this.indexValue;
    });
  }

  hideUpdate() {
    if (this.acknowledgeCheckboxTarget.checked != true) return;
    this.formTarget.submit();
  }
}
