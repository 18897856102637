import { Controller } from 'stimulus';
import { appendHtmlFromString, getAjaxData } from '../utils';
import { FlashAlert } from '../packs/flash_alerts';

export default class KeywordListsController extends Controller {
  static targets = [
    'editBtn',
    'saveEditBtn',
    'nameText',
    'descriptionText',
    'nameTextInput',
    'descriptionTextInput',
    'visibilityText',
    'visibilitySelect',
    'keywords',
    'formTemplate',
  ];

  editList() {
    this.showEditFields();
  }

  save() {
    this.hideEditFields();
  }

  showEditFields() {
    this.editBtnTarget.classList.remove('show');
    this.saveEditBtnTarget.classList.add('show');

    this.nameTextTarget.classList.remove('show');
    this.nameTextInputTarget.classList.add('show');

    this.descriptionTextTarget.classList.remove('show');
    this.descriptionTextInputTarget.classList.add('show');
  }

  hideEditFields() {
    this.editBtnTarget.classList.add('show');
    this.saveEditBtnTarget.classList.remove('show');

    this.nameTextTarget.classList.add('show');
    this.nameTextInputTarget.classList.remove('show');

    this.descriptionTextTarget.classList.add('show');
    this.descriptionTextInputTarget.classList.remove('show');
  }

  keywordCreated(event) {
    const { action, payload } = getAjaxData(event);
    if (action === 'create') {
      if (payload.status === 'ok') {
        const { html } = payload;
        appendHtmlFromString(html, this.keywordsTarget);
        const { currentTarget } = event;
        this.refreshForm(currentTarget);
      } else {
        FlashAlert.error(payload.message);
      }
    }
  }

  refreshForm(formContainer) {
    const template = this.formTemplateTarget;
    const formElement = template.content.cloneNode(true);
    formContainer.replaceChild(formElement, formContainer.children[0]);
  }
}
