import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['selectAll', 'itemCheckbox', 'deleteSelected'];

  connect() {
    const itemCheckboxes = this.itemCheckboxTargets;

    itemCheckboxes.forEach((item) => {
      item.addEventListener('change', this.toggleDeleteButtonVisibility.bind(this));
    });
  }

  selectAll() {
    const itemCheckboxes = this.itemCheckboxTargets;
    const isChecked = this.selectAllTarget.checked;

    itemCheckboxes.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.checked = isChecked;
    });

    this.toggleDeleteButtonVisibility();
  }

  toggleDeleteButtonVisibility() {
    const deleteButton = this.deleteSelectedTarget;
    const checkedCheckboxes = this.itemCheckboxTargets.filter((item) => item.checked);

    if (checkedCheckboxes.length > 0) {
      deleteButton.style.visibility = '';
    } else {
      deleteButton.style.visibility = 'hidden';
    }
  }

   async deleteSelected() {
    const { url } = this.deleteSelectedTarget.dataset;
    const itemCheckboxes = this.itemCheckboxTargets;
    const selectedItems = [];

    itemCheckboxes.forEach((item) => {
      if (item.checked) {
        selectedItems.push(item.value);
      }
    });

    const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
        },
        body: JSON.stringify({ items: selectedItems }),
      });

    if (response.ok) {
      location.reload();
    } else {
      console.error(response.status);
    };
  }
}
