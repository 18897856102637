import {Controller} from 'stimulus'
import {closeModal, getAjaxData, replaceHtmlFromString, deSelectAllGridItems, setListState, createElementFromString} from "../../utils";

export default class WebsiteListsItemController extends Controller {
  static targets = ['modal'];

  async updateItem(event) {
    const {action, payload} = getAjaxData(event)

    if (action === "website_list_updated") {
      replaceHtmlFromString(payload.html, this.element)
      this.updateAllDeviceIcons();
    }
    if (action === "website_list_destroyed") {
      this.element.remove()
      this.updateAllDeviceIcons();
    }
    closeModal(this.modalTarget)
  }

  async updateAllDeviceIcons() {
    const iconContainers = document.querySelectorAll('.thumbnail__icons');

    if (!iconContainers) {
      return;
    }

    Array.from(iconContainers).forEach(async (container) => {
      const targetedContainer = container;
      const sessionId = targetedContainer.dataset.sessionId;

      if (sessionId) {
        const iconsUrl = `/device_sessions/${sessionId}/icons`;

        const response = await fetch(iconsUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
        });
        const { html } = await response.json();

        if (html) {
          const newIconsPartial = createElementFromString(html);
          targetedContainer.innerHTML = newIconsPartial.innerHTML;
        }
      }
    });
  }

  appliedToDevice(event) {
    const { action, payload } = getAjaxData(event);
    setListState({ action, payload });
    deSelectAllGridItems(this.element);
  }
}
