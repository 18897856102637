import {Controller} from "stimulus";

export default class ZoomController extends Controller {
  static targets = ['step'];

  /**
   * @public
   * @param e {Event}
   */
  change(e) {
    const {currentTarget: zoomButton} = e;
    const {zoom: level} = zoomButton.dataset;
    this.stepTargets.forEach(step => {
      step.classList.toggle('zoom__step--selected', step === zoomButton)
    });
    this.data.set('level', level);
    const event = new CustomEvent('zoom:changed', {detail: level});
    this.element.dispatchEvent(event);
  }

  /**
   * @public
   * @param e {Event}
   */
  zoomIn(e) {
    const currentSelectedIndex = this.currentSelectedIndex();
    const nextStep = this.stepTargets[currentSelectedIndex + 1];
    this.selectStep(nextStep)
  }

  /**
   * @public
   * @param e {Event}
   */
  zoomOut(e) {
    const currentSelectedIndex = this.currentSelectedIndex();
    const previousStep = this.stepTargets[currentSelectedIndex - 1];
    this.selectStep(previousStep);
  }

  /**
   * @private
   * @param step {Element}
   */
  selectStep(step) {
    if (!step) {
      return;
    }
    const clickEvent = new Event('click');
    step.dispatchEvent(clickEvent);
  }

  /**
   * @private
   * @returns {number}
   */
  currentSelectedIndex() {
    const currentSelected = this.stepTargets.find(step => {
      const stepLevel = step.dataset.zoom;
      const zoomLevel = this.data.get('level');
      return stepLevel === zoomLevel
    });
    return this.stepTargets.indexOf(currentSelected);
  }
}
