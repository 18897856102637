import { Controller } from 'stimulus';

export default class TestDataController extends Controller {
  static targets = ['matCheckbox', 'schoolCheckbox', 'matField', 'schoolField'];

  checkedMat(event) {
    if (event.currentTarget.checked) {
      this.matFieldTarget.disabled = true;
    } else {
      this.matFieldTarget.disabled = false;
      this.schoolFieldTarget.disabled = false;
      this.schoolCheckboxTarget.checked = false;
    }
  }

  checkedSchool(event) {
    if (event.currentTarget.checked) {
      this.matFieldTarget.disabled = true;
      this.matCheckboxTarget.checked = true;
      this.schoolFieldTarget.disabled = true;
    } else {
      this.schoolFieldTarget.disabled = false;
    }
  }
}
