import { Controller } from 'stimulus';

export default class MicrosoftTenantsController extends Controller {
  static targets = ['microsoftGraph', 'fullImport', 'noImport', 'automaticLoginCredentials'];

  toggleMicrosoftGraph() {
    if (this.fullImportTarget.checked) {
      this.microsoftGraphTarget.classList.remove('d-none');
    } else {
      this.microsoftGraphTarget.classList.add('d-none');
      this.noImportTarget.checked = true;
      this.automaticLoginCredentialsTarget.checked = true;
    }
  }
}
