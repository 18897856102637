import {Controller} from 'stimulus';

export default class AzureAdMappingController extends Controller {
  static targets = ["kindSelect", "groupSelect"]

  connect() {
    this.initialiseSelect2();
  }

  get kindSelect() {
    if (this.hasKindSelectTarget) {
      return this.kindSelectTarget;
    }
  }

  get groupSelect() {
    if (this.hasGroupSelectTarget) {
      return this.groupSelectTarget;
    }
  }

  get linkedSelects() {
    const selects = [this.groupSelect]
    return selects.filter(select => !!select)
  }

  get selects() {
    const selects = [this.kindSelect, this.groupSelect]
    return selects.filter(select => !!select)
  }

  initialiseSelect2() {
    $(this.selects).select2({
      allowClear: true
    });
    $(this.linkedSelects).on("change.select2", (event) => {
      const {target} = event;
      const changeEvent = new CustomEvent("linked.change", {bubbles: true})
      target.dispatchEvent(changeEvent)
    })
  }

  setGroupName(event) {
    if (!(event instanceof CustomEvent)) {
      return
    }

    const {target} = event;
    if (!target) {
      return;
    }

    const selectedFieldId = this.groupSelect.id
    const targetFieldId = selectedFieldId.replace("team_identifier", "team_name")
    const selectedGroupName = this.groupSelect.options[this.groupSelect.selectedIndex].text
    const groupNameField = document.getElementById(targetFieldId)

    groupNameField.value = selectedGroupName
  }
}
