import { Controller } from 'stimulus';

export default class ConcernHistoryController extends Controller {
  static targets = [
    "printLink"
  ];

  connect() {
    this.printLinkTarget.addEventListener('click', this.printHistory)
  }

  printHistory(e) {
    e.preventDefault();

    const target = document.getElementById('history');

    try {
      target.contentWindow.document.execCommand('print', false, null);
    } catch(e) {
      target.contentWindow.print();
    }
  }
}
