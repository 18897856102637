import { Controller } from 'stimulus';
import { getAjaxData } from '../utils';

export default class LoadMoreController extends Controller {
  loadMoreData(event) {
    const { action, payload } = getAjaxData(event);
    const { html } = payload;
    if (action !== 'load_more') {
      return;
    }
    this.element.innerHTML = html;
  }
}
