import { Controller } from 'stimulus';

export default class ClipboardController extends Controller {
  static targets = ["source"];

  connect() {
    if (document.queryCommandSupported("copy")) {
      this.element.classList.add("clipboard--supported")
    }
  }

  copy() {
    this.sourceTarget.select();
    document.execCommand("copy");
  }
}
