import { Controller } from 'stimulus';

export default class googleOrganisationalUnitsModalController extends Controller {
  static targets = ['searchBox', 'tableRow', 'ouCheckbox', 'selectedOrganisationalUnitsCounter'];

  openModal() {
    this.selectedOrganisationalUnitsCounterTarget.blur();
    this.checkedOUs = [];
    this.ouCheckboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        this.checkedOUs.push(checkbox.value);
      }
    });
  }

  modalDone() {
    let selected = 0;
    this.ouCheckboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        selected += 1;
      }
    });
    console.log(this.selectedOrganisationalUnitsCounterTarget);
    this.selectedOrganisationalUnitsCounterTarget.value = `${selected} ${I18n.t('assets.javascripts.controllers.google_credentials.form.amount_selected')}`;
    console.log(this.selectedOrganisationalUnitsCounterTarget);
  }

  cancelModal() {
    this.ouCheckboxTargets.forEach((checkbox) => {
      if (this.checkedOUs.includes(checkbox.value)) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    });
  }

  delaySearch(event) {
    const source = event.target;
    let timer = source.getAttribute('data-timer');
    $('body').on('hide.bs.modal', () => {
      this.searchBoxTarget.value = '';
      this.searchGroup();
    });

    clearTimeout(timer);
    timer = setTimeout(this.searchGroup.bind(this), 500);
    source.setAttribute('data-timer', timer);
  }

  searchGroup() {
    const searchText = this.searchBoxTarget.value.toLowerCase();
    const rows = this.tableRowTargets;

    rows.forEach((element) => {
      const searchMatch = element.innerText.toLowerCase().indexOf(searchText) > -1;
      element.classList.toggle('d-none', !searchMatch);
    });
  }
}
