import { Controller } from 'stimulus';

export default class extends Controller {
  static classes = ['blur'];

  static targets = ['toBlur', 'removeOnShow'];

  static values = {
    isSensitive: { type: Boolean, default: false },
  }

  connect() {
    if (!this.isSensitiveValue) {
      this.removeOnShowTargets.forEach((t) => t.remove());
    }
  }

  toBlurTargetConnected(el) {
    if (this.isSensitiveValue) {
      el.classList.add(this.blurClass);
    }
  }

  viewSensitive() {
    this.toBlurTargets.forEach((t) => t.classList.remove(this.blurClass));
    this.removeOnShowTargets.forEach((t) => t.remove());
  }
}
