import { Controller } from 'stimulus';
import { deSelectAllGridItems, setIconState } from '../../utils';

export default class DevicesControlsController extends Controller {
  created(event) {
    const [data] = event.detail;
    const { action } = data;
    if (['send_logout', 'send_message', 'open_website'].includes(action)) {
      this.handleSentMessage();
    }
    if (['screen_lock', 'internet_access'].includes(action)) {
      this.handleIconStates(data);
    }
  }

  /**
   * @private
   * @param {Object<status, message>}payload
   */
  handleSentMessage() {
    this.resetElement();
  }

  /**
   * @private
   * @param {string} action
   * @param {Object<status, message>}payload
   */
  handleIconStates({ action, payload }) {
    this.resetElement();
    setIconState({ action, payload });
  }

  /**
   * @private
   */

  resetElement() {
    this.element.reset();
    deSelectAllGridItems(this.element);
  }
}
