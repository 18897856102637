import {Controller} from 'stimulus';

export default class CredentialController extends Controller {
  static targets = ["operatingSystemSelect", "allowLogoutSelect"];

  connect() {
    this.initialiseSelect2();
  }

  get operatingSystemSelect() {
    if (this.hasOperatingSystemSelectTarget) {
      return this.operatingSystemSelectTargets;
    }
  }

  get allowLogoutSelect() {
    const selects = this.hasAllowLogoutSelectTarget ? this.allowLogoutSelectTargets : null
    console.log(selects)
    return selects;
  }

  get selects() {
    const selects = [...this.operatingSystemSelect, ...this.allowLogoutSelect]
    return selects.filter(select => !!select)
  }

  initialiseSelect2() {
    $(this.selects).select2({
      allowClear: true
    });
  }
}
