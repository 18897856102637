import { Controller } from 'stimulus';
import { incrementCountElement } from '../../utils';

export default class GroupsMembersCountController extends Controller {
  static targets = ['number', 'text'];

  /**
   * Receive group_form:change event and triggers the necessary processes
   * @param event
   */
  formChanged(event) {
    if (!(/\/groups\/new$/.test(window.location.href))) {
      const counterElement = this.numberTarget;
      const counterTextElement = this.textTarget;
      incrementCountElement(counterElement, event.detail.quantityChange, counterTextElement);
    }
  }
}
