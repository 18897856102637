import { Controller } from 'stimulus';
import * as Awesomplete from 'awesomplete';

export default class AddKeywordModifier extends Controller {
  static targets = ['search', 'keyword'];

  async connect() {
    this.searchInput = this.initializeAwesomplete();
    if (!this.searchInput) {
      return;
    }
    const data = await this.getListData();
    this.setSearchList(data.keywords);
  }

  async getListData() {
    const response = await fetch(`/keyword_modifiers/unique_keywords?system=${this.searchTarget.dataset.system}`);
    return response.json();
  }

  open() {
    this.element.classList.add('search__wrapper--active');
  }

  close() {
    this.element.classList.remove('search__wrapper--active');
  }

  /* eslint no-param-reassign: "error" */
  selected(event) {
    this.keywordTarget.value = event.text.value;
  }

  setSearchList(list = []) {
    const lists = [];
    list.forEach((keyword) => {
      lists.push({ id: keyword[0], plainText: keyword[1] });
    });
    this.searchInput.list = lists;
  }

  disableInput() {
    this.searchInput.disabled = true;
  }

  initializeAwesomplete() {
    const options = {
      data(item) {
        return { label: item.plainText, value: item.id };
      },
      replace(suggestion) {
        this.input.value = suggestion.label;
      },
    };
    return new Awesomplete(this.searchTarget, options);
  }
}
