import { Controller } from 'stimulus';

export default class extends Controller {
  static classes = ['selected'];

  static targets = ['option', 'valueElement'];

  static values = {
    selected: String,
  }

  changeSelection({ params: { selection }, srcElement }) {
    this.updateSelectionTo(srcElement);
    this.selectedValue = selection;
  }

  selectedValueChanged() {
    this.valueElementTarget.setAttribute('value', this.selectedValue);
  }

  updateSelectionTo(el) {
    this.optionTargets.forEach((t) => {
      if (t === el) {
        t.classList.add(...this.selectedClasses);
      } else {
        t.classList.remove(...this.selectedClasses);
      }
    });
  }
}
