import { Controller } from 'stimulus';
import { FlashAlert } from '../../packs/flash_alerts';

export default class BroadcastController extends Controller {
  static targets = ['broadcastControls', 'startTeacherBroadcast', 'teacherBroadcastForm', 'startStudentBroadcast', 'endBroadcast', 'modal'];

  static room = null;

  static screenTrack = null;

  setActiveBroadcast() {
    this.broadcastControlsTarget.classList.add('broadcast-controls-container--active');
  }

  setInactiveBroadcast() {
    this.broadcastControlsTarget.classList.remove('broadcast-controls-container--active');
  }

  connect() {
    if (window.broadcastCache) {
      if (window.broadcastCache.deviceId) {
        // eslint-disable-next-line max-len
        this.setStudentEndBroadcastPath(window.broadcastCache.data.id, window.broadcastCache.deviceId);
      } else {
        this.setEndBroadcastPath(window.broadcastCache.data.id);
      }
      this.room = window.broadcastCache.room;
      this.setActiveBroadcast();
      this.element.dataset['deviceSessions-ControlsDisabled'] = false;
    }
  }

  disconnectFromRoom() {
    window.broadcastCache = null;

    // Disconnect from room and clear and un-publish local tracks if available
    if (this.room) {
      if (this.room.localParticipant) {
        this.room.localParticipant.tracks.forEach((publication) => {
          publication.track.stop();
          publication.unpublish();
          publication.track.detach();
          publication.track.disable();
        });
      }

      this.room.disconnect();
    }

    if (this.screenTrack) {
      this.screenTrack.stop();
      this.screenTrack = null;
    }
  }

  /**
   * Retrieve and save screen track
   */
  getScreenTrack() {
    const video = require('twilio-video');
    const outerScope = this;
    navigator.mediaDevices.getDisplayMedia().then((stream) => {
      outerScope.screenTrack = video.LocalVideoTrack(stream.getTracks()[0]);
      Rails.fire(outerScope.teacherBroadcastFormTarget, 'submit');
    }).catch(() => {
      FlashAlert.error(this.startTeacherBroadcastTarget.dataset.error);
    });
  }

  /**
   * Connect to Twilio room once it's created to share screen
   *
   * @param event
   */
  teacherBroadcastCreated(event) {
    const { connect } = require('twilio-video');
    const outerScope = this;
    const [data] = event.detail;
    const roomId = data.broadcast.broadcast_room_id;

    connect(data.host_token, {
      name: roomId,
      automaticSubscription: false,
      video: false,
      audio: false,
    }).then((room) => {
      this.room = room;
      this.cacheBroadcast(data.broadcast);
      this.setEndBroadcastPath(data.broadcast.id);
      outerScope.setActiveBroadcast();
      room.localParticipant.publishTrack(outerScope.screenTrack);

      // When the teacher clicks "Stop Sharing Screen" in the browser, end the broadcast
      room.localParticipant.on('trackStopped', () => {
        outerScope.endBroadcast();
      });
    });
    $(this.modalTarget).modal('hide');
  }

  studentBroadcastCreated(event) {
    const [data] = event.detail;
    this.cacheBroadcast(data.broadcast, data.host_device_id)
    this.setStudentEndBroadcastPath(data.broadcast.id, data.host_device_id);
    this.setActiveBroadcast();
    $(this.modalTarget).modal('hide');
  }

  setEndBroadcastPath(broadcastId) {
    this.endBroadcastTarget.dataset.url = `/devices/broadcast_screens/${broadcastId}`;
  }

  setStudentEndBroadcastPath(broadcastId, deviceId) {
    this.endBroadcastTarget.dataset.url = `/devices/broadcast_screens/${broadcastId}?producer_id=${deviceId}`;
  }

  endBroadcast() {
    // Only send update request if valid URL has been set
    this.disconnectFromRoom();
    if (this.endBroadcastPath !== '#') {
      Rails.ajax({
        url: this.endBroadcastPath,
        type: 'delete',
        dataType: 'json',
        success: (() => {
          this.setInactiveBroadcast();
        }),
      });
    }
  }

  get endBroadcastPath() {
    return this.endBroadcastTarget.dataset.url;
  }

  cacheBroadcast(broadcast, deviceId = null) {
    window.broadcastCache = {};
    window.broadcastCache.data = broadcast;
    window.broadcastCache.deviceId = deviceId;
    window.broadcastCache.room = this.room;
  }

  /* eslint class-methods-use-this: ['error',
    { 'exceptMethods': ['broadcastModal'] }] */

  broadcastModal() {
    const modalEvent = new CustomEvent('broadcast:modal');
    window.dispatchEvent(modalEvent);
  }
}
