import {Controller} from 'stimulus';

export default class StartController extends Controller {
  static targets = ['section'];

  toggleUpload(event) {
    const {currentTarget} = event;
    const {value} = currentTarget;
    this.sectionTargets.forEach(sectionTarget => {
      const {dataset} = sectionTarget;
      const {section} = dataset;
      sectionTarget.classList.toggle('collapse', value !== section)
    })
  }
}
