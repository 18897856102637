import {Controller} from 'stimulus'

export default class FaviconController extends Controller {
  /* TODO: Resume faviconkit if/when service resumes
  static targets = ["loading", "image"]

  connect() {
    // If the image was cached and doesn't need loading
    if(this.imageTarget.complete){
      this.loadingTarget.classList.add('d-none')
    }
    else {
      this.imageTarget.classList.add('d-none')
    }
  }

  loaded(event){
    this.imageTarget.classList.remove('d-none')
    this.loadingTarget.classList.add('d-none')
  } */
}
