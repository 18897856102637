import {Controller} from 'stimulus';

export default class ConcernsConcernCategoriesController extends Controller {
  static targets = ['message', 'modal', 'title']

  categorySelected(event) {
    const target = event.currentTarget;
    const itemChecked = target.checked;
    const message = target.dataset.popMessage;
    const title = target.dataset.popTitle;

    if (itemChecked) {
      this.messageTarget.innerHTML = message;
      this.titleTarget.innerHTML = title;
      $(this.modalTarget).modal("show");
    }
  }
}
