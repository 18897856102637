import { Controller } from 'stimulus';

export default class AutomaticLoginSettingsController extends Controller {
  static targets = ['saveButton']

  addSetting(event) {
    const button = event.currentTarget;
    const { operatingSystem } = button.dataset;
    const settingsTable = document.getElementById(`${operatingSystem}_settings_tables`);
    const dataFieldSelect = document.getElementById(`${operatingSystem}_fields`);
    const removeDomainCheckbox = document.getElementById(`${operatingSystem}_remove_domain`);
    const allowLogoutCheckbox = document.getElementById(`${operatingSystem}_allow_logout`);

    const dataField = dataFieldSelect.value;
    const removeDomain = removeDomainCheckbox.checked;
    const allowLogout = allowLogoutCheckbox.checked;

    removeDomainCheckbox.checked = false;
    allowLogoutCheckbox.checked = false;
    dataFieldSelect.selectedIndex = 0;
    button.classList.add('d-none');

    let removeDomainText = '';
    if (removeDomain) {
      removeDomainText = I18n.t('assets.javascripts.controllers.automatic_login_settings.yes');
    }

    let allowLogoutText = '';
    if (allowLogout) {
      allowLogoutText = I18n.t('assets.javascripts.controllers.automatic_login_settings.yes');
    }

    if (dataField !== 'default') {
      const newRow = settingsTable.insertRow(settingsTable.rows.length);
      newRow.classList.add('autologin-setting-row');
      newRow.innerHTML = `<td class="col-2"></td>
        <td class="col-4 field">${dataField}</td>
        <td class="col-2 remove-domain">${removeDomainText}</td>
        <td class="col-2 allow-logout">${allowLogoutText}</td>
        <td class="col-2">
          <i class="fa fa-trash text-danger fa-lg float-right" style="cursor: pointer;" data-action="click->automatic-login-settings#deleteSettingRow"></i>
        </td>
        <input type="hidden" name="setting[][operating_system]" value="${operatingSystem}">
        <input type="hidden" name="setting[][field]" value="${dataField}">
        <input type="hidden" name="setting[][remove_domain]" value="${removeDomain}">
        <input type="hidden" name="setting[][allow_logout]" value="${allowLogout}">`;
    }

    this.enableSaveButton();
  }

  changeField(event) {
    const { operatingSystem } = event.currentTarget.dataset;
    const settingsTable = document.getElementById(`${operatingSystem}_settings_tables`);
    const dataField = document.getElementById(`${operatingSystem}_fields`).value;
    const button = this.element.querySelector(`[data-target="addButton"][data-operating-system="${operatingSystem}"]`);

    if (dataField !== 'default' && !this.fieldExists(settingsTable, dataField)) {
      button.classList.remove('d-none');
    } else {
      button.classList.add('d-none');
    }
  }

  deleteSettingRow(event) {
    const tableRow = event.target.closest('tr');
    tableRow.remove();
    this.enableSaveButton();
  }

  fieldExists(table, field) {
    for (var i = 1, row; row = table.rows[i]; i++) {
      for (var j = 0, cell; cell = row.cells[j]; j++) {
        if (cell.innerText.includes(field)) {
          return true;
        }
      }
    }
    return false;
  }

  enableSaveButton() {
    this.saveButtonTarget.classList.remove('btn-light', 'disabled');
    this.saveButtonTarget.classList.add('btn-primary');
    this.saveButtonTarget.disabled = false;
  }
}
