import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['link']

  static values = {
    baseUrl: String,
    selectedUuids: {
      type: Array,
      default: [],
    },
  }

  buildLinkWithUuids() {
    const query = new URLSearchParams();
    if (this.selectedUuidsValue.length < 1) {
      this.setHref(this.baseUrlValue);
      return;
    }

    this.selectedUuidsValue.forEach((uuid) => {
      query.append('capture[uuids][]', uuid);
    });

    if (this.hasLinkTarget) {
      this.linkTargets.forEach((t) => {
        t.setAttribute('href', this.baseUrlValue + '?' + query.toString());
      });
    }
  }

  selectedUuidsValueChanged() {
    this.buildLinkWithUuids();
  }

  setHref(val) {
    if (this.hasLinkTarget) {
      this.linkTargets.forEach((t) => {
        t.setAttribute('href', val);
      });
    }
  }

  updateLinks({ detail: { selectedUuids } }) {
    this.selectedUuidsValue = [...selectedUuids];
  }
}
