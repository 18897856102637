import {Controller} from 'stimulus'

export default class BackdropManualEntryController extends Controller {
  static targets = ['body', 'template', 'userGroupSelect'];

  selectedUserGroups = [];

  addRow(event) {
    const template = this.templateTarget.content.cloneNode(true);
    this.bodyTarget.appendChild(template);
    this.updateUserGroupSelectOptions();
  }

  userGroupChanged(event) {
    const {detail} = event;
    const {id, text} = detail;

    this.selectedUserGroups = [...this.selectedUserGroups, {id, text}];
    this.updateUserGroupSelectOptions();
  }

  updateUserGroupSelectOptions() {
    this.userGroupSelectTargets.forEach(userGroupSelect => {
      const jQSelect = $(userGroupSelect);

      this.selectedUserGroups.forEach(userGroup => {
        const {id, text} = userGroup;

        if (userGroupSelect.querySelector(`option[value="${id}"]`)) {

        } else {
          const newOption = new Option(text, id, false, false);
          jQSelect.append(newOption).trigger('change');
        }
      });
    });
  }
}
