import { Controller } from 'stimulus';
import { useVisibility } from 'stimulus-use';
import { createElementFromString, checkEnvironment } from '../utils';

export default class DeviceSessionDetailsController extends Controller {
  static targets = ['sessionDetail'];

  connect() {
    const { sessionId } = this.sessionDetailTarget.dataset;
    this.isPaused = false;
    useVisibility(this);

    this.pollInterval = setInterval(() => {
      this.setDetailsPartial(sessionId);
    }, checkEnvironment() == "test" ? 1000 : 15000);
  }

  disconnect() {
    clearInterval(this.pollInterval);
  }

  visible() {
    this.isPaused = false;
  }

  invisible() {
    this.isPaused = true;
  }

  async updateDetailsPartial(event) {
    const [data] = event.detail;
    const { payload } = data;
    const { status } = payload;

    if (status !== 'success' || !this.hasSessionDetailTarget) {
      return;
    }

    const { sessionId } = this.sessionDetailTarget.dataset;
    this.setDetailsPartial(sessionId);
  }

  async setDetailsPartial(sessionId) {
    if (this.isPaused) {
      return;
    }

    const detailsUrl = `/device_sessions/${sessionId}/details`;

    const response = await fetch(detailsUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
    const { html } = await response.json();

    if (html) {
      const newDetailsPartial = createElementFromString(html);
      this.sessionDetailTarget.innerHTML = newDetailsPartial.innerHTML;
    }
  }
}
