import { Controller } from 'stimulus';
import { removeElement } from '../src/utils';

export default class RemovableController extends Controller {
  remove() {
    removeElement(this.element);
    const groupFormEvent = new CustomEvent('group_form:change', {
      detail: {
        memberId: this.element.dataset.personId,
        quantityChange: -1,
      },
      bubbles: true,
    });
    document.dispatchEvent(groupFormEvent);
  }
}
