import * as pattern from 'patternomaly';
import Chart from 'chart.js';
import * as ChartUtils from './utils';
import * as ChartOptions from './options';

class Charts {
  constructor(selector = '[data-chart]') {
    this.selector = selector;
    document.addEventListener('DOMContentLoaded', this.setup.bind(this));
  }

  setup() {
    const graphNodes = document.querySelectorAll(this.selector);
    Array.from(graphNodes).forEach((graph) => {
      const type = graph.dataset.chart;
      if (graph.dataset.chartLinked) {
        return;
      }

      let chartOptions;
      switch (type) {
        case 'groupedHorizontalBar':
          chartOptions = this.stackedHorizontalOptions(graph);
          break;
        case 'percentageBar':
          chartOptions = this.percentageBarOptions(graph);
          break;
        case 'horizontalBar':
          chartOptions = this.horizontalBarOptions(graph);
          break;
        case 'horizontalBarClasses':
          chartOptions = this.horizontalBarConcernKindsOptions(graph);
          break;
        case 'pie':
          chartOptions = this.pieOptions(graph);
          break;
        case 'line':
          chartOptions = this.lineOptions(graph);
          break;
        case 'horizontalBarConcernKinds':
          chartOptions = this.horizontalBarConcernKindsOptions(graph);
          break;
        case 'doughnut':
          chartOptions = this.doughnutOptions(graph);
          break;
        default:
          return;
      }

      if (!chartOptions) {
        return;
      }

      new Chart(graph, chartOptions);
    });
    this.createLinkedCharts();
  }

  stackedHorizontalOptions(graph) {
    const options = JSON.parse(graph.dataset.chartOptions);
    const {
      labels, colours, data, label_width,
    } = options;

    const datasetOptions = {
      labels,
      datasets: Object.entries(data).map(([name, data]) => ({
        type: 'horizontalBar',
        label: name,
        backgroundColor: colours[name],
        data,
      })),
    };

    const chartOptions = {
      type: 'horizontalBar',
      data: datasetOptions,
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
            afterFit(scaleInstance) {
              scaleInstance.width = label_width;
            },
          }],
        },
      },
    };

    return chartOptions;
  }

  percentageBarOptions(graph) {
    const options = JSON.parse(graph.dataset.chartOptions);
    const colours = ['#ee0066', '#334455'];
    const { data, keys } = options;
    const type = 'horizontalBar';
    const datasetOptions = {
      datasets: Object.values(data).map(({ value, percentage }, index) => ({
        type,
        label: `${keys[index]} (${value})`,
        backgroundColor: colours[index],
        data: [percentage],
      })),
    };

    const chartOptions = {
      type,
      data: datasetOptions,
      options: {
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            title: () => '',
            label(tooltipItem, tooltipData) {
              const { label } = tooltipData.datasets[tooltipItem.datasetIndex];
              return `${label}: ${parseFloat(tooltipItem.value).toFixed(1)}%`;
            },
          },
        },
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
          }],
        },
      },
    };

    return chartOptions;
  }

  horizontalBarOptions(graph) {
    const options = JSON.parse(graph.dataset.chartOptions);
    const { data, label_width, colours: colourMap } = options;
    const colours = Object.values(colourMap);
    const type = 'horizontalBar';
    const datasetOptions = {
      labels: Object.entries(data).map(([name, count]) => `${name} (${count})`),
      datasets: [{
        data: Object.values(data),
        backgroundColor: colours,
      }],
    };

    const chartOptions = {
      type,
      data: datasetOptions,
      options: {
        layout: {
          padding: {
            left: 30,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
            afterFit(scaleInstance) {
              scaleInstance.width = label_width;
            },
          }],
        },
      },
    };

    return chartOptions;
  }

  createLinkedCharts() {
    const linkedGraphNodes = document.querySelectorAll(`${this.selector}[data-chart-linked]`);
    const linkedGraphElements = Array.from(linkedGraphNodes);
    const linkedMap = ChartUtils.getLinkedMap(linkedGraphElements);

    Object.values(linkedMap).forEach((graphElements) => {
      const max = ChartUtils.calculateMaxValue(graphElements);
      graphElements.forEach((graph) => {
        Charts.createChartWithMax(graph, max);
      });
    });
  }

  static createChartWithMax(graph, max) {
    let chartOptions;
    switch (graph.dataset.chart) {
      case 'phasedConcerns':
        chartOptions = this.phasedConcernsOptions(graph, max);
        break;
      case 'topCategories':
        chartOptions = this.topCategoriesOptions(graph, max);
        break;
      case 'characteristicsConcerns':
        chartOptions = this.characteristicsConcernsOptions(graph, max);
        break;
      case 'topEthnicities':
        chartOptions = this.topEthnicitiesOptions(graph, max);
        break;
      default:
        break;
    }
    if (!chartOptions) {
      return;
    }
    new Chart(graph, chartOptions);
  }

  pieOptions(graph) {
    const options = JSON.parse(graph.dataset.chartOptions);
    const { labels, colors, data } = options;
    const type = 'pie';

    const datasetOptions = {
      labels,
      datasets: [{
        data,
        backgroundColor: colors,
      }],
    };

    const pieOptions = {
      legend: {
        display: false,
        labels: {
          display: false,
        },
      },
      animation: {
        duration: 1500,
      },
    };

    const chartOptions = {
      type,
      data: datasetOptions,
      options: pieOptions,
    };


    return chartOptions;
  }

  horizontalBarConcernKindsOptions(graph) {
    const options = JSON.parse(graph.dataset.chartOptions);
    const { labels, data } = options;
    const type = 'horizontalBar';

    const datasetOptions = {
      labels,
      datasets: Object.entries(data).map(([concern_kind_str, values]) => {
        const concern_kind = JSON.parse(concern_kind_str);
        return {
          type,
          label: concern_kind.name,
          data: values,
          backgroundColor: `rgb(${concern_kind.colour.join(', ')})`,
        };
      }),
    };

    const chartOptions = {
      type,
      data: datasetOptions,
      options: {
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
          }],
        },
      },
    };

    return chartOptions;
  }

  lineOptions(graph) {
    const options = JSON.parse(graph.dataset.chartOptions);
    const { labels, data } = options;
    const type = 'line';

    const datasetOptions = {
      labels,
      datasets: Object.entries(data).map(([concern_kind_str, dateValues]) => {
        const concern_kind = JSON.parse(concern_kind_str);
        return {
          label: concern_kind.name,
          data: Object.values(dateValues),
          backgroundColor: `rgb(${concern_kind.colour.join(', ')})`,
          borderColor: `rgb(${concern_kind.colour.join(', ')})`,
          fill: false,
        };
      }),
    };

    const chartOptions = {
      type,
      data: datasetOptions,
      options: {
        layout: {
          padding: {
            right: 5,
          },
        },
        responsive: true,
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          xAxes: [{
            display: true,
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90,
            },
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: '# Concerns',
            },
          }],
        },
      },
    };

    return chartOptions;
  }

  doughnutOptions(graph) {
    const options = JSON.parse(graph.dataset.chartOptions);
    const {
      labels, data, center, colours,
    } = options;
    const type = 'doughnut';

    const datasetOptions = {
      labels,
      datasets: [{
        data,
        backgroundColor: colours,
      }],
    };

    const chartOptions = {
      type,
      data: datasetOptions,
      options: {
        elements: {
          center,
        },
        responsive: true,
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      },
    };

    return chartOptions;
  }

  static phasedConcernsOptions(graph, max) {
    if (!graph.dataset.chartData) {
      return;
    }
    const data = JSON.parse(graph.dataset.chartData);
    const datasets = Object.entries(data).map(([kind_json, count]) => {
      const kind = JSON.parse(kind_json);
      return {
        type: 'horizontalBar',
        label: kind.name,
        data: [count],
        backgroundColor: `rgb(${kind.colour.join(', ')})`,
      };
    });

    const chartOptions = {
      type: 'horizontalBar',
      options: ChartOptions.singleStackedOptions(max),
      data: {
        datasets,
      },
    };

    return chartOptions;
  }

  static topCategoriesOptions(graph, max) {
    const data = JSON.parse(graph.dataset.chartData);
    const type = 'horizontalBar';
    const { totals, colours } = data;
    const [colourParts] = colours;
    const colour = `rgb(${colourParts.join(', ')})`;
    const patternColor = 'rgba(0, 0, 0, 0.15)';
    const mappedColours = Object.keys(totals).reduce((map, key) => {
      switch (key) {
        case 'concerns':
          map[key] = pattern.draw('diagonal', colour, patternColor, 10);
          break;
        case 'referrals':
          map[key] = pattern.draw('square', colour, patternColor, 10);
          break;
        default:
          map[key] = colour;
      }

      return map;
    }, {});
    const datasets = Object.entries(totals).map(([label, count]) => ({
      label,
      type,
      data: [count],
      backgroundColor: mappedColours[label],
    }));

    const chartOptions = {
      type,
      options: ChartOptions.groupedOptions(max),
      data: {
        datasets,
      },
    };

    return chartOptions;
  }

  static characteristicsConcernsOptions(graph, max) {
    if (!graph.dataset.chartData) {
      return;
    }
    const graphData = JSON.parse(graph.dataset.chartData);

    const datasets = Object.entries(graphData).flatMap(([charValue, valueData], index) => {
      return Object.entries(valueData.concern_kinds).map(([kindJson, count]) => {
        const kind = JSON.parse(kindJson);
        const patternStyle = index > 0 ? 'square' : 'diagonal';
        const patterBgColour = `rgb(${kind.colour.join(', ')})`;
        return {
          label: kind.name,
          data: [count],
          backgroundColor: pattern.draw(patternStyle, patterBgColour, 'rgba(0, 0, 0, 0.15)', 10),
          stack: charValue,
        };
      });
    });

    const data = {
      datasets,
    };

    return {
      type: 'horizontalBar',
      options: ChartOptions.multipleStackedOptions(max),
      data,
    };
  }

  static topEthnicitiesOptions(graph, max) {
    const { chartData } = graph.dataset;
    if (!chartData) {
      return;
    }
    const graphData = JSON.parse(chartData);
    const datasets = Object.entries(graphData.concern_kinds).map(([kind_json, count], index) => {
      const kind = JSON.parse(kind_json);
      return {
        type: 'horizontalBar',
        label: kind.name,
        data: [count],
        backgroundColor: `rgb(${kind.colour.join(', ')})`,
      };
    });

    const data = {
      datasets,
    };

    return {
      type: 'horizontalBar',
      options: ChartOptions.singleStackedOptions(max),
      data,
    };
  }
}

new Charts();
