import {Controller} from 'stimulus';

export default class PendingFormController extends Controller {
  /**
   * @param {Event} event
   */
  submitted(event) {
    this.data.set('submitting', true);
  }
}
