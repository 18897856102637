import {Controller} from 'stimulus'
import tippy from 'tippy.js';

export default class TooltipController extends Controller {
  static targets = ['template', 'trigger'];

  get debug() {
    return !!this.data.get("debug")
  }

  get templateHtml() {
    if (!this.hasTemplateTarget) {
      return;
    }
    const template = this.templateTarget.content.cloneNode(true);
    const container = document.createElement('div');
    container.appendChild(template);
    return container.innerHTML;
  }

  get content() {
    if (this.templateHtml) {
      return this.templateHtml
    }
    return this.element.title
  }

  get tippyOptions() {
    const options = {
      content: this.content,
      arrow: true,
      arrowType: 'round',
      placement: 'bottom',
      theme: this.theme,
      appendTo: "parent",
    };
    const debug = {
      ...options,
      hideOnClick: false,
      trigger: 'click'
    };

    return this.debug ? debug : options;
  }

  get theme() {
    return this.data.get('theme') || 'light'
  }

  connect() {
    const triggers = this.hasTriggerTarget ? this.triggerTargets : this.element;
    if (this.templateHtml) {
      tippy(triggers, this.tippyOptions);
    }
  }
}
