import { Controller } from 'stimulus';

export default class TwilioController extends Controller {
  static targets = ['wrapper', 'video'];

  connect() {
    this.join();
  }

  join() {
    const { connect } = require('twilio-video');
    const { token } = this.wrapperTarget.dataset;
    const roomSid = this.wrapperTarget.dataset.room;
    const outerScope = this;

    connect(token, {
      name: roomSid,
      video: false,
      audio: false,
    }).then((room) => {
      room.participants.forEach((participant) => {
        participant.tracks.forEach((publication) => {
          if (publication.isSubscribed) {
            publication.track.attach(outerScope.videoTarget);
          }
        });
        participant.on('trackSubscribed', (track) => {
          track.attach(outerScope.videoTarget);
        });
      });

      room.on('participantConnected', (participant) => {
        participant.tracks.forEach((publication) => {
          if (publication.isSubscribed) {
            publication.track.attach(outerScope.videoTarget);
          }
        });
        participant.on('trackSubscribed', (track) => {
          track.attach(outerScope.videoTarget);
        });
      });

      window.addEventListener('beforeunload', () => {
        room.disconnect();
      });
    });
  }

  play() {
    this.videoTarget.play();
    this.wrapperTarget.classList.add('broadcast-wrapper--active');
  }
}
