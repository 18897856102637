import {Controller} from 'stimulus';
import * as QRCode from 'qrcode-svg';

export default class QrCodeController extends Controller {
  get qrData() {
    return this.data.get('data');
  }

  connect() {
    const svg = new QRCode(this.qrData).svg();
    this.element.insertAdjacentHTML('beforeend', svg);
  }
}
