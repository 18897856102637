import {Controller} from 'stimulus';

export default class MoveChildrenController extends Controller {
  static targets = ['userActionSelect', 'toSelect', 'submitBtn', "copyCta", "moveCta", "leaversCta"];

  connect() {
    if (this.hasUserActionSelectTarget) {
      $(this.userActionSelectTarget).select2({
        theme: 'bootstrap',
        minimumResultsForSearch: Infinity,
        width: '100%',
      });

      $(this.userActionSelectTarget).on('select2:select', this.toggle.bind(this))
    }

    if (this.hasToSelectTarget) {
      $(this.toSelectTarget).select2({
        theme: 'bootstrap',
        minimumResultsForSearch: Infinity,
        width: '100%',
      });
    }
  }

  /**
   * @public
   * @param event {Event}
   */
  toggle(event) {
    const userAction = event.currentTarget.value

    switch (userAction) {
      case "leavers":
        this.toSelectTarget.disabled = true;
        this.leaversCtaTarget.classList.remove('collapse')
        this.copyCtaTarget.classList.add('collapse')
        this.moveCtaTarget.classList.add('collapse')
        this.submitBtnTarget.disabled = false;
        break;
      case "copy":
        this.toSelectTarget.disabled = false;
        this.leaversCtaTarget.classList.add('collapse')
        this.copyCtaTarget.classList.remove('collapse')
        this.moveCtaTarget.classList.add('collapse')
        this.submitBtnTarget.disabled = false;
        break;
      case "move":
        this.toSelectTarget.disabled = false;
        this.leaversCtaTarget.classList.add('collapse')
        this.copyCtaTarget.classList.add('collapse')
        this.moveCtaTarget.classList.remove('collapse')
        this.submitBtnTarget.disabled = false;
        break;
      default:
        this.submitBtnTarget.disabled = true
    }
  }
}
