import { Controller } from 'stimulus'

export default class BackdropImportFormController extends Controller {
  static targets = ['loader', 'select'];

  connect() {
    this.selectTargets.forEach(selectTarget => {
      if (this.valueUsed(selectTarget)) {
        selectTarget.value = null
      }
    })
  }

  showLoader(event) {
    this.loaderTargets.forEach(loader => loader.classList.toggle("show"));
  }

  optionSelected(event) {
    const { currentTarget } = event;
    const { value } = currentTarget;
    this.selectTargets
      .filter(selectTarget => selectTarget !== currentTarget)
      .forEach(selectTarget => {
        const { value: selectValue } = selectTarget;
        if (value === selectValue) {
          selectTarget.value = null;
        }
      });
  }

  valueUsed(currentTarget) {
    const { value: currentValue } = currentTarget;

    return this.selectTargets
      .filter(selectTarget => selectTarget !== currentTarget)
      .find(selectTarget => selectTarget.value === currentValue)
  }

}
