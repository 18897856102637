import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['sidebar'];

  toggleSidebar() {
    const sidebar = document.getElementById('page-sidebar');
    sidebar.classList.toggle('active');
  }
}
