import { Controller } from 'stimulus';
import consumer from '../../channels/consumer';
import { getAjaxData, replaceOrAppendHtmlFromString } from '../../utils';

export default class ConcernRepliesController extends Controller {
  connect() {
    const concern_id = this.data.get('concern-id');
    const concernRepliesController = this;
    this.repliesChannel = consumer.subscriptions.create(
      { channel: 'ConcernsChannel', concern_id },
      {
        received(data) {
          concernRepliesController.received(data);
        },
      },
    );
  }

  async received(data) {
    const { action } = data;
    if (action === 'concern_replies') {
      const { payload } = data;
      const { partial_url } = payload;
      const response = await fetch(partial_url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      });
      const json = await response.json();
      const { html } = json;
      replaceOrAppendHtmlFromString(html, this.element);
    }
  }

  addConcernReply(event) {
    const { action, payload } = getAjaxData(event);
    if (action !== 'concern_reply_created') {
      return;
    }

    const { html } = payload;
    replaceOrAppendHtmlFromString(html, this.element);
  }
}
