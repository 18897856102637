import { Controller } from 'stimulus';

export default class MonitorPrintAuditController extends Controller {
  static targets = ["filterPrintOverviewSubmitBtn", "modalSelectFields"];

  /**
   * @public
   * @param event {Event}
   */
  redirectToPrintAudit(event) {
    const {detail} = event;
    const redirect_path = detail[0]["path"]
    const filters = detail[0].filters

    this.filterPrintOverviewSubmitBtnTarget.innerHTML = "Generating..."
    this.filterPrintOverviewSubmitBtnTarget.disabled=true

    this.modalSelectFieldsTarget.parentNode.removeChild(this.modalSelectFieldsTarget)

    const full_path = new URL(`${location.origin}/${redirect_path}`)

    if (filters.number_answers !== "") {
      full_path.searchParams.append("number_answers", filters.number_answers)
    }
    if (filters.assigned_users !== "") {
      full_path.searchParams.append("assigned_users", filters.assigned_users)
    }
    if (filters.ratings !== "") {
      full_path.searchParams.append("risk_rating", filters.risk_rating)
    }

    window.location = full_path
  }
}
