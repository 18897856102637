import { Controller } from 'stimulus';

export default class ActionPendingController extends Controller {
  created(event) {
    const [data] = event.detail;
    const { action } = data;
    if (['close_application', 'close_tab'].includes(action)) {
      this.handleIconStates(data);
    }
  }

  /**
   * @private
   * @param {string} action
   * @param {Object<status, message>}payload
   */
  handleIconStates({ payload }) {
    const { status } = payload;
    if (status !== 'success') {
      return;
    }
    this.element.addEventListener('animationend', () => {
      this.element.dataset.pending = false;
    });
    this.element.dataset.pending = true;
  }
}
