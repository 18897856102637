import { Controller } from 'stimulus';

export default class extends Controller {
  static classes = [
    'sm', 'md', 'lg', 'xl',
  ]

  static targets = ['zoomInput']

  static values = {
    selectedSizeIndex: { type: Number, default: 1 },
    sizes: {
      type: Array,
      default:
        [{ name: 'sm', klass: 'row-cols-6' },
          { name: 'md', klass: 'row-cols-5' },
          { name: 'lg', klass: 'row-cols-3' },
          { name: 'xl', klass: 'row-cols-1' }],
    },
    previousSizeIndex: { type: Number, default: 1 },
    smallScreenMode: { type: Boolean, default: false },
  };

  checkZoomSizeCookie() {
    const cookieName = 'captures_zoom_level=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split('; ');
    cookieArray.forEach((cookie) => {
      if (cookie.indexOf(cookieName) === 0 ) {
        this.performResize(cookie.substring(cookieName.length));
      }
    });
  }

  checkScreenSize() {
    const windowSize = window.innerWidth;
    if (windowSize < 990) {
      if (this.smallScreenModeValue === false) {
        this.previousSizeIndexValue = this.selectedSizeIndexValue;
        this.smallScreenModeValue = true;
        this.performResize('xl');
        return;
      }
    }
    if (windowSize > 990) {
      if (this.smallScreenModeValue === true) {
        this.selectedSizeIndexValue = this.previousSizeIndexValue;
        this.smallScreenModeValue = false;
      }
    }
  }

  cookieExpiryTime() {
    const date = new Date();
    return date.setFullYear(date.getFullYear() + 1);
  }

  connect() {
    this.checkZoomSizeCookie();
    this.updateButtonSelection();
  }

  selectedSize() {
    return this.sizesValue[this.selectedSizeIndexValue];
  }

  selectedSizeName() {
    return this.selectedSize().name;
  }

  sizeUp() {
    if (this.selectedSizeIndexValue < this.sizesValue.length - 1) {
      this.selectedSizeIndexValue += 1;
    }
  }

  sizeDown() {
    if (this.selectedSizeIndexValue > 0) {
      this.selectedSizeIndexValue -= 1;
    }
  }

  setSize({ params: { sizestr } }) {
    this.performResize(sizestr);
  }

  performResize(sizestr) {
    const index = this.sizesValue.findIndex((el) => el.name === sizestr);
    if (index !== -1) {
      this.selectedSizeIndexValue = index;
    }
    document.cookie = `captures_zoom_level=${sizestr}; expires="${this.cookieExpiryTime()}"`;
  }

  selectedSizeIndexValueChanged() {
    this.updateButtonSelection();
    this.dispatch(
      'sizeChange',
      {
        detail: this.selectedSize(),
        prefix: 'capturesThumbnailGrid',
      },
    );
  }

  updateButtonSelection() {
    if (!this.hasZoomInputTarget) return;
    const sizeName = this.selectedSizeName();
    this.zoomInputTargets.forEach((input) => {
      if (input.id.slice(-2) === sizeName) {
        input.classList.add('active');
        input.setAttribute('aria-pressed', true);
      } else {
        input.classList.remove('active');
        input.setAttribute('aria-pressed', false);
      }
    });
  }
}
