import { Controller } from 'stimulus';

export default class ChooseGroupsController extends Controller {
  static targets = ['toggleable', 'allGroups', 'tick', 'checkbox', 'form'];

  toggleExtendedGroups() {
    this.toggleableTargets.forEach((target) => {
      target.classList.toggle('show');
    });
  }

  selectAllGroups() {
    this.allGroupsTargets.forEach((target) => {
      target.classList.toggle('show');
    });
    const allGroupsChecked = !this.allGroupsTarget.classList.contains('show');

    this.tickTargets.forEach((target) => {
      if (allGroupsChecked && target.classList.contains('tick-icon--checked')) {
        target.classList.remove('tick-icon--checked');
      }
      target.classList.toggle('tick-icon--checked');
    });

    this.checkboxTargets.forEach((target) => {
      // eslint-disable-next-line no-param-reassign
      target.checked = allGroupsChecked;
    });
  }

  dispatchSubmitForm(e) {
    e.preventDefault();
    this.dispatch("dispatchSubmitForm");
  }

  submitForm(e) {
    e.preventDefault();
    if (this.hasFormTarget) {
      this.formTarget.submit();
    }
  }
}
