import { Controller } from 'stimulus';

export default class DigestSchedulesRowController extends Controller {
  static targets = ['newScheduleFormContainer', 'newScheduleTimeInput', 'newScheduleForm'];

  createSchedule() {
    Rails.fire(this.newScheduleFormTarget, 'submit');
    this.newScheduleFormContainerTarget.classList.add('visually-hidden');
    this.newScheduleTimeInputTarget.value = null;
  }

  showScheduleForm() {
    this.newScheduleFormContainerTarget.classList.remove('visually-hidden');
  }

  clearScheduleForm() {
    this.hideForm();
  }

  hideForm() {
    this.newScheduleFormContainerTarget.style.transition = '1s';
    this.newScheduleFormContainerTarget.style.opacity = 0;
    setTimeout(() => {
      this.newScheduleFormContainerTarget.classList.add('visually-hidden');
      this.newScheduleFormContainerTarget.style.opacity = 1;
      this.newScheduleTimeInputTarget.value = null;
    }, 1000);
  }

  deleteSchedule() {
    const schedule = this.element;
    schedule.style.transition = '1s';
    schedule.style.opacity = 0;

    setTimeout(() => {
      schedule.remove();
    }, 1000);
  }
}
