import {Controller} from 'stimulus'

export default class PresenceOverviewController extends Controller {

  thingsBeingViewed = {}

  viewersChanged(event) {
    const {detail} = event;
    const {hasOtherViewers, uuid} = detail;
    this.thingsBeingViewed[uuid] = hasOtherViewers;

    const showMessage = Object.values(this.thingsBeingViewed).includes(true)

    this.element.classList.toggle("hint--hidden", !showMessage)
  }
}