import { Controller } from 'stimulus';
import { debounce } from 'lodash'

export default class TopNavController extends Controller {
  static targets = ['navMenu'];

  connect() {
    if (this.hasNavMenuTarget) {
      this.debouncedCheckWindowSize = debounce(this.checkWindowSize, 1000);
      this.debouncedCheckWindowSize();
      window.addEventListener('resize', this.debouncedCheckWindowSize.bind(this));
    }
  }

  checkWindowSize() {
    const windowWidth = window.innerWidth;

    const menu = this.navMenuTarget;

    if (windowWidth <= 768 && menu.classList.contains('dropdown')) {
      menu.classList.remove('dropdown');
      menu.classList.add('dropup');
    } else if (windowWidth > 768 && menu.classList.contains('dropup')) {
      menu.classList.remove('dropup');
      menu.classList.add('dropdown');
    }
  }

  disconnect() {
    window.removeEventListener('resize', this.debouncedCheckWindowSize);
  }
}
