/**
 * Submits form remotely using Rails UJS
 * calling form.submit() bypasses UJS so we have to dispatch a Custom Event
 * @param form {HTMLFormElement}
 */
export function submitFormRemotely(form) {
  window.Rails.fire(form, 'submit');
}

/**
 * @param htmlString {string}
 * @returns {HTMLElement}
 */
export function createElementFromString(htmlString) {
  const template = document.createElement('template');
  template.innerHTML = htmlString.trim();
  const node = template.content.cloneNode(true);
  return node.firstChild.firstChild.parentElement;
}

/**
 * @param html {string}
 * @param target {HTMLElement | Window | Document}
 * @return {HTMLElement}
 */
export function appendHtmlFromString(html, target) {
  const newElement = createElementFromString(html);
  target.appendChild(newElement);
  return newElement;
}

/**
 * @param html {string}
 * @param target {HTMLElement | Window | Document}
 * @return {HTMLElement}
 */
export function prependHtmlFromString(html, target) {
  const newElement = createElementFromString(html);
  target.prepend(newElement);
  return newElement;
}

/**
 * @param html {string}
 * @param target {HTMLElement}
 * @return {HTMLElement}
 */
export function replaceHtmlFromString(html, target) {
  const newElement = createElementFromString(html);
  target.parentNode.replaceChild(newElement, target);
  return newElement;
}

/**
 * @param newElement {HTMLElement}
 * @param target {HTMLElement}
 * @return {HTMLElement}
 */
export function replaceHtmlFromElement(newElement, target) {
  target.parentNode.replaceChild(newElement, target);
  return newElement;
}

export function checkEnvironment() {
  return document.querySelector('meta[name="environment"]').getAttribute('content');
}

export const deSelectAllGridItems = (element = window) => {
  const deSelectEvent = new CustomEvent('grid:deselect-all', { bubbles: true });
  element.dispatchEvent(deSelectEvent);
};

export const selectAllGridItems = (element = window) => {
  const selectEvent = new CustomEvent('grid:select-all', { bubbles: true });
  element.dispatchEvent(selectEvent);
};

export const removeSelectedGridItems = (element = window) => {
  const removeSelectedEvent = new CustomEvent('grid:remove-selected', { bubbles: true });
  element.dispatchEvent(removeSelectedEvent);
};

export const getAjaxData = (event) => {
  const { detail } = event;
  const [response] = detail;
  return response;
};

export const closeModal = (modalElement) => {
  $(modalElement).modal('hide');
};

export const replaceOrAppendHtmlFromString = (html, parent = window) => {
  const newElement = createElementFromString(html);
  const { id } = newElement;
  const existing = parent.querySelector(`#${id}`);
  if (existing) {
    replaceHtmlFromString(html, existing);
  } else {
    appendHtmlFromString(html, parent);
  }
};

export const replaceOrPrependHtmlFromString = (html, parent = window) => {
  prependHtmlFromString(html, parent);
};

export const setFlashingIcons = ({ action, payload }) => {
  const { status, device_ids: deviceIds } = payload;

  if (status !== 'success') {
    return;
  }

  deviceIds.forEach((deviceId) => {
    const selector = `[data-device-id="${deviceId}"]`;
    const iconSelector = `[data-icon="${action}"]`;
    const icons = document.querySelectorAll(`${selector} ${iconSelector}`);
    if (!icons) {
      return;
    }

    Array.from(icons).forEach((icon) => {
      icon.addEventListener('animationend', () => {
        icon.dataset.pending = false;
      });
      icon.dataset.pending = true;
    });
  });
};

export const setIconState = ({ action, payload }) => {
  const { status, state, device_ids: deviceIds } = payload;

  if (status !== 'success') {
    return;
  }

  deviceIds.forEach((deviceId) => {
    const selector = `[data-device-id="${deviceId}"]`;
    const iconSelector = `[data-icon="${action}"]`;
    const icons = document.querySelectorAll(`${selector} ${iconSelector}`);

    if (!icons) {
      return;
    }

    Array.from(icons).forEach((icon) => {
      const targetedIcon = icon;
      targetedIcon.dataset.state = state;
    });
  });
};

export const setListState = ({ action, payload }) => {
  const { status, state, device_ids: deviceIds } = payload;

  if (status !== 'success') {
    return;
  }

  deviceIds.forEach((deviceId) => {
    const selector = `[data-device-id="${deviceId}"]`;
    const iconSelector = `[data-icon="${action}"]`;
    const icons = document.querySelectorAll(`${selector} ${iconSelector}`);

    if (!icons) {
      return;
    }

    Array.from(icons).forEach((icon) => {
      const targetedIcon = icon;
      const toState = state;

      targetedIcon.dataset.state = toState;
    });
  });
};

/**
 *
 * @param {string} html Sting of HTML to be added
 * @param {HTMLElement} parent Container to search for existing thing
 * @param {HTMLElement} target Target element for inserting
 * @param {string} type For inserting before or after
 */
export const replaceOrInsertHtmlFromString = (html, parent, target, type) => {
  const newElement = createElementFromString(html);
  const { id } = newElement;
  const existing = parent.querySelector(`#${id}`);
  if (existing) {
    replaceHtmlFromString(html, existing);
    return;
  }

  if (type === 'before') {
    parent.insertBefore(newElement, target);
    return;
  }
  appendHtmlFromString(html, parent);
};

/**
 * Increase/Decrease value of a count-displaying element using param addedValue e.g +1 or -1.
 * Can pass in a textElement denoting what's being counted so the term can
 * be changed to plural/singular accordingly. Can set replaceCurrentValue to true
 * to replace the current count rather than increment it
 * @param countElement
 * @param addedValue
 * @param textElement
 * @param replaceCurrentValue
 */
export const incrementCountElement = (countElement, addedValue, textElement, replaceCurrentValue) => {
  if (!countElement) {
    return;
  }
  let newCount = addedValue;
  if (replaceCurrentValue) {
    countElement.innerHTML = addedValue;
  } else {
    const currentCount = parseInt(countElement.innerHTML);
    newCount = currentCount + addedValue;
    countElement.innerHTML = newCount;
  }
  // No point carrying on if there isn't a text element to denote what's being counted
  // or no singular/plural terms set
  if (!textElement || !textElement.dataset.singular || !textElement.dataset.plural) {
    return;
  }

  let countSubject = textElement.dataset.plural;
  if (newCount == 1) {
    countSubject = textElement.dataset.singular;
  }
  textElement.innerHTML = countSubject;
};

export default {
  createElementFromString,
  submitFormRemotely,
  appendHtmlFromString,
  deSelectAllGridItems,
  selectAllGridItems,
  getAjaxData,
  closeModal,
  replaceHtmlFromString,
  replaceOrAppendHtmlFromString,
  setFlashingIcons,
  incrementCountElement,
};
