import {Controller} from 'stimulus';
import {appendHtmlFromString, getAjaxData} from '../utils';
import {FlashAlert} from '../packs/flash_alerts';

export default class AutomaticLoginController extends Controller {
  static targets = ["formTemplate", "credentials"];

  credentialCreated(event) {
    const {action, payload} = getAjaxData(event)
    if (action === "create") {
      if (payload.status === "ok") {
        const {html} = payload
        appendHtmlFromString(html, this.credentialsTarget)
        const {currentTarget} = event;
        this.refreshForm(currentTarget);
      } else {
        FlashAlert.error(payload.message)
      }
    }
  }

  refreshForm(formContainer) {
    const template = this.formTemplateTarget
    const formElement = template.content.cloneNode(true)
    formContainer.replaceChild(formElement, formContainer.children[0]);
  }
}
