import { Controller } from 'stimulus'

export default class GridRowController extends Controller {
  static targets = ["checkbox", "tick"];

  toggleGroup() {
    this.checkboxTarget.checked = !this.checkboxTarget.checked;
    this.tickTarget.classList.toggle("tick-icon--checked")
  }
}
