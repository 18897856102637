import {Controller} from "stimulus";

export default class ReportsChildrenController extends Controller {
  static targets = ["characteristics"];

  /**
   * @public
   * @param event {Event}
   */
  toggleCharacteristics(event) {
    var childId = event.target.dataset.childId
    var totalNumberCharacteristics = event.target.dataset.totalNumberCharacteristics
    if (event.target.innerHTML.includes("Display all")) {
        event.target.innerHTML = "Display less"
    } else {
        event.target.innerHTML = `Display all ${totalNumberCharacteristics} characteristics`
    }
    document.getElementById(`further-characteristics-${childId}`).classList.toggle("further-characteristics__hidden")
  }
}
