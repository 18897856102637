import { Controller } from 'stimulus';

/* global SignaturePad */
export default class MedicinePermissionController extends Controller {
  static targets = ['medicinePermissionForm', 'expiryToggle', 'administerSelect', 'administerTime', 'medicineExpiry', 'medicinePersistance', 'signaturePad', 'signaturePadAdd', 'signatureCanvas', 'signatureData'];

  connect() {
    // Make sure items are setup on connect
    // Make sure hidden elements are hidden or shown on load
    this.expiryAlertChecked();
    this.showSignaturePad();
  }

  expiryAlertChecked(event) {
    const state = this.expiryToggleTarget;
    const medicineExpiry = this.medicineExpiryTarget;

    if (state && medicineExpiry) {
      const stateToggled = state.checked;
      const medicineExpiryClassList = medicineExpiry.classList;
      if (medicineExpiryClassList.contains('d-none') && (event || stateToggled)) {
        medicineExpiryClassList.remove('d-none');
      } else {
        medicineExpiryClassList.add('d-none');
      }
    }
  }

  // When select box changes show corrosponding time blocks
  // e.g 3x Daily will show 3 Time Blocks
  medicineSchedule(event) {
    const administerSelectBox = this.administerSelectTarget;
    const timeBlocksTarget = this.administerTimeTargets;

    if (administerSelectBox && timeBlocksTarget) {
      administerSelectBox.onchange = function change() {
        let fields = [];
        const selectedOption = document.getElementById('select2-medicine_schedule_type-container');

        // Get first character of the selected choice
        // Would be a number unless null or 'When Requested'
        fields = selectedOption.innerText.split('-');
        const firstChar = fields[0].substring(0, 1);
        const rowsToShow = (parseInt(firstChar, 10));

        // Check if we triggered by the event and check if first character is a number
        // If its a number we convert to int and show that many time blocks
        // Else show no time blocks
        if (typeof rowsToShow === 'number' && event) {
          let timeBlocksClassList = '';
          let timeBlockId = 1;

          timeBlocksTarget.forEach((timeBlock) => {
            if (timeBlock) {
              timeBlocksClassList = timeBlock.classList;
              if (timeBlockId <= rowsToShow) {
                timeBlocksClassList.remove('d-none');
              } else {
                timeBlocksClassList.add('d-none');
              }
              timeBlockId += 1;
            }
          });
        }
      };
    }
  }

  showSignaturePad(event) {
    const signaturePad = this.signaturePadAddTarget;

    // Check if the medicine form is new on connect
    // if its new detect changes to signature pad
    if (this.hasMedicinePersistanceTarget && !event) {
      this.saveSignaturePad();
    }

    if (signaturePad) {
      if (signaturePad.style.display === 'none' && event) {
        signaturePad.style.removeProperty('display');
      } else {
        signaturePad.style.display = 'none';
      }
    }
  }

  clearSignaturePad() {
    const canvas = this.signatureCanvasTarget;
    const signaturePad = new SignaturePad(canvas, { backgroundColor: 'rgb(255, 255, 255)' });
    const signaturePadData = this.signatureDataTarget;

    signaturePad.clear();
    signaturePadData.value = '';
  }

  saveSignaturePad() {
    const canvas = this.signatureCanvasTarget;
    const signaturePad = new SignaturePad(canvas, { backgroundColor: 'rgb(255, 255, 255)' });
    const signaturePadData = this.signatureDataTarget;

    // Update the SignaturePad Data
    // No point in updating a blank Signature
    signaturePad.onEnd = function end() {
      if (signaturePad.toData().length > 0) {
        signaturePadData.value = signaturePad.toDataURL();
      }
    };
  }
}
