/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as ActiveStorage from '@rails/activestorage';
import LocalTime from 'local-time';
import '../modules';
import RemindersSettings from './action_plan/reminder_settings';
import '../controllers';
import '../channels';

ActiveStorage.start();
LocalTime.start();
new RemindersSettings();
