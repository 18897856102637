import { Controller } from 'stimulus';

export default class extends Controller {
  static classes = ['hide'];

  static values = {
    displayClass: { type: String, default: 'row-cols-3' },
    displaySize: { type: String, default: 'md' },
  };

  static targets = ['container', 'hideOnSmaller'];

  initialize() {
    const zoomLevel = document.cookie.split('; ').find((row) => row.startsWith('captures_zoom_level='))?.split('=')[1];
    this.setDisplayClassValue(zoomLevel);
  }

  connect() {
    super.connect();
  }

  setDisplayClassValue(zoomLevel) {
    switch (zoomLevel) {
      case 'sm':
        this.displayClassValue = 'row-cols-6';
        break;
      case 'md':
        this.displayClassValue = 'row-cols-5';
        break;
      case 'lg':
        this.displayClassValue = 'row-cols-3';
        break;
      case 'xl':
        this.displayClassValue = 'row-cols-1';
        break;
      default:
        this.displayClassValue = 'row-cols-3'; // Default to 'lg' size
    }
  }

  displayClassValueChanged(value, previousValue) {
    if (this.hasContainerTarget) {
      this.containerTargets.forEach((t) => {
        t.classList.remove(previousValue);
        t.classList.add(value);
      });
    }
  }

  displaySizeValueChanged() {
    if (['lg', 'xl'].includes(this.displaySizeValue)) {
      this.showHiddenElements();
    } else {
      this.hideHiddenElements();
    }
  }

  // eslint-disable-next-line no-unused-vars
  detectSizeChange({ detail: { name, klass } }) {
    this.displayClassValue = klass;
    this.displaySizeValue = name;
  }

  hideHiddenElements() {
    if (this.hasHideOnSmallerTarget) {
      this.hideOnSmallerTargets.forEach((t) => t.classList.add(this.hideClass));
    }
  }

  showHiddenElements() {
    if (this.hasHideOnSmallerTarget) {
      this.hideOnSmallerTargets.forEach((t) => t.classList.remove(this.hideClass));
    }
  }
}
