import {Controller} from 'stimulus';
import { getAjaxData } from '../../utils';

export default class DevicesGoogleMeetsController extends Controller {

  googleMeetCreated(event) {
    const {action, payload} = getAjaxData(event)
    if (action !== 'google_meet_created') {
      return;
    }
    window.open(payload.url);
  }
}
