import { Controller } from 'stimulus';

export default class CaptureKeywordListController extends Controller {
  static targets = ['list'];

  updateCaptureKeywordList(e) {
    const { listId, captureId, captureUuid, listName } = e.target.dataset;
    const url = `/captures/${captureId}/associate_keyword_list`;
    const params = new FormData();
    params.append('keyword_list_id', listId);
    params.append('uuid', captureUuid);

    this.associateCaptureToList(params, url);
    this.updateCapturePageTitle(listName);
    this.allowCaptureTriage();
  }

  associateCaptureToList(params, url) {
    Rails.ajax({ // eslint-disable-line no-undef
      url,
      type: 'PATCH',
      data: params,
    });
  }

  updateCapturePageTitle(listName) {
    document.querySelector('.capture-page__header').innerHTML = listName;
  }

  allowCaptureTriage() {
    const concernButton = document.querySelector('.concern-control__button');
    if (concernButton.classList.contains('hidden')) concernButton.classList.toggle('hidden');
  }
}
