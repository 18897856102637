import { Controller } from 'stimulus';

export default class GoogleClassroomSelectionModalController extends Controller {
  static targets = [
    'modal',
    'modalButton',
    'selectedClassesData',
    'classCheckBox',
    'modalLoadingData',
    'modalDataError',
    'modalDataErrorMessage',
    'modalData',
    'dataRow',
    'searchBox',
    'submitForm',
  ];

  processSelectedItems() {
    const allSelectedClasses = [];

    this.classCheckBoxTargets.forEach((element) => {
      allSelectedClasses.push(
        {
          google_class_name: element.dataset.gname,
          google_class_id: element.dataset.gid,
          selected: element.checked,
        },
      );
    });
    this.selectedClassesDataTarget.value = JSON.stringify({ data: allSelectedClasses });
  }

  async preloadModalData() {
    this.disableFormElements(true);
    this.showModalLoadingContent();

    const modalContent = this.modalDataTarget;
    const modalData = await this.fetchGoogleClasses('/google_classes_data');
    const result = this.populateModalData(modalData, modalContent);

    if (result) {
      this.showModalDataContent();
      this.disableFormElements(false);
    } else {
      this.showModalContentError();
    }
  }

  disableSubmit(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      return false;
    }
    return true;
  }

  selectAll(event) {
    const visibleRows = this.modalDataTarget.querySelectorAll('div:not(.d-none).classes_row');

    visibleRows.forEach((element) => {
      const checkBox = element.querySelectorAll('input[type=checkbox]')[0];
      checkBox.checked = event.target.checked;
    });
  }

  delaySearch(event) {
    const source = event.target;
    let timer = source.getAttribute('data-timer');
    $('body').on('hide.bs.modal', () => {
      this.searchBoxTarget.value = '';
      this.searchGroup();
    });

    clearTimeout(timer);
    timer = setTimeout(this.searchGroup.bind(this), 2000);
    source.setAttribute('data-timer', timer);
  }

  searchGroup() {
    const searchText = this.searchBoxTarget.value.toLowerCase();
    const rows = this.modalDataTarget.querySelectorAll('div.classes_row');

    rows.forEach((element) => {
      const { name } = element.dataset;
      const classNameIncludesSearch = name.toLowerCase().includes(searchText);
      element.classList.toggle('d-none', !classNameIncludesSearch);
    });
  }

  async fetchGoogleClasses(url) {
    try {
      const results = await fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        if (response.ok) { return response.json(); }
        return this.requestError(response.ok, I18n.t('assets.javascripts.controllers.groups.google_classroom.error', { message: response.statusText }));
      }).then((json) => {
        if (json.success === true && json.data.length === 0) { return this.requestError(false, I18n.t('assets.javascripts.controllers.groups.google_classroom.no_data_error')); }
        return json;
      });
      return results;
    } catch (error) {
      return this.requestError(false, I18n.t('assets.javascripts.controllers.groups.google_classroom.error', { message: error.message }));
    }
  }

  populateModalData(jsonData, element) {
    const targetElement = element;
    const errorMsgElement = this.modalDataErrorMessageTarget;
    if (jsonData.success === true && jsonData.data) {
      targetElement.innerHTML = '';
      targetElement.innerHTML = this.buildHtml(jsonData.data);
      return true;
    }
    errorMsgElement.innerHTML = jsonData.errors;
    return false;
  }

  buildHtml(data) {
    let htmlString = '';
    const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));

    if (sortedData.length > 0) {
      sortedData.forEach((element) => {
        let checkedString = '';
        if (element.selected === true) { checkedString = 'checked'; }
        htmlString += `<div class="m-2 border-bottom classes_row" data-target="groups--google-classroom-selection-modal.dataRow" data-name="${element.name}">`;
        htmlString += `<div id="googleClassRow${element.class_id}" class='d-flex flex-nowrap flex-row align-items-center justify-content-center mb-1'>`;
        htmlString += `<div id="googleClassSelect" class="w-10"><input name="selected" type="hidden" value="0" /><input id="selectedCheckbox${element.class_id}" class="google-credentials__checkbox" data-gName="${element.name}" data-gId="${element.class_id}" type="checkbox" value="1" ${checkedString} name="selected" data-target="groups--google-classroom-selection-modal.classCheckBox"/></div>`;
        htmlString += `<div id="googleClassName" class="w-100">${element.name}</div>`;
        htmlString += '</div></div>';
      });
    }
    return htmlString;
  }

  disableFormElements(disabled) {
    this.searchBoxTarget.disabled = disabled;
    this.submitFormTarget.disabled = disabled;
  }

  showElement(element, visible) {
    const className = 'd-none';
    if (visible && element.classList.contains(className)) {
      element.classList.remove(className);
    } else if (!visible && !element.classList.contains(className)) {
      element.classList.add(className);
    }
  }

  showModalLoadingContent() {
    this.showElement(this.modalLoadingDataTarget, true);
    this.showElement(this.modalDataTarget, false);
    this.showElement(this.modalDataErrorTarget, false);
  }

  showModalDataContent() {
    this.showElement(this.modalLoadingDataTarget, false);
    this.showElement(this.modalDataTarget, true);
    this.showElement(this.modalDataErrorTarget, false);
  }

  showModalContentError() {
    this.showElement(this.modalLoadingDataTarget, false);
    this.showElement(this.modalDataTarget, false);
    this.showElement(this.modalDataErrorTarget, true);
  }

  requestError(success, errortext) {
    return { success: success, errors: errortext };
  }
}
