import { Controller } from 'stimulus';

export default class OtpController extends Controller {
  disableSpace(event) {
    const { code } = event;
    if (code === 'Space') {
      event.preventDefault();
    }
  }
}
