import { Controller } from 'stimulus';
import { useIntersection, useVisibility } from 'stimulus-use';
import { checkEnvironment } from '../utils';

export default class DeviceThumbnailController extends Controller {
  static targets = [
    'image',
    'checkSelect',
    'offlineThumbnail',
    'onlineThumbnail',
    'sessionPin',
    'deviceSession',
    'thumbnail',
    'thumbnailIcon',
    'thumbnailIconText',
    'thumbnailErrorIconText',
    'thumbnailErrorTimeoutText',
    'thumbnailOnlineIcon',
    'checkbox',
  ];

  static counter = 0;

  get deviceSessionId() {
    return this.deviceSessionTarget.dataset.deviceSessionId;
  }

  async connect() {
    const deviceSession = this.deviceSessionTarget;
    this.apiBase = deviceSession.getAttribute('data-hub-url');
    this.isPaused = false;
    this.hasDisconnected = false;

    useIntersection(this);
    useVisibility(this);
    this.checkPrevLocation();

    const deviceId = deviceSession.getAttribute('data-device-uuid');

    if (deviceId) {
      this.isOnline = 'true';
      this.changeSelectableState('true');
    }
  }

  disconnect() {
    this.disappear();
  }

  visible() {
    this.isPaused = false;
  }

  invisible() {
    this.isPaused = true;
  }

  appear() {
    const deviceSession = this.deviceSessionTarget;
    const deviceId = deviceSession.getAttribute('data-device-uuid');

    if (deviceId) {
      this.addDevice();

      this.pollInterval = setInterval(() => {
        this.pollDevice(deviceId);
      }, 1000);
    }
  }

  disappear() {
    clearInterval(this.pollInterval);
  }

  async addDevice() {
    const groupId = window.location.href.match(/groups\/(\d+)/)[1];
    const headers = { 'Content-Type': 'application/json' };

    if (!(checkEnvironment() === 'test')) {
      headers['X-CSRF-Token'] = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
    }

    await fetch(`/groups/${groupId}/device_sessions/${this.deviceSessionTarget.dataset.deviceSessionId}`, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers,
      mode: 'cors',
    });
  }

  async pollDevice(deviceId) {
    if (this.isPaused) {
      return;
    }

    const deviceSession = this.deviceSessionTarget;
    const currentImage = this.imageTarget;
    const cachebuster = Math.random() * Number.MAX_SAFE_INTEGER;

    const response = await fetch(`${this.apiBase}/thumb/${deviceId}?cachebuster=${cachebuster}`).then((res) => {
      if (res.status >= 400 && res.status < 600) {
        DeviceThumbnailController.counter ++;
      }

      if (DeviceThumbnailController.counter > 60) {
        this.hasDisconnected = true;
        this.errorCode = res.status;
        this.isLoading = false;
        this.isOnline = 'false';
        this.changeSelectableState('false');
        this.toggleThumbnail();
      }

      if (checkEnvironment() === 'test') {
        const testResponse = { ok: true };
        return testResponse;
      }
      return res;
    });

    if (response?.ok) {
      if (this.hasDisconnected) {
        const personType = deviceSession.dataset.personType === 'Child' ? 'students' : 'users';
        await fetch(`/${personType}/${deviceSession.dataset.personId}/device_sessions`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
        }).then((userResponse) => {
          if (userResponse?.ok) {
            userResponse.json().then((parsedResponse) => {
              if (parsedResponse?.ok) {
                const id = parsedResponse.device_session_id;
                const newHref = this.thumbnailTarget.href.replaceAll(this.deviceSessionId, id);
                this.thumbnailTarget.href = newHref;
              }
            });
          }
        });
        this.hasDisconnected = false;
        DeviceThumbnailController.counter = 0;
      }

      if (!(checkEnvironment() === 'test')) {
        const blob = await response.blob();
        const fileReader = new FileReader();

        fileReader.onload = (e) => {
          currentImage.src = e.target.result;
          this.setToOnline();
        };

        fileReader.readAsDataURL(blob);
      } else {
        this.setToOnline();
      }
    }
  }

  onlineStatus() {
    return this.data.get('online-status');
  }

  checkPrevLocation() {
    if (!this.deviceSessionId) return;
    const prevUrl = document.referrer.split('?');

    if (prevUrl.length === 2 && prevUrl[1].includes('device_session=', 'group_id=')) {
      this.isLoading = true;
      this.toggleThumbnail();
    }
  }

  changeSelectableState(state) {
    if (state === 'false') {
      this.offlineThumbnailTarget.classList.remove('d-none');
      this.checkSelectTarget.classList.add('d-none');
      this.sessionPinTarget.classList.add('d-none');
      this.onlineThumbnailTarget.classList.add('d-none');
    } else {
      this.offlineThumbnailTarget.classList.add('d-none');
      this.checkSelectTarget.classList.remove('d-none');
      this.sessionPinTarget.classList.remove('d-none');
      this.onlineThumbnailTarget.classList.remove('d-none');
    }
    this.deviceSessionTarget.dataset['thumbnail-ItemSelectable'] = state;
    this.data.set('online-status', this.isOnline);
  }

  // eslint-disable-next-line class-methods-use-this
  padTime(value) {
    return value < 10 ? `0${value}` : value;
  }

  seenTimeAgo() {
    const now = new Date();
    const past = new Date(this.deviceSessionTarget.dataset.deviceSessionLastSeenAt);
    const gap = new Date(Math.abs(now.getTime() - past.getTime()));

    return `${this.padTime(gap.getMinutes())}:${this.padTime(gap.getSeconds())}`;
  }

  setToOnline() {
    if (this.isLoading) {
      this.isLoading = false;
      this.toggleThumbnail();
    }
    this.isOnline = 'true';
    this.changeSelectableState('true');
  }

  toggleThumbnail() {
    this.thumbnailIconTextTarget.thumbnailErrorIconText = '';
    this.thumbnailIconTextTarget.thumbnailErrorTimeoutTextTarget = '';

    if (this.isLoading === true) {
      this.thumbnailIconTarget.className = '';
      this.thumbnailIconTarget.classList.add('fas', 'fa-spinner');
      // eslint-disable-next-line no-undef
      this.thumbnailIconTextTarget.textContent = `${I18n.t('assets.javascripts.device_thumbnails.loading')}`;
    } else if (this.isLoading === 'error') {
      this.thumbnailIconTarget.className = '';
      this.thumbnailIconTarget.classList.add('fas', 'fa-image-slash');
      // eslint-disable-next-line no-undef
      this.thumbnailIconTextTarget.textContent = `${I18n.t('assets.javascripts.device_thumbnails.error')}`;
      this.thumbnailErrorIconTextTarget.textContent = this.errorCode;
      this.thumbnailErrorTimeoutTextTarget.textContent = this.seenTimeAgo();
    } else {
      this.thumbnailIconTarget.className = '';
      this.thumbnailIconTarget.classList.add('fa', 'fa-power-off');
      // eslint-disable-next-line no-undef
      this.thumbnailIconTextTarget.textContent = `${I18n.t('assets.javascripts.device_thumbnails.offline')}`;
    }
  }
}
