import { Controller } from 'stimulus';

export default class GroupsFormController extends Controller {
  static targets = ['participantMembers'];

  kindChange(event) {
    const { target } = event;
    const { dataset } = target;
    const { type } = dataset;
    this.showParticipantMembers(type);
  }

  /**
   * @private
   * @param type
   */
  showParticipantMembers(type) {
    this.participantMembersTargets.forEach((participantMembersTarget) => {
      const { dataset } = participantMembersTarget;
      const { type: targetType } = dataset;
      participantMembersTarget.classList.toggle('collapse', type !== targetType);
    });
  }
}
