import { Controller } from 'stimulus';

export default class PinController extends Controller {
  static targets = [
    'icon',
    'pinnedItems',
    'notPinnedItems',
    'pinnedContainer',
    'pinnableItem',
  ];

  /**
   *
   * @param event {CustomEvent}
   */
  toggle(event) {
    this.togglePinStatus(event.target.parentNode);
  }

  togglePinStatus(el) {
    const isPressed = el.getAttribute('aria-pressed');

    if (isPressed === 'false') {
      el.setAttribute('aria-pressed', 'true');
      this.movePin(el, 'move');
    } else {
      el.setAttribute('aria-pressed', 'false');
      this.movePin(el, 'remove');
    }
  }

  movePin(el, action) {
    const { itemId, source } = el.dataset;
    if (!itemId) return;

    const item = this.getItem(itemId, source)[0];
    let container;

    if (action === 'move') {
      container = this.pinnedItemsTarget;
      this.showPinnedHeading(action);
      container.append(item);
    } else {
      container = this.notPinnedItemsTarget;
      this.showPinnedHeading(action);
      container.append(item);
    }
  }

  getItem(itemId, source) {
    return this.pinnableItemTargets.filter((target) => {
      const { id } = target.dataset;
      if (id.split('-')[0] == source && id.split('-')[1] == itemId) {
        return target;
      }
    });
  }

  showPinnedHeading(action) {
    if ((action === 'move' && this.pinnedItemsTarget.children.length < 1)
      || (action === 'remove' && this.pinnedItemsTarget.children.length === 1)) {
      this.pinnedContainerTarget.classList.toggle('hidden');
    }
  }
}
