import {Controller} from 'stimulus';

export default class DeviceSessionsSelectedController extends Controller {
  static targets = ['title', 'body']

  itemsSelected(event) {
    const { detail: { selectedCount } } = event;
    // eslint-disable-next-line no-undef
    const title = I18n.t('assets.javascripts.controllers.device_sessions.selected.title', { count: selectedCount });
    const body = I18n.t('assets.javascripts.controllers.device_sessions.selected.body', { count: selectedCount });

    this.titleTarget.innerText = title;
    this.bodyTarget.innerText = body;
  }
}
