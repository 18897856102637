import {Controller} from 'stimulus';

export default class ToggleController extends Controller {
  toggle(event) {
    event.preventDefault();
    const button = this.element;
    if (button.getAttribute('aria-pressed') === 'true') {
      button.setAttribute('aria-pressed', 'false');
    } else {
      button.setAttribute('aria-pressed', 'true');
    }
  }
}
