import { Controller } from 'stimulus';

export default class KeywordModifiersController extends Controller {
  static targets = [
    'editBtn',
    'saveEditBtn',
    'deleteBtn',
    'input',
    'matchType',
    'matchTypePill',
    'modifierForm',
    'token',
  ];

  editList() {
    this.showEditFields();
  }

  save() {
    this.hideEditFields();
  }

  submitForm() {
    Rails.fire(this.modifierFormTarget, 'submit');
  }

  showEditFields() {
    this.editBtnTarget.classList.remove('show');
    this.saveEditBtnTarget.classList.add('show');
    this.deleteBtnTarget.classList.add('show');
    this.matchTypePillTarget.classList.remove('show');
    this.matchTypeTarget.classList.add('show');

    this.inputTargets.forEach(input => {
      input.readOnly = false;
      input.classList.remove('border-0');
    });
  }

  hideEditFields() {
    this.editBtnTarget.classList.add('show');
    this.saveEditBtnTarget.classList.remove('show');
    this.deleteBtnTarget.classList.remove('show');
    this.matchTypePillTarget.classList.add('show');
    this.matchTypeTarget.classList.remove('show');

    this.inputTargets.forEach(input => {
      input.readOnly = true;
      input.classList.add('border-0');
    });
  }

  onPostSuccess(event) {
    const { detail } = event;
    const [result] = detail;
    const { payload } = result;
    const { success, keyword_id, match_type, flash_box, token } = payload;

    $(`[data-flash-box="${keyword_id}"]`).html(flash_box); // Show flash message.
    if (success) {
      this.matchTypePillTarget.innerText = match_type; // Change content in match type.
    } else {
      this.tokenTarget.value = token; // fill it with old value.
    }
  }

  onDeleteSuccess(event) {
    const { detail } = event;
    const [result] = detail;
    const { payload } = result;
    const { success, keyword_id, modifier_id, flash_box, modifier_count, modifier_count_text, keyword_count, keyword_count_text } = payload;

    $(`[data-flash-box="${keyword_id}"]`).html(flash_box); // Show flash message.
    if (success) {
      $(`[data-modifier-row="${modifier_id}"]`).remove(); // Remove row.
      $(`[data-modifier-count="${keyword_id}"]`).text(modifier_count_text); // Change count text.

      if (modifier_count === 0) {
        $(`[data-modifier-table="${keyword_id}"]`).remove(); // Remove modifier table.
        $(`[data-info-box="${keyword_id}"]`).addClass('show'); // Show info box.
        $(`[data-keyword-row="${keyword_id}"]`).remove(); // Remove keyword row.
      }

      if (keyword_count === 0) {
        $('[data-alert-box="true"]').html(keyword_count_text); // Show flash message.
        $('[data-keyword-table-header="true"]').addClass('invisible'); // Hide keyword table header.
      }
    }
  }
}
