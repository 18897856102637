export default class RemindersSettings {

  constructor(selector = "alert-reminders-settings") {
    document.addEventListener('DOMContentLoaded', () => {
      this.setup(selector);
    });
  }

  setup(selector) {
    this.settings_area = document.forms.namedItem(selector);
    this.add_button = this.settings_area ? this.settings_area.querySelector("#add_reminder_button") : null;
    this.fieldset = this.settings_area ? this.settings_area.querySelector("fieldset[name='reminders']") : null;
    this.reminder_index = this.add_button ? parseInt(this.add_button.dataset.reminderIndex || 0) : 0;

    this.add_button ? this.add_button.addEventListener('click', this.add_reminder.bind(this)) : null;
    this.fieldset ? this.setup_remove_buttons() : null;
  }

  add_reminder(event) {
    event.preventDefault();
    this.add_button.dataset.reminderIndex = ++this.reminder_index;

    const template = document.getElementById("reminder-template").content.cloneNode(true);

    for (const node of template.querySelectorAll('[data-name]')) {
      node.name = `reminder[reminders][${this.reminder_index}][${node.dataset.name}]${node.multiple ? '[]' : ''}`;
      node.id = `reminder_reminders_${this.reminder_index}_${node.dataset.name}`;
    }

    for (const node of template.querySelectorAll('[data-for]')) {
      node.htmlFor = `reminder_reminders_${this.reminder_index}_${node.dataset.for}`;
    }

    this.fieldset.appendChild(template);
    // Re-initiate Select2
    $('.select2').select2({
      theme: 'bootstrap',
      width: '100%'
    });
    //  Setup remove button listeners
    this.setup_remove_buttons();
  }

  setup_remove_buttons() {
    const remove_buttons = this.fieldset.querySelectorAll("[data-remove-button]") || [];
    for (const button of remove_buttons) {
      button.removeAllListeners;
      button.addEventListener('click', this.remove_reminder)
    }
  }

  remove_reminder(event) {
    event.preventDefault();
    const button = event.target;
    const form_row = button.closest(".form-group.row");
    form_row ? form_row.remove() : false;
  }
}
