import { Controller } from 'stimulus';

export default class BulkActionsController extends Controller {
  static targets = ['actionSelect', 'userGroupOption', 'mergeUsersPrimaryUser', 'createMembershipsOption', 'createMembershipsCheckbox'];

  connect() {
    this.initialiseSelect2();
    this.hideSelects();
  }

  initialiseSelect2() {
    $(this.actionSelectTarget).on('change.select2', (event) => {
      const { target } = event;
      const changeEvent = new CustomEvent('linked.change', { bubbles: true });
      target.dispatchEvent(changeEvent);
      this.optionSelected(event);
    });
  }

  optionSelected() {
    const usersPage = this.actionSelectTarget.dataset.pageType;

    switch (this.actionSelectTarget.value) {
      case 'assign_to_user_group':
        this.userGroupOptionTarget.style.display = 'block';
        if (usersPage === 'active') {
          this.mergeUsersPrimaryUserTarget.style.display = 'none';
          this.createMembershipsOptionTarget.style.display = 'none';
          this.createMembershipsCheckboxTarget.style.display = 'none';
        }
        break;
      case 'merge_users':
        this.mergeUsersPrimaryUserTarget.style.display = 'block';
        this.userGroupOptionTarget.style.display = 'none';
        this.createMembershipsOptionTarget.style.display = 'none';
        this.createMembershipsCheckboxTarget.style.display = 'none';
        break;
      case 'create_memberships':
        this.userGroupOptionTarget.style.display = 'none';
        this.mergeUsersPrimaryUserTarget.style.display = 'none';
        this.createMembershipsOptionTarget.style.display = 'block';
        this.createMembershipsCheckboxTarget.style.display = 'block';
        break;
      default:
        this.hideSelects();
    }
  }

  hideSelects() {
    if (this.hasUserGroupOptionTarget) this.userGroupOptionTarget.style.display = 'none';
    if (this.hasMergeUsersPrimaryUserTarget) this.mergeUsersPrimaryUserTarget.style.display = 'none';
    if (this.hasCreateMembershipsCheckboxTarget) this.createMembershipsCheckboxTarget.style.display = 'none';
    if (this.hasCreateMembershipsOptionTarget) this.createMembershipsOptionTarget.style.display = 'none';
  }
}
