import { Controller } from 'stimulus';

export default class googleClassroomCredentialsModalController extends Controller {
  static targets = ['searchBox', 'tableRow']

  delaySearch(event) {
    const source = event.target;
    let timer = source.getAttribute('data-timer');
    $('body').on('hide.bs.modal', () => {
      this.searchBoxTarget.value = '';
      this.searchGroup();
    });

    clearTimeout(timer);
    timer = setTimeout(this.searchGroup.bind(this), 2000);
    source.setAttribute('data-timer', timer);
  }

  searchGroup() {
    const searchText = this.searchBoxTarget.value.toLowerCase();
    const rows = this.tableRowTargets;

    rows.forEach((element) => {
      const searchMatch = element.innerText.toLowerCase().indexOf(searchText) > -1;
      element.classList.toggle('d-none', !searchMatch);
    });
  }

  disableSubmit(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      return false;
    }
    return true;
  }

  selectAll(event) {
    const table = event.currentTarget.closest('table').getElementsByTagName('tbody')[0];
    const visibleRows = table.querySelectorAll('tr:not(.d-none).google-classroom-credentials__group-row');

    visibleRows.forEach((element) => {
      const checkBox = element.querySelectorAll('input[type=checkbox]')[0];
      checkBox.checked = true;
    });
  }
}
