import * as Dropzone from 'dropzone';
import { DirectUpload } from '@rails/activestorage';
import { DirectUploadController } from './direct_upload_controller';

/**
 *
 * @param controller {DropzoneBaseController}
 * @returns {Dropzone}
 */
export function createDropZone(controller) {
  /**
   * @type {{headers: {"X-CSRF-Token": string}, acceptedFiles: string, addRemoveLinks: boolean, autoQueue: boolean, maxFilesize: number, uploadMultiple: boolean, url: string, maxFiles: number}}
   */
  const options = {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    uploadMultiple: controller.maxFiles > 1,
  };
  return new Dropzone(controller.element, options);
}

/**
 *
 * @param source {DropzoneBaseController}
 * @param file {DropzoneFile}
 * @returns {DirectUploadController}
 */
export function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

/**
 *
 * @param file {DropzoneFile}
 * @param url {string}
 * @param controller {DirectUploadController}
 * @returns {DirectUpload}
 */
export function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}
