import { Controller } from 'stimulus';

import { replaceOrAppendHtmlFromString } from '../../utils';

export default class ScrollController extends Controller {
  static targets = ['itemsContainer', 'spinner', 'emptyMessage', 'alertCounts', 'reminderCount', 'viewAll'];

  connect() {
    this.getNextPage();
  }

  get currentPage() {
    return parseInt(this.element.dataset.page, 10);
  }

  set currentPage(page) {
    this.element.dataset.page = page;
  }

  get totalPages() {
    return parseInt(this.element.dataset.totalPages, 10);
  }

  set totalPages(pagesCount) {
    this.element.dataset.totalPages = pagesCount;
  }

  scrollHandler() {
    const sDiff = this.itemsContainerTarget.scrollHeight - this.itemsContainerTarget.offsetHeight;
    const loadMembers = this.itemsContainerTarget.scrollTop === sDiff;

    if (loadMembers) {
      this.getNextPage();
    }
  }

  canLoadMorePages() {
    return this.currentPage < this.totalPages && !this.currentlyLoading;
  }

  getNextPage() {
    if (!this.canLoadMorePages()) {
      return;
    }

    const $this = this;
    const { url } = this.element.dataset;
    this.spinnerTarget.classList.add('show');
    this.currentlyLoading = true;

    /* global Rails */

    Rails.ajax(
      {
        url,
        type: 'get',
        data: `page=${this.currentPage + 1}`,
        dataType: 'json',
        success: (data) => {
          $this.spinnerTarget.classList.remove('show');
          $this.emptyMessageTarget.classList.remove('show');
          $this.currentPage = this.currentPage + 1;
          $this.totalPages = data.payload.total_pages;
          if ($this.hasAlertCountsTarget) {
            $this.alertCountsTarget.innerText = data.payload.unread_entries;
          }
          data.payload.html.forEach((member) => {
            replaceOrAppendHtmlFromString(member, $this.itemsContainerTarget);
          });

          if (data.payload.total_entries === 0) {
            $this.emptyMessageTarget.classList.add('show');
          }

          if (data.payload.reminder_count <= 3) {
            this.viewAllTarget.remove();
          } else if (data.payload.reminder_count) {
            this.viewAllTarget.innerText = `View More (${data.payload.reminder_count - 3})`;
          }

          this.currentlyLoading = false;
        },
      },
    );
  }

  viewAll() {
    if (!this.canLoadMorePages()) {
      return;
    }

    const $this = this;
    const { url } = this.viewAllTarget.dataset;
    this.spinnerTarget.classList.add('show');
    this.currentlyLoading = true;

    Rails.ajax(
      {
        url,
        type: 'get',
        data: 'page=1',
        dataType: 'json',
        success: (data) => {
          $this.spinnerTarget.classList.remove('show');
          $this.emptyMessageTarget.classList.remove('show');
          $this.currentPage = this.currentPage + 1;
          $this.totalPages = data.payload.total_pages;
          if ($this.hasAlertCountsTarget) {
            $this.alertCountsTarget.innerText = data.payload.unread_entries;
          }
          data.payload.html.forEach((member) => {
            replaceOrAppendHtmlFromString(member, $this.itemsContainerTarget);
          });

          this.currentlyLoading = false;
        },
      },
    );

    this.viewAllTarget.remove();
  }

  ignoreNotification(event) {
    const url = event.currentTarget.dataset.ignoreUrl;
    const listObj = event.currentTarget.closest('[data-notification-card="true"]');

    Rails.ajax({
      url,
      type: 'patch',
      dataType: 'json',
      success: (data) => {
        listObj.remove();
        this.alertCountsTarget.innerText = data.payload.unread_entries;
        if (data.payload.total_entries === 0) {
          this.emptyMessageTarget.classList.add('show');
        }
      },
    });
  }

  ignoreReminder(event) {
    const url = event.currentTarget.dataset.ignoreUrl;
    const listObj = event.currentTarget.closest('[data-notification-card="true"]');

    Rails.ajax({
      url,
      type: 'delete',
      dataType: 'json',
      success: (data) => {
        listObj.remove();

        const event = new CustomEvent('remindersUpdated', {
          bubbles: true,
          detail: {
            count: data.payload.total_entries,
          },
        });

        document.dispatchEvent(event);

        // document.getElementById("reminder-count").innerText = data.payload.total_entries;
        if (data.payload.total_entries === 0) {
          this.emptyMessageTarget.classList.add('show');
        }
      },
    });
  }
}
