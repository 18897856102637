import { Controller } from 'stimulus';

export default class SyncLogsController extends Controller {
    static targets = ['modalBody', 'modalTitle'];

    logDetailModal(event) {
      const element = event.target;
      const bodyElement = this.modalBodyTarget;
      const titleElement = this.modalTitleTarget;
      $('#logDetailModal').on('show.bs.modal', () => {
        titleElement.innerHTML = `${element.dataset.success} sync at ${element.dataset.datetime}`;
        fetch(`/administration/sync_logs/${element.dataset.id}/detail`)
          .then(function (response) { return response.json(); })
          .then(function (json) { bodyElement.innerHTML = json.log; });
      });
    }
}
