/**
 *
 * @param root {Document | HTMLElement}
 * @param selector {string}
 * @returns {Element}
 */
export function findElement(root = document, selector) {
  return root.querySelector(selector);
}

/**
 * @returns {string}
 * @param name
 */
export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute('content');
  }
  return '';
}

/**
 *
 * @param el {HTMLElement}
 */
export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

/**
 *
 * @param el {HTMLElement}
 * @param referenceNode {HTMLElement}
 * @returns {HTMLElement}
 */
export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}
