import { Controller } from 'stimulus';

export default class NotificationConfigsController extends Controller {
  static targets = ['notificationConfigForm', 'configToggle', 'configSubToggle']

  connect() {
    const pageNav = document.querySelector('[data-page-nav="1"]');

    if (!pageNav) {
      return;
    }

    pageNav.classList.add('page-nav-notification-setting');
    this.digestToggles();
  }

  // On Connect get all the Digest and ActionPlan toggle states
  // If there are any digest enabled and the ActionPlan is not enabled enable it

  // On Event make sure ActionPlan is turned on when switching on Digest
  // Disable digest it ActionPlan is disabled.
  digestToggles(event) {
    let actionPlan = '';
    let digest = '';

    this.configSubToggleTargets.forEach((target) => {
      const notificationTogglesObj = this.actionPlanDigestToggle(target);

      if (notificationTogglesObj.actionPlan) actionPlan = notificationTogglesObj.actionPlan;
      if (notificationTogglesObj.digest) digest = notificationTogglesObj.digest;

      // ActionPlan needs to be enabled for digest to work
      if (!actionPlan.checked && digest.checked && !event) {
        actionPlan.checked = true;
        this.submitForm();
      }
    });
    if (!event) return;

    if (event.target === actionPlan && !actionPlan.checked && digest.checked) {
      digest.checked = false;
    } else if (event.target === digest && digest.checked && !actionPlan.checked) {
      actionPlan.checked = true;
    }
  }

  // Get ActionPlan and Digest Toggle Targets
  actionPlanDigestToggle(target) {
    const notificationToggles = {};

    if (!target) { return notificationToggles; }
    if (target.dataset.type === 'ConcernKind') {
      const toggleType = target.dataset.alertType;
      if (toggleType === 'send_notification') notificationToggles.actionPlan = target;
      else if (toggleType === 'send_digest') notificationToggles.digest = target;
    }
    return notificationToggles;
  }

  toggleConfig(event) {
    this.toggleSubConfigs(event.target.checked);
  }

  toggleSubConfigs(check) {
    this.configSubToggleTargets.forEach((target) => {
      target.checked = check;
    });
  }

  updateCheckedCount() {
    const checkedConfigs = this.countCheckedConfigs();
    if (checkedConfigs > 0 && !this.configToggleTarget.checked) {
      this.configToggleTarget.checked = true;
      this.toggleNotifications();
    } else if (checkedConfigs === 0 && this.configToggleTarget.checked) {
      this.configToggleTarget.checked = false;
      this.toggleNotifications();
    }
  }

  countCheckedConfigs() {
    return this.configSubToggleTargets.filter((target) => target.checked).length;
  }

  toggleNotifications() {
    const notificationId = this.configToggleTarget.dataset.id;
    const event = new CustomEvent('toggle-notifications', {
      detail: {
        id: notificationId,
      },
    });

    window.dispatchEvent(event);
  }

  submitForm() {
    Rails.fire(this.notificationConfigFormTarget, 'submit');
  }
}
