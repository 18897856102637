import {Controller} from 'stimulus'
import {replaceHtmlFromString} from "../../utils";
import {removeElement} from "../../src/utils";

export default class BackdropFormRowController extends Controller {
  static targets = ['controls', 'input', 'textarea', 'select', 'userGroupSelect'];

  connect() {
    if (this.hasUserGroupSelectTarget) {
      $(this.userGroupSelectTarget).select2({
        tags: true,
      });

      $(this.userGroupSelectTarget).on('select2:select', this.userGroupSelected.bind(this))
    }
  }

  editable(event = null) {
    this.inputTargets.forEach(input => input.readOnly = false);
    this.textareaTargets.forEach(target => target.readOnly = false);
    this.data.set("editing", true)
  }

  lock(event = null) {
    this.inputTargets.forEach(input => input.readOnly = true);
    this.textareaTargets.forEach(target => target.readOnly = true);
    this.data.set("editing", false)
  }

  saving(event) {
    this.lock()
  }

  saved(event) {
    //  Replace this.element with the resulting HTML
    const {detail} = event;
    const [response] = detail;
    const {action, html, payload} = response;

    if (action === 'destroy') {
      removeElement(this.element)
    } else {
      if (html) {
        return replaceHtmlFromString(html, this.element)
      }
      const {html: newHtml} = payload
      if (newHtml) {
        return replaceHtmlFromString(newHtml, this.element)
      }
    }
  }

  failed(event) {
    this.editable();
  }

  userGroupSelected(event) {
    const {params} = event;
    const {data: detail} = params;
    const newUserGroupEvent = new CustomEvent('user-group:change', {bubbles: true, detail});
    this.element.dispatchEvent(newUserGroupEvent);
  }
}
