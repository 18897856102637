import {Controller} from 'stimulus'

export default class SliderController extends Controller {
  static targets = ['window', 'slide', 'controls', 'control'];

  connect() {
    this.updateCurrentIndex();
    this.initializeIntersectObserver();
  }

  selectSlide(event) {
    const {currentTarget} = event;
    const index = this.controlTargets.indexOf(currentTarget);
    const selectedSlide = this.slideTargets[index];
    const options = {
      behaviour: "smooth",
      block: "center",
      inline: "center"
    };
    selectedSlide.scrollIntoView(options);
    this.updateCurrentIndex(index);
  }

  /**
   * @private
   */
  initializeIntersectObserver() {
    const options = {
      root: this.windowTarget,
      threshold: 0.5,
    };
    this.observer = new IntersectionObserver(this.intersection.bind(this), options);
    this.slideTargets.forEach(slide => this.observer.observe(slide))
  }

  /**
   * @private
   */
  intersection(entries, observer) {
    entries.forEach(entry => {
      const {target, isIntersecting: visible} = entry;
      const index = this.slideTargets.indexOf(target);
      if (visible) {
        this.updateCurrentIndex(index);
      }
    })
  }

  /**
   * @private
   */
  updateCurrentIndex(currentIndex = 0) {
    this.controlTargets.forEach((control, controlIndex) => {
      control.classList.toggle("slider__control--active", controlIndex === currentIndex)
    })
  }
}
