import {Controller} from 'stimulus'
import {replaceOrInsertHtmlFromString, incrementCountElement} from "../../../utils";

export default class MemberController extends Controller {
  static targets = ["member", "checkedIcon", "plusIcon"];

  connect() {
    this.checkedIcons().forEach(icon => {
      if (icon.classList.contains("show")) {
        this.plusIconTarget.classList.remove("show");
        this.checkedIconTarget.classList.add("show");
        return;
      }
    });
    const participantCard = document.getElementById(`group_participant_child_${this.memberId()}`)
    if (participantCard) {
      this.plusIconTarget.classList.remove("show");
      this.checkedIconTarget.classList.add("show");
    }
  }

  addMemberToForm() {
    const memberId = this.memberId();

    Rails.ajax({
      url: `/students/${memberId}`,
      type: "get",
      dataType: "json",
      success: (data) => {
        const html = data.partials.group_member.participant_html;
        const participantsGroup = document.querySelector('[id^="group_participants_group"]');
        const parent = participantsGroup.querySelector('.group-members');
        const target = participantsGroup.querySelector('.add-member');
        replaceOrInsertHtmlFromString(html, parent, target, "before");
        const groupFormEvent = new CustomEvent('group_form:change', {
          detail: {
            memberId: memberId,
            quantityChange: +1,
          },
          bubbles: true,
        });
        document.dispatchEvent(groupFormEvent);
      }
    })
  }

  removeMemberFromForm() {
    const formCard = document.getElementById(`group_participant_child_${this.memberId()}`);
    const groupFormEvent = new CustomEvent('group_form:change', {
      detail: {
        memberId: this.memberId(),
        quantityChange: -1,
      },
      bubbles: true,
    });
    document.dispatchEvent(groupFormEvent);
    formCard.remove();
  }

  plusIcons() {
    return document.getElementsByClassName(`plus-icon-${this.memberId()}`);
  }

  checkedIcons() {
    return document.getElementsByClassName(`checked-icon-${this.memberId()}`);
  }

  memberId() {
    return this.memberTarget.dataset.memberId
  }
}
