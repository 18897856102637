import { Controller } from 'stimulus';

export default class NIValidator extends Controller {
  static get targets() { return ['input', 'message']; }

  connect() {
    this.validate();
  }

  validate() {
    const input = this.inputTarget;
    const { value } = input;
    const compareValue = value.split(/\s+/).join('');
    const exp1 = /^[A-CEGHJ-NOPR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D\s]{1}/i;
    const exp2 = /(^GB)|(^BG)|(^NK)|(^KN)|(^TN)|(^NT)|(^ZZ).+/i;

    const valid = compareValue.match(exp1) && !compareValue.match(exp2);
    this.messageTarget.classList.toggle('d-none', valid);
  }
}
