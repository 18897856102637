import {Controller} from 'stimulus'
import {deSelectAllGridItems} from '../utils';

export default class FavouriteWebsitesController extends Controller {
  static targets = ["input", "launchWebsiteForm", "launchWebsiteInput", "addLink", "addLinkInput", "favouritesList"]

  created(event){
    const [data] = event.detail
    this.favouritesListTarget.innerHTML = this.favouritesListTarget.innerHTML + data.body.innerHTML
    this.addLinkInputTarget.value = ''
    this.addLinkTarget.classList.add('d-none')
  }

  opened(_event){
    this.launchWebsiteInputTarget.value = ''
    deSelectAllGridItems(this.element);
  }

  updateAddToFavouriteLink(){
    const targetUrl = this.urlWithProtocol(this.inputTarget.value)
    this.addLinkInputTarget.value = targetUrl

    try {
      const displayUrl = new URL(targetUrl).host
      this.addLinkTarget.classList.remove('d-none')
      this.addLinkTarget.value = `${I18n.t('assets.javascripts.controllers.device_sessions.add_favourite')} ${displayUrl}`
    } catch (error) {
      this.addLinkTarget.classList.add('d-none')
    }
  }

  urlWithProtocol(url){
    if(!url.toLowerCase().startsWith('http')){
      url = 'https://' + url
    }
    return url
  }

  removed(event){
    const [data] = event.detail
    this.favouritesListTarget.querySelector(`[data-favourite-id="${data.payload.id}"]`).remove()
  }

  sendWebsiteToDevice(event){
    this.launchWebsiteInputTarget.value = event.target.dataset.favouriteWebsitesUrlValue
    Rails.fire(this.launchWebsiteFormTarget, 'submit');
  }
}
