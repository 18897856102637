import { Controller } from 'stimulus';
import { useVisibility } from 'stimulus-use';
import { createElementFromString, checkEnvironment } from '../../utils';

export default class PersonDeviceSessionController extends Controller {
  static targets = ['sessionCard'];

  connect() {
    const { personId } = this.sessionCardTarget.dataset;
    this.isPaused = false;
    useVisibility(this);

    this.pollInterval = setInterval(() => {
      this.setSessionPartial(personId);
    }, checkEnvironment() === 'test' ? 1000 : 15000);
  }

  disconnect() {
    clearInterval(this.pollInterval);
  }

  visible() {
    this.isPaused = false;
  }

  invisible() {
    this.isPaused = true;
  }

  async setSessionPartial(personId) {
    if (this.isPaused) {
      return;
    }

    const thumbnailUrl = `/students/${personId}/device_sessions/thumbnail`;

    const response = await fetch(thumbnailUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
    const { html } = await response.json();

    if (html) {
      const newSessionPartial = createElementFromString(html);
      this.sessionCardTarget.outerHTML = newSessionPartial.outerHTML;
    }
  }
}
