import { Controller } from 'stimulus';

export default class SchoolSearchController extends Controller {
  static targets = ['searchBox'];

  search(event) {
    const lastSearchParam = this.getSearchUrlParam();
    const currentSearchString = event.target.value;
    if (lastSearchParam !== currentSearchString) {
      this.redirectToFilteredResultsPage(currentSearchString);
    }
  }

  redirectToFilteredResultsPage(searchString) {
    const url = `${window.location.origin}/manage_user_group_templates?search=${searchString}`;
    window.location.assign(url);
  }

  getSearchUrlParam() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('search');
  }
}
