import {Controller} from 'stimulus'

export default class DeviceSessionsControlsController extends Controller {
  /**
   * @return {boolean}
   */
  get isDisabled() {
    const isDisabled = this.data.get('disabled');
    if (typeof isDisabled === 'string') {
      return isDisabled === 'true';
    }

    return isDisabled || false;
  }

  /**
   * @param disabled {boolean | string}
   */
  set isDisabled(disabled) {
    let isDisabled;
    if (typeof disabled === 'string') {
      isDisabled = disabled === 'true';
    } else {
      isDisabled = disabled || false;
    }

    this.data.set('disabled', isDisabled);
    this.element.setAttribute('aria-disabled', isDisabled);
    const inputs = [...this.element.querySelectorAll('input, textarea, select, button[type=submit]:not(#broadcastStart)')];
    inputs.forEach((input) => {
      input.disabled = isDisabled;
    });
  }

  connect() {
    this.isDisabled = this.isDisabled;
  }

  itemsSelected(event) {
    const { detail: { itemsSelected } } = event;
    this.isDisabled = !itemsSelected;
  }
}
