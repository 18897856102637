import {Controller} from 'stimulus'
import {
  appendHtmlFromString,
  closeModal,
  getAjaxData,
  replaceHtmlFromString,
  submitFormRemotely,
  deSelectAllGridItems,
  setIconState,
} from "../../utils";
import {removeElement} from "../../src/utils";

export default class DevicesWebsiteListsController extends Controller {
  static targets = ['searchForm', 'searchResults', 'noResults', 'clearList', 'newWebsiteListModal'];

  websiteListCreated(event) {
    const {action, payload} = getAjaxData(event)
    if (action !== "website_list_created") {
      return;
    }
    const {modalHtml, itemHtml, websiteListId, deviceId} = payload

    // Close modal
    closeModal(this.newWebsiteListModalTarget)
    // Remove the "No results" message if there is one
    if (this.hasNoResultsTarget) {
      removeElement(this.noResultsTarget);
    }
    // Add itemHtml to results list
    const el = appendHtmlFromString(itemHtml, this.searchResultsTarget)
    el.scrollIntoView({behavior: 'smooth', block: 'end'})
    // Replace modal with modalHtml
    replaceHtmlFromString(modalHtml, this.newWebsiteListModalTarget)

    let hiddenDeviceIdField = document.createElement("INPUT");
    hiddenDeviceIdField.setAttribute("type", "hidden");
    hiddenDeviceIdField.setAttribute("id", "website_list_device_id");
    hiddenDeviceIdField.setAttribute("name", "website_list[device_id]");
    hiddenDeviceIdField.setAttribute("value", deviceId);

    document.getElementById(`hidden-device-id-${websiteListId}`).appendChild(hiddenDeviceIdField);
  }

  // Listening for the `search` event on the search input
  inputSearch(event) {
    const {target} = event;
    if (!target.value) {
      submitFormRemotely(this.searchFormTarget)
    }
  }

  searchResult(event) {
    const {detail} = event;
    const [result] = detail;
    const {action, payload} = result
    if (action !== "search") {
      return
    }

    const {html} = payload;
    this.searchResultsTarget.innerHTML = html
  }

  toggleClearButton(event) {
    if (event.detail.itemsSelected) {
      this.clearListTarget.classList.add("website-list__clear--active");
    } else {
      this.clearListTarget.classList.remove("website-list__clear--active");
    }
  }

  deviceListsCleared(event) {
    const { action, payload } = getAjaxData(event);
    setIconState({ action, payload });
    deSelectAllGridItems(this.element);
  }
}
