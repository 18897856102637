import { Controller } from 'stimulus';
import { useVisibility } from 'stimulus-use';

export default class DeviceSessionsActivityController extends Controller {
  static targets = ['thumbnailsContainer']

  connect() {
    const { groupId } = this.thumbnailsContainerTarget.dataset;
    this.isPaused = false;
    useVisibility(this);

    this.pollInterval = setInterval(() => {
      this.updateActivity(groupId);
    }, 10000);
  }

  disconnect() {
    clearInterval(this.pollInterval);
  }

  visible() {
    this.isPaused = false;
  }

  invisible() {
    this.isPaused = true;
  }

  async updateActivity(groupId) {
    if (this.isPaused) {
      return;
    }

    const deviceSessionsUrl = `/groups/${groupId}/device_sessions`;

    await fetch(deviceSessionsUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    });
  }
}
