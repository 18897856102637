import { Controller } from 'stimulus';

export default class NotificationsController extends Controller {
  static targets = ['notificationForm', 'notificationToggle', 'configToggle']

  toggleNotifications(event) {
    if (this.notificationToggleTarget.dataset.id !== event.detail.id) return;

    const checkedConfigTargets = this.configToggleTargets.filter((target) => (
      target.checked && target.dataset.id === event.detail.id
    ));

    if (checkedConfigTargets.length > 0 && !this.notificationToggleTarget.checked) {
      this.notificationToggleTarget.checked = true;
    } else if (checkedConfigTargets.length === 0 && this.notificationToggleTarget.checked) {
      this.notificationToggleTarget.checked = false;
    }

    this.submitForm();
  }

  submitForm() {
    Rails.fire(this.notificationFormTarget, 'submit');
  }
}
