function barOptions(max = null, barPercentage = 1, stacked = false) {
  return {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        stacked,
        display: false,
        gridLines: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        scaleLabel: {
          display: false,
        },
        ticks: {
          display: false,
          max,
        },
      }],
      yAxes: [{
        stacked,
        display: false,
        barPercentage,
        categoryPercentage: 1,
        gridLines: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        scaleLabel: {
          display: false,
        },
        ticks: {
          display: false,
        },
      }],
    },
    tooltips: {
      enabled: false,
    },
    hover: { mode: null },
  };
}

export function stackedOptions(max = null, barPercentage = 1) {
  return barOptions(max, barPercentage, true);
}

export function groupedOptions(max = null, barPercentage = 0.85) {
  return barOptions(max, barPercentage, false);
}

export function singleStackedOptions(max = null) {
  return stackedOptions(max, 1);
}

export function multipleStackedOptions(max = null) {
  return stackedOptions(max, 0.85);
}
