import { Controller } from 'stimulus';

export default class SuggestedKeywordExclusionsController extends Controller {
  static targets = ['suggestedExclusionForm', 'suggestionsAccordian', 'suggestionsCount']

  submitForm() {
    Rails.fire(this.suggestedExclusionFormTarget, 'submit');
  }

  reject(event) {
    Rails.fire(event.currentTarget.parentNode, 'submit');
  }

  clearForm(event) {
    const { payload } = event.detail[0];

    if (payload.rejected === true) {
      const tableRow = event.target.offsetParent.parentNode;
      this.suggestionsCountTarget.innerText = payload.count_text;

      if (payload.total_entries === 0) {
        this.suggestionsAccordianTarget.remove();
      } else {
        tableRow.remove();
      }
    }
  }
}
