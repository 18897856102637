import { Controller } from 'stimulus';
import { FlashAlert } from '../../packs/flash_alerts';
import consumer from '../../channels/consumer';
import { replaceOrPrependHtmlFromString } from '../../utils';

export default class NotificationsController extends Controller {

  static targets = ['dropdownMenu', 'itemsContainer', 'notifications',
    'newReminderContainer', 'reminderDate', 'datetimePicker',
    'notificationCounter', 'alertCounts', 'emptyMessage', 'notificationsButton'];

  flatPicker = null;

  fromConcern = false;

  connect() {
    const notificationsController = this;
    const { userUuid } = this.element.dataset;

    consumer.subscriptions.create(
      { channel: 'NotificationsChannel', user_uuid: userUuid },
      {
        received(data) {
          notificationsController.received(data);
        },
      },
    );
  }

  remindersOpened(event) {
    const changeEvent = new CustomEvent('notificationReminders:opened');
    document.dispatchEvent(changeEvent);
  }

  keepOpen(event) {
    event.stopPropagation();
  }

  backToNotifications() {
    this.newReminderContainerTarget.classList.add('d-none');
    this.notificationsTarget.classList.remove('d-none');
  }

  newReminder(event) {
    this.fromConcern = event.detail.fromConcern;
    this.newReminderContainerTarget.classList.remove('d-none');
    this.notificationsTarget.classList.add('d-none');
    this.loadNewReminder(event);
  }

  loadNewReminder(event) {
    let url = null;
    if (this.fromConcern) {
      url = event.detail.notificationUrl;
    } else {
      url = event.target.parentElement.dataset.notificationUrl;
    }
    Rails.ajax(
      {
        url,
        type: 'get',
        dataType: 'json',
        success: (data) => {
          const errorMessage = data.error_message;
          if (errorMessage) {
            this.newReminderContainerTarget.classList.add('d-none');
            this.notificationsTarget.classList.remove('d-none');

            FlashAlert.error(errorMessage);
          } else {
            const outerScope = this;
            this.newReminderContainerTarget.innerHTML = data.payload.html;
            this.flatPicker = $(this.datetimePickerTarget).flatpickr({
              enableTime: true,
              dateFormat: 'D J M Y - H:i',
              time_24hr: true,
              enable: [
                function (date) {
                  const dateToday = new Date();
                  return (date.getDate() === dateToday.getDate() || date > dateToday);
                },
              ],
              locale: this.datetimePickerTarget.dataset.locale,
              onOpen: [
                function () {
                  $(outerScope.element).dropdown('toggle');
                  outerScope.dropdownMenuTarget.classList.add('show');
                  outerScope.highlightItem(null, outerScope.datetimePickerTarget.parentElement);
                },
              ],
              onClose: [
                function () {
                  outerScope.element.classList.add('closable');
                  outerScope.element.classList.add('show');
                },
              ],
            });
            if (this.fromConcern) {
              // if click from concern details page then
              // trigger the click event of notifications button.
              this.notificationsButtonTarget.click();
            }
          }
        },
      },
    );
  }

  createSuccess(data) {
    const errorMessage = data.detail[0].error_message;
    if (errorMessage) {
      FlashAlert.error(errorMessage);
    } else {
      $(`#${data.detail[0].notification_id}`).remove();
      this.newReminderContainerTarget.innerHTML = data.detail[0].payload.html;
      this.alertCountsTarget.innerText = data.detail[0].unread_entries;
      if (data.detail[0].total_entries === 0) {
        this.emptyMessageTarget.classList.add('show');
      }
    }
  }

  datetimePicker() {
    this.flatPicker.toggle();
  }

  setTime(event) {
    const d = new Date(event.currentTarget.dataset.setDatetime);
    const dayIndex = d.getDay();
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayName = days[dayIndex];
    const year = d.getFullYear();
    const date = this.dateWithOrdinalSuffix(d.getDate());
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthIndex = d.getMonth();
    const monthName = months[monthIndex];
    const hour = String(d.getHours()).padStart(2, '0');
    const min = String(d.getMinutes()).padStart(2, '0');

    const formatted = `${dayName} ${date} ${monthName} ${year} - ${hour}:${min}`;
    this.datetimePickerTarget.value = formatted;
  }

  dateWithOrdinalSuffix(date) {
    return date + (date % 10 == 1 && date != 11 ? 'st'
      : (date % 10 == 2 && date != 12 ? 'nd'
        : (date % 10 == 3 && date != 13 ? 'rd' : 'th')));
  }

  highlightItem(event, itemToHighlight) {
    if (!itemToHighlight) {
      itemToHighlight = event.currentTarget;
    }
    this.element.querySelectorAll('[data-set-datetime]').forEach((item) => {
      item.classList.remove('top-nav__new-reminder--select');
    });
    itemToHighlight.classList.add('top-nav__new-reminder--select');
  }

  goToNotification(event) {
    const isAddReminderClicked = event.target.parentElement.dataset.notificationUrl;
    const isIgnoreNotificationClicked = event.target.parentElement.dataset.ignoreUrl;

    if (!isAddReminderClicked && !isIgnoreNotificationClicked) {
      window.location.replace(event.currentTarget.dataset.url);
    }
  }

  async received(data) {
    const { action } = data;
    if (action === 'new_notification') {
      const { payload } = data;
      const partialUrl = payload.partial_url;
      const notificationCount = payload.notification_count;
      const totalEntriesCount = payload.total_entries;

      const response = await fetch(partialUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      });

      const json = await response.json();
      const { html } = json;

      replaceOrPrependHtmlFromString(html, this.itemsContainerTarget);
      this.notificationCounterTarget.innerHTML = notificationCount;

      if (totalEntriesCount > 0) {
        this.emptyMessageTarget.classList.remove('show');
      }
    }
  }
}