import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox'];

  toggle() {
    const checkbox = this.checkboxTarget;
    const { url } = checkbox.dataset;
    const { checked } = checkbox;

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
      },
      body: JSON.stringify({ enabled: checked }),
    })
      .catch((error) => {
        console.error(error);
      });
  }
}
