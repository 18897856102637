import { Controller } from 'stimulus';
import * as Awesomplete from 'awesomplete';
import * as moment from 'moment';
import flatpickr from 'flatpickr';
import flatpickrLanguage from '../../utils/flatpickrLanguage';

export default class SidebarController extends Controller {
  static targets = [
    'activatable',
    'keywordSearch',
    'keywords',
    'keywordLists',
    'childName',
    'selectedData',
    'startDate',
    'endDate',
    'dateDuration',
    'filterCollapse',
  ];

  async connect() {
    this.disableInput();
    this.hideInput();
    this.searchInput = this.initializeAwesomplete();
    if (!this.searchInput) {
      return;
    }
    // this.initializeFlatpickr();
    const data = await this.getListData();
    this.setSearchList(data);
  }

  toggleActive() {
    if (this.hasActivatableTarget) {
      this.activatableTargets.forEach((t) => t.classList.toggle('active'));
    }
  }

  open() {
    this.element.classList.add('search__wrapper--active');
  }

  close() {
    this.element.classList.remove('search__wrapper--active');
  }

  next(event) {
   alert('alert');
  }

  /* eslint class-methods-use-this: ['error',
    { 'exceptMethods': ['getLabel', 'getItemPrefix', 'getId', 'getListData',
    'cookieExpiryDate', 'getCookie'] }] */

  cookieExpiryDate() {
    const date = new Date();
    return date.setFullYear(date.getFullYear() + 1);
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return undefined;
  }

  setCookie(name, value) {
    document.cookie = `${name}=${value};expires=${this.cookieExpiryDate()};path=/`;
  }

  toggleCookie(event) {
    let filter;

    if (event.target.tagName !== 'H4') {
      filter = event.target.parentNode.dataset.value;
    } else {
      filter = event.target.dataset.value;
    }

    const cookie = this.getCookie(filter);

    if (cookie === undefined || cookie === '0') {
      this.setCookie(filter, 1);
    } else {
      this.setCookie(filter, 0);
    }
  }

  /* eslint no-param-reassign: 'error' */
  selected(event) {
    this.showSelectedKeywords(event.text);
    const dataType = this.getItemPrefix(event.text.value);
    const id = this.getId(event.text.value);

    const targetMap = {
      'k': 'keywordsTarget',
      'kl': 'keywordListsTarget',
      'child': 'childNameTarget'
    };

    const targetName = targetMap[dataType];
    if (targetName) {
      const options = Array.from(this[targetName].options);
      options.forEach((option) => {
        if (option.value === id) {
          option.selected = true;
        }
      });
    }
  }

  disableDate() {
    if (this.startDateTarget.value === '') {
      this.startDateTarget.disabled = true;
    }
    if (this.endDateTarget.value === '') {
      this.endDateTarget.disabled = true;
    }
  }

  showSelectedKeywords(keyword) {
    const span = document.createElement('span');
    span.innerHTML = `${this.getLabel(
      keyword.label,
    )} <i class='fa fa-times-circle ml-2'></i>`;
    span.className = 'btn btn-outline-info btn-sm mr-2 mt-2';
    span.dataset.action = 'click->captures--sidebar#removeSelected';
    span.dataset.dataType = this.getItemPrefix(keyword.value);
    span.dataset.id = this.getId(keyword.value);
    this.selectedDataTarget.appendChild(span);
  }

  getLabel(value) {
    return value.split('<sp')[0];
  }

  getItemPrefix(value) {
    return value.split('_')[0];
  }

  getId(value) {
    return value.split('_')[1];
  }

  removeSelected(event) {
    const target = event.currentTarget;
    let selectTarget;
    if (target.dataset.dataType === 'k') {
      selectTarget = this.keywordsTarget;
    } else if (target.dataset.dataType === 'kl') {
      selectTarget = this.keywordListsTarget;
    } else if (target.dataset.dataType === 'child') {
      selectTarget = this.childNameTarget;
    }
    const optionToRemove = Array.from(selectTarget.options).find(option => option.value === target.dataset.id);
    if (optionToRemove) {
      optionToRemove.selected = false;
    }
    target.remove();
  }

  hideInput() {
    this.keywordsTarget.hidden = true;
    this.keywordListsTarget.hidden = true;
    this.childNameTarget.hidden = true;
  }

  disableInput() {
    this.keywordSearchTarget.disabled = true;
    this.defaultPlaceholder = this.keywordSearchTarget.placeholder;
  }

  setSearchList(list = []) {
    const lists = [];
    list.keyword_lists.forEach((keyword) => {
      lists.push({
        id: keyword[0],
        plainText: keyword[1],
        dataType: 'keyword_list',
      });
    });
    list.keywords.forEach((keyword) => {
      lists.push({
        id: keyword[0],
        plainText: keyword[1],
        dataType: 'keyword',
      });
    });
    list.children.forEach((child) => {
      lists.push({
        id: child[0],
        plainText: `${child[1]} ${child[2]} (Year ${child[3]})`,
        dataType: 'child',
      });
    });
    this.searchInput.list = lists;
    this.keywordSearchTarget.placeholder = this.defaultPlaceholder;
    this.keywordSearchTarget.disabled = false;
  }

  async getListData() {
    const response = await fetch('/captures/keywords_and_keyword_lists');
    return response.json();
  }

  filterFromLast(amount, unitOfTime, startOfUnit = false) {
    let start;
    if (startOfUnit) {
      if (unitOfTime === 'weeks') {
        start = moment().startOf('isoWeek').toDate();
      } else {
        start = moment().startOf(unitOfTime).toDate();
      }
    } else {
      start = moment().subtract(amount, unitOfTime).startOf('day').toDate();
    }
    const end = moment().endOf('day').toDate();

    /* eslint no-underscore-dangle: ['error', { 'allow': ['_flatpickr'] }] */
    const startDatePicker = this.startDateTarget._flatpickr;
    const endDatePicker = this.endDateTarget._flatpickr;

    startDatePicker.setDate(start);
    endDatePicker.setDate(end);
  }
  
  filterByUnit(event) {
    const unitOfTime = event.target.dataset.unit;
    this.filterFromLast(0, unitOfTime, true);
  }

  initializeAwesomplete() {
    const label = 'plainText';
    let itemId;
    const options = {
      data(item) {
        if (item.dataType === 'keyword') {
          itemId = `k_${item.id}`;
        } else if (item.dataType === 'keyword_list') {
          itemId = `kl_${item.id}`;
        } else if (item.dataType === 'child') {
          itemId = `child_${item.id}`;
        }
        return {
          label: `${item[label]} <span class='text-grey'> - ${item.dataType} </span>`,
          value: itemId,
        };
      },
      replace(suggestion) {
        const [labelValue] = suggestion.label.split('<sp');
        this.input.value = labelValue;
      },
    };
    return new Awesomplete(this.keywordSearchTarget, options);
  }

  initializeFlatpickr() {
    const config = {
      altFormat: 'j M y - H:i',
      altInput: true,
      dateFormat: 'd-m-Y H:i',
      defaultHour: 0,
      disable: [
        (date) => {
          const notAfter = new Date();
          notAfter.setHours(23, 59, 59, 999);

          return date > notAfter;
        },
        (date) => {
          const notBefore = new Date(this.startDateTarget.dataset.unavailable);

          return date < notBefore;
        },
      ],
      enableTime: true,
      time_24hr: true,
    };

    const language = flatpickrLanguage();
    flatpickr.localize(language);

    flatpickr(this.startDateTarget, config);
    flatpickr(this.endDateTarget, config);
  }
}
