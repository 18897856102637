import { datadogLogs } from '@datadog/browser-logs';

const environment = document.querySelector('meta[name="environment"]').getAttribute('content');
const region = document.querySelector('meta[name="region"]').getAttribute('content');
const version = document.querySelector('meta[name="revision"]').getAttribute('content');

let clientToken = null;
let site = null;

if (region.startsWith('uk-')) {
  clientToken = 'pub5ba87d9af50fb0c145bb00b33d47ecba';
  site = 'datadoghq.eu';
} else if (region.startsWith('us-')) {
  clientToken = 'pub14ae36248f28975836f394b1669805df';
  site = 'datadoghq.com';
}

if (clientToken && site) {
  // FIXME: the demo and production clusters have environment set to "production"
  // so they will be grouped under the same env tag at the moment.
  const env = `${environment}-${region}`;

  datadogLogs.init({
    clientToken,
    env,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    site,
    version,
  });
} else if (environment === 'development') {
  // Don't send logs to Datadog. Instead, log to console in dev environment.
  datadogLogs.logger.setHandler('console');
} else {
  datadogLogs.logger.setHandler('silent');
}
