import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["section", "required"];

  expand(event) {
    const {target} = event;
    this.sectionTarget.classList.toggle('d-none', !target.checked);
    this.requiredTargets.forEach(input => input.toggleAttribute("required", target.checked))
  }
}
