import {Controller} from 'stimulus';
import * as moment from 'moment';

export default class PeriodController extends Controller {
  static targets = ['startTime', 'endTime'];

  /**
   * @type {moment.Moment}
   */
  startTime;

  /**
   * @type {moment.Moment}
   */
  endTime;

  connect() {
    /**
     * {HTMLTimeElement}
     */
    const startTimeTarget = this.startTimeTarget;
    const [startHours, startMinutes] = startTimeTarget.attributes.datetime.value.split(':');
    this.startTime = moment().hours(parseInt(startHours)).minutes(parseInt(startMinutes));

    const endTimeTarget = this.endTimeTarget;
    const [endHours, endMinutes] = endTimeTarget.attributes.datetime.value.split(':');
    this.endTime = moment().hours(parseInt(endHours)).minutes(parseInt(endMinutes));
  }

  extendClass() {
    /**
     * @type {moment.Moment}
     */
    this.endTime = this.endTime.add(5, 'minutes');
    this.updateDisplayedTimes();
  }

  updateDisplayedTimes() {
    this.startTimeTarget.attributes.datetime.value = this.startTime.format("HH:mm");
    this.startTimeTarget.innerText = this.startTime.format("hh:mma");

    this.endTimeTarget.attributes.datetime.value = this.endTime.format("HH:mm");
    this.endTimeTarget.innerText = this.endTime.format("hh:mma")
  }
}
