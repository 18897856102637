import { Controller } from 'stimulus';

export default class ItemController extends Controller {
  static targets = ['checkbox', 'checkboxContainer', 'deviceSession']

  /**
   * @private
   * @return {boolean}
   */
  get checked() {
    const stringValue = this.data.get('checked');
    return stringValue === 'true';
  }

  get selectable() {
    let stringValue;
    if (this.hasDeviceSessionTarget) {
      stringValue = this.deviceSessionTarget.dataset['thumbnail-ItemSelectable'];
    }

    return stringValue === undefined || stringValue === null || stringValue === 'true';
  }

  connect() {
    this.rehydrateSelectedDevicesCache();
    this.toggleCheckboxVisibility();
  }

  toggleCheckboxVisibility() {
    if (this.hasCheckboxContainerTarget) {
      if (!this.selectable) {
        this.checkboxContainerTarget.classList.add('d-none');
      } else {
        this.checkboxContainerTarget.classList.remove('d-none');
      }
    }
  }

  /**
   * @private
   * @param value {boolean}
   */
  set checked(value) {
    this.toggleCheckboxVisibility();

    if (this.hasDeviceSessionTarget) {
      if (!this.selectable) {
        return;
      }
    }

    this.data.set('checked', value);

    if (this.checkboxTarget.checked !== value) {
      this.checkboxTarget.checked = value;
      const changeEvent = new Event('change', { bubbles: true });
      this.checkboxTarget.dispatchEvent(changeEvent);
    }
  }

  /**
   * @protected
   * @param event
   */
  check(event) {
    if (event.type === 'change') {
      const { target: { checked } } = event;
      this.checked = checked;
    } else {
      const { detail: { select: checked } } = event;
      this.checked = checked;
    }

    this.cacheSelectedItems(event.target.value);
  }

  cacheSelectedItems(itemId) {
    window.selectedDevicesCache = window.selectedDevicesCache || [];

    if (itemId === undefined) {
      return;
    }

    // Always remove the cached id and re-add if checked,
    // avoids duplicates and handles remove in one go
    const location = window.selectedDevicesCache.indexOf(itemId);
    if (location >= 0) {
      window.selectedDevicesCache.splice(location, 1);
    }

    if (this.checked) {
      window.selectedDevicesCache.push(itemId);
    }
  }

  rehydrateSelectedDevicesCache() {
    if (window.selectedDevicesCache && window.selectedDevicesCache.length > 0) {
      if (window.selectedDevicesCache.indexOf(this.element.dataset.deviceId) >= 0) {
        this.check({ type: 'change', target: { checked: true } });

        // This triggers a gridchange which unlocks the sidebar if we have selected items
        // Needs to run after the GridController or else it will disable the sidebar
        // if it's executed before
        Promise.resolve().then(() => {
          const selectedCount = window.selectedDevicesCache.length;
          const selectEvent = new CustomEvent('grid:change', {
            bubbles: true,
            detail: {
              itemsSelected: selectedCount > 0,
              selectedCount,
            },
          });

          window.dispatchEvent(selectEvent);
        });
      }
    }
  }
}
