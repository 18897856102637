import { Controller } from 'stimulus';

export default class WebsiteListsModalController extends Controller {
  static targets = [
    'form',
    'colourOption',
    'kindOption',
    'addWebsite',
    'allowedIcon',
    'blockedIcon',
    'header',
    'list',
    'listCount',
    'listPlaceholder',
    'template',
    'websiteInput',
  ];

  connect() {
    this.updateForm();
    $(this.element).on('hide.bs.modal', () => {
      this.resetForm();
    });
  }

  updateForm() {
    const newCount = this.listTarget.childElementCount;
    this.updateListDisplay(newCount);
    this.updateWebsiteCount(newCount);
    this.colourChanged();
    this.kindChanged();
  }

  addNewWebsite(event) {
    const { type: eventType, key: eventKey } = event;
    if (eventType === 'keydown' && eventKey !== 'Enter') {
      return;
    }
    event.preventDefault(); // Prevent form from submitting

    if (this.addWebsiteTarget.value === '') {
      return;
    }

    const newWebsite = this.templateTarget.content.cloneNode(true);
    const newUrl = this.addWebsiteTarget.value;

    newWebsite.querySelector('input').value = newUrl;
    newWebsite.querySelector('input').title = newUrl;

    this.listTarget.appendChild(newWebsite);
    this.addWebsiteTarget.value = '';

    this.listTarget.lastElementChild.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });

    this.updateForm();
  }

  removeWebsite(event) {
    event.preventDefault();
    const button = event.target;
    this.removeWebsiteFromForm(button);
  }

  /**
   * @private
   */
  colourChanged() {
    // eslint-disable-next-line max-len
    const selectedColourElement = this.colourOptionTargets.find((colorOption) => colorOption.checked);
    this.headerTarget.style.backgroundColor = selectedColourElement?.value;
  }

  /**
   * @private
   */
  // eslint-disable-next-line consistent-return
  kindChanged() {
    const selectedKindElement = this.kindOptionTargets.find((kindOption) => kindOption.checked);
    switch (selectedKindElement.value) {
      case ('allowed'):
        this.allowedIconTarget.classList.remove('d-none');
        this.blockedIconTarget.classList.add('d-none');
        break;
      case ('blocked'):
        this.allowedIconTarget.classList.add('d-none');
        this.blockedIconTarget.classList.remove('d-none');
        return null;
      default:
        return null;
    }
  }

  /**
   * @private
   * @param newCount
   */
  updateListDisplay(newCount) {
    if (newCount === 0) {
      this.listPlaceholderTarget.classList.remove('d-none');
      this.listTarget.classList.add('d-none');
    } else {
      this.listPlaceholderTarget.classList.add('d-none');
      this.listTarget.classList.remove('d-none');
    }
  }

  /**
   * @private
   * @param newCount
   */
  // eslint-disable-next-line consistent-return
  updateWebsiteCount(newCount) {
    const count = this.listCountTarget.querySelector('[data-count]');
    if (!count) {
      return null;
    }
    count.innerText = newCount;
  }

  /**
   * Private
   * @param element
   */
  removeWebsiteFromForm(element) {
    const site = element.closest('.website-list__website');
    site.remove();
    this.updateForm();
  }

  /**
   * private
   */
  resetForm() {
    const removableWebsites = this.websiteInputTargets.filter((websiteInputTarget) => websiteInputTarget.dataset.persisted === 'false');
    // eslint-disable-next-line max-len
    removableWebsites.forEach((websiteInputTarget) => this.removeWebsiteFromForm(websiteInputTarget));
    this.formTarget.reset();
    this.updateForm();
  }
}
