import { Controller } from 'stimulus';

export default class SiteNavController extends Controller {
  togglePageScroll() {
    if (this.element.classList.contains('collapsed')) {
      this.disablePageScroll();
    } else {
      this.enablePageScroll();
    }
  }

  enablePageScroll() {
    document.querySelector('body').classList.remove('overflow-hidden-mobile');
  }

  disablePageScroll() {
    document.querySelector('body').classList.add('overflow-hidden-mobile');
  }
}
