import * as Awesomplete from "awesomplete";
import {Controller} from "stimulus";
import {createElementFromString} from "../utils";

const PersonType = {
  CHILD: "child",
  USER: "user",
};

/**
 * @typedef {Object} IAttachedPersonOptions
 * @property {HTMLElement} [target]
 * @property {string} url
 */

/**
 * @typedef {Object} IPersonInfoResult
 * @property {IPersonInfo} html
 */

/**
 * @typedef {Object} IPersonInfo
 * @property {string} cardHtml
 * @property {string} voiceHtml
 */

/**
 * @param type {"child" | "user"}
 * @param id {string}
 * @returns {string}
 */
const generatePersonUrl = (type, id) => {
  switch (type) {
    case (PersonType.CHILD):
      return `/students/${id}/concerns/child_info.json`;
    case (PersonType.USER):
      return `/users/${id}/concerns/user_info.json`;
  }
};

export default class ConcernsFormController extends Controller {
  static targets = [
    "search",
    "concernedPeople",
    "voicesMarker",
  ];

  /**
   * @private
   * @static
   * @param target {HTMLElement|EventTarget}
   * @returns {HTMLElement|Element}
   */
  static getPersonElement(target) {
    return target.closest("[data-target='concernedPerson']");
  }

  /**
   * @public
   */
  connect() {
    this.initializeAwesomplete();
  }

  /**
   * @public
   * @param event {Event | CustomEvent}
   * @returns {Promise<void>}
   */
  async personSelected(event) {
    if (event instanceof CustomEvent) {
      // We already have the partial to render out
      const {detail: {concernHtml: {card_html: cardHtml, voice_html: voiceHtml}}} = event;
      /**  @type {IPersonInfoResult} */
      const result = {html: {cardHtml, voiceHtml}};
      this.attachConcernedPerson(result)
      return
    }

    const {target, text} = event;
    const {dataset} = target;
    const {personType} = dataset;
    const {value: personId} = text;
    target.value = null;
    /**  @type {IAttachedPersonOptions} */
    const options = {url: generatePersonUrl(personType, personId)};
    await this.findConcernedPersonInfo(options);
  }

  /**
   * @public
   * @param event {UIEvent}
   */
  removePerson(event) {
    const {target} = event;
    const parentElement = ConcernsFormController.getPersonElement(target);
    const {person: formPersonId} = this.element.dataset;
    const {person: removePersonId} = parentElement.dataset;
    if (formPersonId === removePersonId) {
      return;
    }
    parentElement.remove();
    const spokenChildren = this.voicesMarkerTarget.children;
    Array.from(spokenChildren)
      .filter((fieldset) => fieldset.dataset.person === removePersonId)
      .forEach((fieldset) => fieldset.remove());
  }

  /**
   * @public
   * @param event {UIEvent}
   * @returns {Promise<void>}
   */
  async addSibling(event) {
    const {target} = event;
    const {siblingId} = target.dataset;
    const siblingIds = [siblingId];
    const parentElement = ConcernsFormController.getPersonElement(target);
    await this.addSiblings(siblingIds, parentElement);
  }

  /**
   * @public
   * @param event {UIEvent}
   * @returns {Promise<void>}
   */
  async addAllSiblings(event) {
    const {target} = event;
    const {siblingIds} = target.dataset;
    const parentElement = ConcernsFormController.getPersonElement(target);
    await this.addSiblings(JSON.parse(siblingIds), parentElement);
  }

  /**
   * @private
   */
  initializeAwesomplete() {
    const options = {
      replace(suggestion) {
        this.input.value = suggestion.label;
      },
    };
    this.searchTargets.forEach((target) => new Awesomplete(target, options));
  }

  /**
   * @private
   * @param personOptions {IAttachedPersonOptions}
   * @returns {Promise<void>}
   */
  async findConcernedPersonInfo(personOptions) {
    const options = {target: this.concernedPeopleTarget, ...personOptions};
    const {target, url} = options;
    const response = await fetch(url);
    const result = await response.json();
    this.attachConcernedPerson(result, target);
  }

  /**
   * @private
   * @param result {IPersonInfoResult}
   * @param target {HTMLElement}
   */
  attachConcernedPerson(result, target = this.concernedPeopleTarget) {
    const {cardHtml, voiceHtml} = result.html;
    this.attachConcernedPersonCard(cardHtml, target);
    this.attachConcernedPersonVoice(voiceHtml);
  }

  /**
   * @private
   * @param html {string}
   */
  attachConcernedPersonVoice(html) {
    const newElement = createElementFromString(html);
    const {id: newElementId} = newElement;
    /** @type {string[]} */
    const currentIds = Array.from(this.voicesMarkerTarget.children).map((element) => element.id);

    if (currentIds.includes(newElementId)) {
      return;
    }

    this.voicesMarkerTarget.appendChild(newElement);
    this.initiateSelect2(this.voicesMarkerTarget);
    const newElementEvent = new CustomEvent("concern_form:element_added", {bubbles: true});
    this.voicesMarkerTarget.dispatchEvent(newElementEvent);
  }

  /**
   * @private
   * @param html {string}
   * @param target {HTMLElement}
   */
  attachConcernedPersonCard(html, target) {
    const newElement = createElementFromString(html);
    const {id: newElementId} = newElement;
    /** @type {string[]} */
    const currentIds = Array.from(this.concernedPeopleTarget.children).map((element) => element.id);

    if (currentIds.includes(newElementId)) {
      return;
    }

    if (target === this.concernedPeopleTarget) {
      target.appendChild(newElement);
    } else {
      target.parentNode.insertBefore(newElement, target.nextSibling);
    }
    this.initiateSelect2();
  }

  /**
   * @private
   * @param [siblingIds=[]] {string[]}
   * @param parentElement {HTMLElement}
   * @returns {Promise<void>}
   */
  async addSiblings(siblingIds = [], parentElement) {
    const promises = siblingIds.map((id) => {
      /** @type {IAttachedPersonOptions} */
      const personOptions = {
        target: parentElement,
        url: generatePersonUrl(PersonType.CHILD, id),
      };
      return this.findConcernedPersonInfo(personOptions);
    });
    await Promise.all(promises);
  }

  /**
   * @private
   * @param [target=this.concernedPeopleTarget] {HTMLElement}
   */
  initiateSelect2(target = this.concernedPeopleTarget) {
    $(target).find(".select2").select2({
      theme: "bootstrap",
      width: "100%",
    });
  }
}
