import { Controller } from 'stimulus';

/* global I18n */

export default class KeywordExclusionsController extends Controller {
  static targets = ['errorBox', 'group', 'reason']

  validate(event) {
    const errors = [];

    if (!this.reasonTarget.value) {
      errors.push(I18n.t('assets.javascripts.components.keyword_exclusions.reason_missing'));
    }

    const groupSelected = this.groupTargets.some((ele) => ele.checked);

    if (!groupSelected) {
      errors.push(I18n.t('assets.javascripts.components.keyword_exclusions.groups_missing'));
    }

    if (errors.length) {
      event.preventDefault();

      const oldlist = this.errorBoxTarget.firstChild;

      const newList = document.createElement('ul');

      errors.forEach((error) => {
        const item = document.createElement('li');
        item.innerText = error;

        newList.appendChild(item);
      });

      oldlist.replaceWith(newList);

      this.errorBoxTarget.classList.remove('d-none');
      this.errorBoxTarget.scrollIntoView();
    }
  }
}
