import {Controller} from 'stimulus';

export default class ModalController extends Controller {
  static targets = ['participants', 'selectable', 'submit', 'broadcast', 'form'];

  static outlets = ['devices--broadcast'];

  modalShow() {
    Rails.ajax({
      url: this.element.dataset.url,
      type: "get",
      dataType: "json",
      success: (data) => {
        this.participantsTarget.innerHTML = data.payload.html;
        $('#broadcastScreenModal').modal('show');
      }
    })
  }

  participantSelected(event) {
    const participant = event.currentTarget;
    const { teacher, deviceId } = participant.dataset;
    const unselect = teacher === this.teacher && deviceId === this.deviceId;
    this.blankBroadcastForm();
    if (unselect) {
      this.resetBroadcastTarget();
    } else {
      participant.style = 'background-color: #f2f2f2';
      this.teacher = teacher;
      this.deviceId = deviceId;
      this.broadcastTarget.innerHTML = ModalController.studentHTML(deviceId);
    }
    this.submitTarget.disabled = unselect;
  }

  startScreenShare(event) {
    if (this.teacher) {
      event.preventDefault();
      this.devicesBroadcastOutlet.getScreenTrack();
      this.resetBroadcastTarget();
      this.blankBroadcastForm();
      $('#broadcastScreenModal').modal('hide');
    }
  }

  /**
   * @private
   */
  resetBroadcastTarget() {
    this.teacher = null;
    this.deviceId = null;
    this.broadcastTarget.innerHTML = '';
  }

  /**
   * @private
   */
  blankBroadcastForm() {
    this.selectableTargets.forEach((element) => (element.style = null));
  }

  /**
   * @private
   * @param {string} studentId
   */
  static studentHTML(studentId) {
    return `<input type="hidden" name="producer_device_id" value="${studentId}">`;
  }
}
