import { Controller } from 'stimulus';

export default class UserGroupTemplatesController extends Controller {
  static targets = [
    'userGroupSetting',
    'selectAllSchools',
    'selectSchool',
    'schoolRow',
    'selectedSchoolCount',
    'schoolUserGroupSetting',
    'loadingSidebarData',
    'sidebarData',
    'groupSidebarOptions',
    'sidebarUserGroupSetting',
    'sidebarUserGroupSetting',
    'sidebarUserGroupSettingParent',
    'sidebarFeedback',
    'sidebarProcessingIcon',
    'selectSchoolHighlight',
    'sidebar',
    'deleteTemplateModalBody',
    'schoolDefaultUserGroupName',
    'userGroupTemplateSelect',
    'defaultUserGroupActionSelect',
  ];

  connect() {
    $(document).ready(function() {
      $(".switch_school_select").select2({
        theme: 'bootstrap'
      });
    });
  }

  toggleSidebar(enabled) {
    const target = this.sidebarTarget;
    if (enabled === true) {
      if (target.classList.contains('d-none')) {
        target.classList.remove('d-none');
      }
    } else if (enabled === false) {
      if (!target.classList.contains('d-none')) {
        target.classList.add('d-none');
      }
    }
  }

  preloadSidebarToggleStates() {
    this.sidebarUserGroupSettingParentTargets.forEach((groupTarget) => {
      const groupToggle = groupTarget;
      const groupBodySettingToggles = this.sidebarUserGroupBodyToggles(groupTarget.dataset.key);
      const groupBodyToggleStates = [];

      groupBodySettingToggles.forEach((toggleTarget) => {
        const toggle = toggleTarget;
        const schoolSettingStates = [];
        const matchingSchoolsSettings = this.findMatchingSchoolSetting(toggle);

        if (matchingSchoolsSettings.length > 0) {
          matchingSchoolsSettings.forEach((setting) => {
            schoolSettingStates.push(setting.dataset.enabled);
          });
        } else {
          schoolSettingStates.push('false');
        }

        if (schoolSettingStates.includes('false')) {
          toggle.checked = false;
          groupBodyToggleStates.push(toggle.checked.toString());
        } else if (schoolSettingStates.includes('true')){
          toggle.checked = true;
          groupBodyToggleStates.push('true');
        }
      });

      if (groupBodyToggleStates.includes('false')) {
        groupToggle.checked = false;
      } else {
        groupToggle.checked = true;
      }
    });
  }

  launchSidebar() {
    const selectedSchools = this.selectedSchools();
    this.selectedSchoolCountTarget.innerHTML = I18n.t('assets.javascripts.controllers.multi_academy_trusts.user_group_templates_controller.manage_user_group_templates.sidebar.selected_schools', { count: selectedSchools.length }).toString();

    if (selectedSchools.length === 0) {
      this.loadingSidebarDataTarget.classList.add('d-none');
      this.toggleSidebar(false);
    } else if (selectedSchools.length > 0) {
      this.preloadSidebarToggleStates();
      this.preloadDefaultUserGroupOptions();
      this.toggleSidebar(true);
      this.resetSidebarFeedback();
      this.sidebarDataTarget.classList.remove('d-none');
      this.loadingSidebarDataTarget.classList.add('d-none');
    }
  }

  selectedSchools() {
    const selectedSchools = this.selectedSchoolRows();
    const schoolRows = [];

    selectedSchools.forEach((row) => {
      if (row.childNodes[0].checked) {
        schoolRows.push(row);
      }
    });
    return schoolRows;
  }

  selectSchool() {
    this.launchSidebar();
  }

  toggleAllSettingsForGroup(event) {
    this.sidebarProcessingIconTarget.classList.toggle('d-none');
    const groupTarget = event.target;
    const settingToggles = this.sidebarUserGroupBodyToggles(groupTarget.dataset.key);
    const changedElements = [];
    const matchingSchoolSettingElements = [];
    const toChange = {};

    settingToggles.forEach((settingToggle) => {
      const toggle = settingToggle;
      if (groupTarget.checked === true && toggle.checked !== true) {
        toggle.checked = true;
        changedElements.push(toggle);
        const matchingSetting = this.findMatchingSchoolSetting(toggle);
        matchingSchoolSettingElements.push(matchingSetting);
      } else if (groupTarget.checked === false && toggle.checked !== false) {
        toggle.checked = false;
        changedElements.push(toggle);
        const matchingSetting = this.findMatchingSchoolSetting(toggle);
        matchingSchoolSettingElements.push(matchingSetting);
      }
    });
    const json = this.buildJson(matchingSchoolSettingElements.flat(), toChange, groupTarget.checked);
    if (Object.keys(json).length === 0) {
      this.sidebarProcessingIconTarget.classList.toggle('d-none');
      return;
    }
    this.postResults(json, matchingSchoolSettingElements, groupTarget.checked);
  }

  toggleUserGroupSettingForSchools(event) {
    this.sidebarProcessingIconTarget.classList.toggle('d-none');
    const settingToggle = event.target;
    const matchingSchoolSettings = this.findMatchingSchoolSetting(settingToggle);
    const toChange = {};

    const json = this.buildJson(matchingSchoolSettings, toChange, settingToggle.checked);
    if (Object.keys(json).length === 0) {
      this.sidebarProcessingIconTarget.classList.toggle('d-none');
      return;
    }

    this.postResults(json, matchingSchoolSettings, settingToggle.checked);
  }

  toggleSidebarGroupOptions(event) {
    const chevronIcon = event.target;
    const selectedGroupOptions = this.sidebarUserGroupBody(chevronIcon.dataset.key);

    chevronIcon.classList.toggle('fa-chevron-up');
    chevronIcon.classList.toggle('fa-chevron-down');
    selectedGroupOptions[0].classList.toggle('d-none');
  }

  preloadModalToggleStates() {
    this.modalUserGroupSettingParentTargets.forEach((groupTarget) => {
      const groupToggle = groupTarget;
      const groupBodySettingToggles = this.sidebarUserGroupBodyToggles(groupTarget.dataset.key);
      const groupBodyToggleStates = [];

      groupBodySettingToggles.forEach((toggleTarget) => {
        const toggle = toggleTarget;
        const schoolSettingStates = [];
        const matchingSchoolsSettings = this.findMatchingSchoolSetting(toggle);

        matchingSchoolsSettings.forEach((setting) => {
          schoolSettingStates.push(setting.dataset.enabled);
        });

        if (schoolSettingStates.includes('false')) {
          toggle.checked = false;
        } else {
          toggle.checked = true;
        }
        groupBodyToggleStates.push(toggle.checked);
      });

      if (groupBodyToggleStates.includes(false)) {
        groupToggle.checked = false;
      } else {
        groupToggle.checked = true;
      }
    });
  }

  launchModal() {
    // Show loading spinner whilst updating modal
    this.resetModalFeedback();
    this.modalDataTarget.classList.add('d-none');
    this.loadingModalDataTarget.classList.remove('d-none');

    $('#manageTemplatesModal').modal().show;
    this.preloadModalToggleStates();
    this.resetModalFeedback();

    const selectedSchoolCountText = this.selectedSchoolCountTarget;
    const selectedSchools = this.selectedSchools();
    selectedSchoolCountText.innerHTML = I18n.t('assets.javascripts.controllers.multi_academy_trusts.user_group_templates_controller.manage_user_group_templates.sidebar.selected_schools', { count: selectedSchools.length });

    if (selectedSchools.length <= 0) {
      this.modalSubmitFeedback(I18n.t('assets.javascripts.controllers.multi_academy_trusts.user_group_templates_controller.manage_user_group_templates.sidebar.select_a_school'), false, true);
    } else {
      this.modalDataTarget.classList.remove('d-none');
      this.loadingModalDataTarget.classList.add('d-none');
    }
  }

  selectedSchools() {
    const selectedSchools = this.selectSchoolTargets;
    const schoolRows = [];

    selectedSchools.forEach((row) => {
      if (row.checked) {
        schoolRows.push(row);
      }
    });
    return schoolRows;
  }

  selectedSchoolRows() {
    const schoolRows = this.schoolRowTargets;
    const selectedRows = [];

    schoolRows.forEach((obj) => {
      const rowSelected = obj.querySelector('#school_checkbox').childNodes[0].checked;
      if (rowSelected) {
        selectedRows.push(obj);
      }
    });
    return selectedRows;
  }

  toggleAllSettingsForGroup(event) {
    this.sidebarProcessingIconTarget.classList.toggle('d-none');
    const groupTarget = event.target;
    const settingToggles = this.sidebarUserGroupBodyToggles(groupTarget.dataset.key);
    const changedElements = [];
    const matchingSchoolSettingElements = [];
    const toChange = {};

    settingToggles.forEach((settingToggle) => {
      const toggle = settingToggle;
      if (groupTarget.checked === true && toggle.checked !== true) {
        toggle.checked = true;
        changedElements.push(toggle);
        const matchingSetting = this.findMatchingSchoolSetting(toggle);
        matchingSchoolSettingElements.push(matchingSetting);
      } else if (groupTarget.checked === false && toggle.checked !== false) {
        toggle.checked = false;
        changedElements.push(toggle);
        const matchingSetting = this.findMatchingSchoolSetting(toggle);
        matchingSchoolSettingElements.push(matchingSetting);
      }
    });
    const json = this.buildJson(matchingSchoolSettingElements.flat(), toChange, groupTarget.checked);
    if (Object.keys(json).length === 0) {
      this.sidebarProcessingIconTarget.classList.toggle('d-none');
      return;
    }
    this.postResults(json, matchingSchoolSettingElements, groupTarget.checked);
  }

  toggleUserGroupSettingForSchools(event) {
    this.sidebarProcessingIconTarget.classList.toggle('d-none');
    const settingToggle = event.target;
    const matchingSchoolSettings = this.findMatchingSchoolSetting(settingToggle);
    const toChange = {};

    const json = this.buildJson(matchingSchoolSettings, toChange, settingToggle.checked);
    if (Object.keys(json).length === 0) {
      this.sidebarProcessingIconTarget.classList.toggle('d-none');
      return;
    }

    this.postResults(json, matchingSchoolSettings, settingToggle.checked);
  }

  toggleModalGroupOptions(event) {
    const chevronIcon = event.target;
    const selectedGroupOptions = this.modalUserGroupBody(chevronIcon.dataset.key);

    chevronIcon.classList.toggle('fa-chevron-up');
    chevronIcon.classList.toggle('fa-chevron-down');
    selectedGroupOptions[0].classList.toggle('d-none');
  }

  selectAllSchools(event) {
    const selectAllCheckbox = event.target;
    const schoolSelectRows = this.selectSchoolTargets;

    schoolSelectRows.forEach((row) => {
      const schoolToggle = row;
      if (selectAllCheckbox.checked === true) {
        schoolToggle.checked = true;
      } else {
        schoolToggle.checked = false;
      }
    });

    this.launchSidebar();
  }

  filterMatchingState(target, enabled) {
    if (target.checked === enabled) {
      return null;
    } else if (target.checked === enabled.toString()) {
      return null;
    } else {
      return target;
    }
  }

  toggleIconsState(targets, enabled = false) {
    const iconTargets = targets;
    iconTargets.forEach((target) => {
      const icon = target;
      if (enabled === true && icon.dataset.enabled === 'false') {
        icon.classList.remove('fa-light');
        icon.classList.add('fa-solid');
        icon.dataset.enabled = enabled;
      } else if (enabled === false && icon.dataset.enabled === 'true') {
        icon.classList.add('fa-light');
        icon.classList.remove('fa-solid');
        icon.dataset.enabled = enabled;
      }
    });
  }

  sidebarUserGroupBody(key) {
    const targetedTargets = [];
    const bodyTargets = this.groupSidebarOptionsTargets;
    bodyTargets.forEach((target) => {
      const bodyTarget = target;
      if (bodyTarget.id === `ug-${key}-body`) {
        targetedTargets.push(bodyTarget);
      }
    });
    return targetedTargets;
  }

  sidebarUserGroupBodyToggles(key) {
    const targetedTargets = [];
    const bodyToggleTargets = this.sidebarUserGroupSettingTargets;
    const ugKey = key;

    bodyToggleTargets.forEach((target) => {
      const bodyToggle = target;
      if (bodyToggle.dataset.key === ugKey) {
        targetedTargets.push(bodyToggle);
      }
    });
    return targetedTargets;
  }

  findElementsByUserGroupKey(key, elements) {
    const targetedTargets = [];
    const targetsToCompare = elements;
    const ugKey = key;

    targetsToCompare.forEach((target) => {
      const elementTarget = target;
      if (elementTarget.dataset.key === ugKey) {
        targetedTargets.push(elementTarget);
      }
    });

    return targetedTargets;
  }

  findMatchingSchoolSetting(target) {
    const settingTarget = target;
    const selectedSchools = this.selectedSchools();
    const schoolSettingTargets = this.schoolUserGroupSettingTargets;
    const ugSUuids = [];
    const schoolUserGroupSettings = [];
    const ugSetting = settingTarget.dataset.setting;
    const ugKey = settingTarget.dataset.key;

    selectedSchools.forEach((schoolRow) => {
      ugSUuids.push(schoolRow.dataset.s_uuid);
    });

    schoolSettingTargets.forEach((setting) => {
      ugSUuids.forEach((uuid) => {
        if (setting.dataset.s_uuid === uuid && setting.dataset.setting === ugSetting && setting.dataset.ug_key === ugKey) {
          schoolUserGroupSettings.push(setting);
        } else {
          return null;
        }
      });
    });
    return schoolUserGroupSettings;
  }

  buildJson(elements, hash, enableSetting = false) {
    const toChange = hash;
    let id = 0;

    elements.forEach((element) => {
      if (element.dataset.enabled !== enableSetting.toString()) {
        toChange[`${id}`] = {};
        toChange[`${id}`].s_uuid = element.dataset.s_uuid;
        toChange[`${id}`].ug_id = element.dataset.ug_id;
        toChange[`${id}`].ug_setting = element.dataset.setting;
        toChange[`${id}`].enabled = enableSetting;
        id++;
      }
    });
    return toChange;
  }

  postResults(json, iconsToChange = [], enabled = false) {
    const postUrl = window.location.origin + '/manage_user_group_templates/apply';
    const resultsCount = Object.keys(json).length;

    if (resultsCount > 1) {
      const userGroups = [];
      Object.values(json).forEach((v) => { userGroups.push(v['ug_id']); });
      const prompt = window.confirm(I18n.t('assets.javascripts.controllers.multi_academy_trusts.user_group_templates_controller.manage_user_group_templates.sidebar.update_multiple_prompt', { count: this.selectedSchools().length }));

      if (prompt === true) {
        this.makeRequest(postUrl, json, iconsToChange, enabled);
      } else {
        this.sidebarProcessingIconTarget.classList.add('d-none');
        this.preloadSidebarToggleStates();
      }
    } else if (resultsCount === 1) {
      this.makeRequest(postUrl, json, iconsToChange, enabled);
    }
    this.resetSidebarFeedback();
  }

  makeRequest(url, json, iconsToChange, enabled = false) {
    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': document.head.querySelector(`meta[name="csrf-token"]`).getAttribute('content'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    }).then((response) => {
      if (response.ok === true && response.status.toString().includes(2)) {
        this.sidebarSubmitFeedback(I18n.t('assets.javascripts.controllers.multi_academy_trusts.user_group_templates_controller.manage_user_group_templates.sidebar.post_success'), response.ok);
        this.toggleIconsState(iconsToChange.flat(), enabled);
        this.preloadSidebarToggleStates();
      } else {
        this.sidebarSubmitFeedback(I18n.t('assets.javascripts.controllers.multi_academy_trusts.user_group_templates_controller.manage_user_group_templates.sidebar.post_fail', { status: response.status.toString() }), response.ok);
        console.log(`Error Submitting data: ${response.status}`);
        location.reload();
      }
    });
    this.preloadSidebarToggleStates();
  }

  sidebarSubmitFeedback(text, responseOk = false) {
    const processingSpinner = this.sidebarProcessingIconTarget;
    const feedbackTarget = this.sidebarFeedbackTarget;

    if (!processingSpinner.classList.contains('d-none')) {
      processingSpinner.classList.add('d-none');
    }

    const feedbackStyle = responseOk === true ? 'alert-success' : 'alert-danger';
    feedbackTarget.classList.add(feedbackStyle);
    feedbackTarget.innerHTML = text;
    feedbackTarget.classList.remove('d-none');
  }

  getTableData() {
    // Search box target
    // const searchText = this.searchSchoolsTarget;
    const results = [];
    const counter = 1;

    fetch(window.location.origin + `/manage_user_group_templates/table_data.json?page=${counter}`)
      .then((response) => response.json())
      .then((data) => console.log(data));
  }

  resetSidebarFeedback() {
    const feedbackTarget = this.sidebarFeedbackTarget;
    if (!feedbackTarget.classList.contains('d-none')) {
      feedbackTarget.classList.add('d-none');
    }

    if (feedbackTarget.classList.contains('alert-danger')) {
      feedbackTarget.classList.remove('alert-danger');
    } else if (feedbackTarget.classList.contains('alert-warning')) {
      feedbackTarget.classList.remove('alert-warning');
    } else if (feedbackTarget.classList.contains('alert-success')) {
      feedbackTarget.classList.remove('alert-success');
    }
  }

  loadDeleteTemplateModal(event) {
    const templateId = event.target.dataset.template;
    const modalBody = this.deleteTemplateModalBodyTarget;
    const url = `${window.location.origin}/user_group_templates/${templateId}/delete`;
    fetch(url, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': document.head.querySelector(`meta[name="csrf-token"]`).getAttribute('content'),
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      return response.text();
    }).then((html) => {
      modalBody.innerHTML = html;
      $('.school-select').select2({
        theme: 'bootstrap',
        minimumResultsForSearch: Infinity
      });
    });
  }

  preloadDefaultUserGroupOptions() {
    const schoolDefaultUserGroups = this.getDefaultUserGroups();
    if (schoolDefaultUserGroups[0] == null) { return; }

    const currentUserGroups = [];
    const currentUserGroupActions = [];

    schoolDefaultUserGroups.forEach((userGroup) => {
      currentUserGroups.push(userGroup.dataset.key);
      currentUserGroupActions.push(userGroup.dataset.setting);
    });
    const uniqDefaultUserGroups = ([...new Set(currentUserGroups)]);
    const uniqDefaultUserGroupActions = ([...new Set(currentUserGroupActions)]);
    this.setDefaultUserGroupSelections(uniqDefaultUserGroups, uniqDefaultUserGroupActions);

    if (this.userGroupTemplateSelectTarget.value === 'none') {
      this.defaultUserGroupActionSelectTarget.disabled = true;
    }
  }

  updateDefaultStaffGroup(event) {
    const targetSchoolIds = this.getSchoolIds();
    const selectedStaffGroup = event.target.value;
    const url = `${window.location.origin}/manage_user_group_templates/update_default_user_group`;

    if (selectedStaffGroup === 'none') {
      this.defaultUserGroupActionSelectTarget.disabled = true;
    } else {
      this.defaultUserGroupActionSelectTarget.disabled = false;
    }

    const json = {
      user_group_template: '',
      sids: targetSchoolIds,
      ug_key: selectedStaffGroup,
    };

    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': document.head.querySelector(`meta[name="csrf-token"]`).getAttribute('content'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          this.updateSchoolDefaultUserGroupColumns();
        } else {
          event.preventDefault();
          this.preloadDefaultUserGroupOptions();
        }
      });
  }

  updateDefaultUserGroupAction(event) {
    const targetSchoolIds = this.getSchoolIds();
    const selectedAction = event.target.value;
    const url = `${window.location.origin}/manage_user_group_templates/update_default_user_group_action`;

    // send request to controller to change the default user_group action setting for the school

    const json = {
      user_group_template: '',
      sids: targetSchoolIds,
      default_user_group_action: selectedAction,
    };

    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': document.head.querySelector(`meta[name="csrf-token"]`).getAttribute('content'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(json),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          this.updateSchoolDefaultOptionKeys();
        } else {
          event.preventDefault();
          this.preloadDefaultUserGroupOptions();
        }
      });
  }

  getSchoolIds() {
    const selectedSchools = this.selectedSchoolRows();
    const schoolIds = [];

    selectedSchools.forEach((obj) => {
      const row = obj.querySelector('#school_checkbox').childNodes[0];
      schoolIds.push(row.dataset.s_uuid);
    });
    return schoolIds;
  }

  getDefaultUserGroups() {
    const selectedSchools = this.selectedSchoolRows();
    const defaultUserGroups = [];

    selectedSchools.forEach((row) => {
      defaultUserGroups.push(row.querySelector('#default_user_group'));
    });
    return defaultUserGroups;
  }

  updateSchoolDefaultUserGroupColumns() {
    const currentSchoolDefaultUserGroups = this.getDefaultUserGroups();
    const selectedUserGroupTemplate = this.userGroupTemplateSelectTarget;

    currentSchoolDefaultUserGroups.forEach((obj) => {
      const key = selectedUserGroupTemplate.selectedOptions[0].value;
      const name = selectedUserGroupTemplate.selectedOptions[0].text;
      const schoolUserGroup = obj;

      schoolUserGroup.dataset.key = key;
      if (key === 'none') {
        schoolUserGroup.innerHTML = I18n.t('assets.javascripts.controllers.multi_academy_trusts.user_group_templates_controller.manage_user_group_templates.default_user_group.no_default_staff_group_selected');
      } else {
        schoolUserGroup.innerText = name;
      }
    });
  }

  updateSchoolDefaultOptionKeys() {
    const currentSchoolDefaultUserGroups = this.getDefaultUserGroups();
    const selectedUserGroupAction = this.defaultUserGroupActionSelectTarget;

    currentSchoolDefaultUserGroups.forEach((obj) => {
      const option = obj;
      option.dataset.setting = selectedUserGroupAction.selectedOptions[0].value;
    });
  }

  setDefaultUserGroupSelections(uniqSchoolUserGroups, uniqDefaultUserGroupActions) {
    const userGroupTemplateOptions = Array.from(this.userGroupTemplateSelectTarget.options);
    const defaultUserGroupActionOptions = Array.from(this.defaultUserGroupActionSelectTarget.options);

    this.createMultipleSelectOption(this.userGroupTemplateSelectTarget);
    this.createMultipleSelectOption(this.defaultUserGroupActionSelectTarget);

    [
      [userGroupTemplateOptions, uniqSchoolUserGroups],
      [defaultUserGroupActionOptions, uniqDefaultUserGroupActions],
    ].forEach((optionsArray) => {
      const selectOptions = optionsArray[0];
      const uniqueObjects = optionsArray[1];

      selectOptions.forEach((obj) => {
        const option = obj;

        if (uniqueObjects.length === 1 && option.value === uniqueObjects[0]) {
          option.selected = true;
        } else if (uniqueObjects.length > 1 && option.value === 'multiple') {
          option.hidden = false;
          option.disabled = true;
          option.selected = true;
        }
      });
    });
  }

  createMultipleSelectOption(selectElement) {
    const targetElement = selectElement;
    const selectOptions = targetElement.options;

    let multipleExists = false;

    Array.from(selectOptions).forEach((object) => {
      const option = object;
      if (option.value === 'multiple') {
        multipleExists = true;
        option.hidden = true;
        option.disabled = true;
        option.selected = false;
      }
    });

    if (!multipleExists) {
      const newOption = document.createElement('option');
      newOption.value = 'multiple';
      newOption.innerHTML = I18n.t('assets.javascripts.controllers.multi_academy_trusts.user_group_templates_controller.manage_user_group_templates.default_user_group.multiple_selected');
      newOption.hidden = true;
      newOption.disabled = true;
      newOption.selected = false;
      targetElement.appendChild(newOption);
    }
  }
}
