import { Controller } from 'stimulus';
import {FlashAlert} from '../packs/flash_alerts';

export default class setupAutomaticLoginController extends Controller {

  autoLoginFieldSelected = (event) => {
    const os = event.target.dataset.value;
    const fieldsDropdown = document.querySelector(`#${os}_fields`);

    if (fieldsDropdown.options[fieldsDropdown.selectedIndex].text === 'Email') {
      document.querySelector(`#${os}_remove_domain`).style.display = 'block';
    } else {
      document.querySelector(`#${os}_remove_domain`).style.display = 'none';
    }
  }
}
