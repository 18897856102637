import TypeaheadController from '../typeahead_controller';

export default class SearchesChildrenController extends TypeaheadController {
  onSelection(feedback) {
    const { id } = feedback.selection.value;
    const { url } = feedback.selection.value;

    window.location.href = url;
  }
}
