import { Controller } from 'stimulus';

export default class ConcernDetailsController extends Controller {
  static targets = ['concernDetail']

  connect() {
    const redactedElement = document.createElement('span');
    redactedElement.classList.add('bg-dark');
    redactedElement.innerText = '!REDACTED!';

    const regex = /~{2,}/g;

    this.concernDetailTarget.innerHTML = this.concernDetailTarget.innerHTML.replaceAll(
      regex, redactedElement.outerHTML,
    );
  }
}
