import { Controller } from 'stimulus';
import { useClickOutside } from 'stimulus-use';

export default class UsersController extends Controller {
  static targets = ['selectAllCheckbox', 'userCheckbox', 'editEmailText', 'editEmailForm'];

  connect() {
    useClickOutside(this);
  }

  toggleAllCheckboxes() {
    if (this.selectAllCheckboxTarget.checked) {
      this.userCheckboxTargets.forEach(userCheckbox => userCheckbox.checked = true);
    } else {
      this.userCheckboxTargets.forEach(userCheckbox => userCheckbox.checked = false);
    }
  }

  emailClicked(event) {
    this.hideFields();
    this.showField(event.currentTarget.dataset.id);
  }

  saveEmail(event) {
    const userId = event.currentTarget.dataset.id;
    const newEmail = document.getElementById('user_' + userId + '_email').value;
    event.preventDefault();
    if (this.validDomain(newEmail)) {
      document.getElementById('user_' + userId + '_email').disabled = true;
      this.updateEmail(userId, newEmail);
    } else {
      document.getElementById('user_' + userId + '_email').disabled = false;
      alert(I18n.t('assets.javascripts.controllers.users.bad_domain'));
    }
  }

  validDomain(email) {
    if (this.data.get('domainsValue') === '') return true;
    const re = new RegExp(this.data.get('domainsValue'));
    return re.test(email);
  }

  updateEmail(userId, newEmail) {
    const controller = this;
    $.ajax({
      type: 'post',
      url: '/users/' + userId + '/manual_email_update',
      data: { id: userId, email: newEmail },
      success: function () {
        controller.hideFields();
        document.getElementById('text_' + userId).innerHTML = newEmail + '&nbsp;&nbsp;&nbsp;<i data-id="' + userId + '" data-action="click->users#emailClicked" class="fa fa-pencil"></i>';
      }
    });
  }

  cancelEmail(event) {
    event.preventDefault();
    this.hideFields();
  }

  clickOutside() {
    this.hideFields();
  }

  showField(userId) {
    document.getElementById('form_' + userId).style.display = 'inline-block';
    document.getElementById('text_' + userId).style.display = 'none';
    document.getElementById('user_' + userId + '_email').disabled = false;
    document.getElementById('user_' + userId + '_email').focus();
  }

  hideFields() {
    this.editEmailFormTargets.forEach(emailForm => emailForm.style.display = 'none');
    this.editEmailTextTargets.forEach(emailForm => emailForm.style.display = 'inline-block');
  }
}
