import { German } from 'flatpickr/dist/l10n/de';
import { Dutch } from 'flatpickr/dist/l10n/nl';
import { english } from 'flatpickr/dist/l10n/default';
// Import languages fom flatpickr module as needed

export default function flatpickrLanguage() {
  const locale = document.querySelector('nav.site-nav').dataset.datepicker;
  let language;

  // Switch case statement converts string into flatpickr language object
  switch (locale) {
    case 'German':
      language = German;
      break;
    case 'Dutch':
      language = Dutch;
      break;
    default:
      language = english;
      break;
  }
  return language;
}
