import {Controller} from 'stimulus';

export default class ConcernFormFieldController extends Controller {
  static targets = ["kindSpecific", "concernKinds", "categories"];

  connect() {
    const concernKinds = this.concernKindsTarget
    const selectedConcernKind = concernKinds.options[concernKinds.selectedIndex].value
    this.showCategories(selectedConcernKind)
    this.showKindSpecificFields(selectedConcernKind)
  }

  kindChanged(event) {
    const {target} = event;
    const {value: concernKind} = target;
    this.showKindSpecificFields(concernKind);
    this.showCategories(concernKind, true)
  }

  showKindSpecificFields(concernKind) {
    this.kindSpecificTargets.forEach((target) => {
      const {kind: fieldKind, kindHidden: fieldHidden} = target.dataset;
      if (fieldKind) {
        target.classList.toggle("d-none", !fieldKind.includes(concernKind))
      }

      if (fieldHidden) {
        target.classList.toggle("d-none", fieldHidden.includes(concernKind))
      }
    })
  }

  showCategories(concernKind, clear = false) {
    this.categoriesTargets.forEach(categoriesTarget => {
      const {kind} = categoriesTarget.dataset;
      categoriesTarget.classList.toggle("d-none", concernKind !== kind)

      if (clear) {
        const checkboxElements = categoriesTarget.querySelectorAll("input[type=checkbox]")
        const checkboxes = [...checkboxElements];
        checkboxes.forEach(checkbox => checkbox.checked = false)
      }
    })
  }

  newElementAdded() {
    const concernKinds = this.concernKindsTarget
    const selectedConcernKind = concernKinds.options[concernKinds.selectedIndex].value
    this.showKindSpecificFields(selectedConcernKind)
    this.showCategories(selectedConcernKind)
  }
}
