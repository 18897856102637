import {Controller} from "stimulus";
import { FlashAlert } from "../../packs/flash_alerts";
import {deSelectAllGridItems} from "../../utils";

export default class CapturesIndexAssignmentController extends Controller {
  static targets = ["usersSelect"];

  /**
   * @public
   */
  connect() {
    if (this.hasUsersSelectTarget) {
      this.initializeSelect2();
    }
  }

  /**
   * @public
   * @param event {Event}
   */
  clearForm(event) {
    const {detail} = event;
    const [result] = detail;
    const {action, payload} = result;
    const {message} = payload;
    if (action === "assigned") {
      $(this.usersSelectTarget).val(null).trigger("change");
      $("#assign_users_modal").modal('hide');
      new FlashAlert(FlashAlert.FLASH_ALERT_SUCCESS, message);
      deSelectAllGridItems(this.element)
    }
  }

  /**
   * @private
   */
  initializeSelect2() {
    $(this.usersSelectTarget).select2({
      multiple: true,
      width: '100%',
    });
  }
}
