export class FlashAlert {
  static FLASH_ALERT_ERROR = 'danger';
  static FLASH_ALERT_SUCCESS = 'success';
  static FLASH_ALERT_WARNING = 'warning';
  static FLASH_ALERT_INFO = 'info';
  static FLASH_ALERT_STANDARD = 'secondary';

  static success(message) {
    new FlashAlert(this.FLASH_ALERT_SUCCESS, message)
  }

  static error(message) {
    new FlashAlert(this.FLASH_ALERT_ERROR, message)
  }

  constructor(type = FlashAlert.FLASH_ALERT_STANDARD, message = 'Alert message.', multiple = false) {
    this.type = type;
    this.message = message;
    this.multiple = multiple;
    this.errors_element = document.getElementById('flash-errors');
    this.error_template = document.getElementById('flash-error-template');
    this.displayError();
  }

  displayError() {
    const alert_class = `alert-${this.type}`;
    const template = this.error_template.content.cloneNode(true);
    const main_div = template.querySelector('div');
    main_div.classList.add(alert_class);
    const message_element = template.querySelector('span.alert__message');
    message_element.innerText = this.message;

    if (!this.multiple) {
      while (this.errors_element.firstChild) {
        this.errors_element.firstChild.remove();
      }
    }

    this.errors_element.appendChild(template);
  }
}
