import { Controller } from 'stimulus';

export default class ConcernReplyFormController extends Controller {
  static targets = ["replyUsers", "dropzone", "filesCount"]
  reset() {
    if (this.hasReplyUsersTarget) {
      const changeEvent = new Event('change');
      this.replyUsersTarget.value = null;
      this.replyUsersTarget.dispatchEvent(changeEvent);
    }
    const fileElements = this.dropzoneTarget.querySelectorAll('input[name="file_uploads[]"], .dz-preview')
    const fileElementsArray = Array.from(fileElements);
    if (fileElementsArray.length > 0) {
      this.dropzoneTarget.classList.remove('dz-started');
      this.filesCountTarget.innerHTML = '';

      fileElementsArray.forEach((el) => {
        el.remove();
      });
    }

    this.element.reset();
  }
}
