export function getLinkedMap(linkedGraphElements) {
  return linkedGraphElements
    .reduce((linked, graph) => {
      const key = graph.dataset.chartLinked;
      const copy = linked;
      if (Object.keys(linked).includes(key)) {
        copy[key] = [...linked[key], graph];
      } else {
        copy[key] = [graph];
      }
      return copy;
    }, {});
}

export function calculateMaxValue(graphs = []) {
  const datasets = graphs
    .map((graph) => graph.dataset);

  const chartMax = datasets
    .map((dataset) => dataset.chartMax);
  const maxSet = chartMax
    .some((max) => !!max);

  if (maxSet) {
    const max = chartMax.reduce((accMax, max) => (max > accMax ? max : accMax), 0);
    return parseInt(max, 10);
  }

  return datasets
    .map((dataset) => JSON.parse(dataset.chartData))
    .reduce((accMax, graphData) => {
      const totalCount = Object.values(graphData).reduce((acc, count) => acc + count, 0);
      if (totalCount > accMax) {
        return totalCount;
      }
      return accMax;
    }, 0);
}
