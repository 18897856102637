import { Controller } from 'stimulus';

export default class LayoutController extends Controller {
  static targets = ['option'];

  /**
   * @public
   * @param e {Event}
   */
  change(e) {
    const { currentTarget: layoutButton } = e;
    const { layout } = layoutButton.dataset;
    this.optionTargets.forEach(option => {
      const {layout: optionLayout} = option.dataset;
      option.classList.toggle('layout__option--selected', optionLayout === layout)
    });
    this.data.set('style', layout);

    const sidebar = document.getElementById('page-sidebar')
    if (sidebar) {
      sidebar.classList.toggle("collapse", layout === 'compact');
    }

    const event = new CustomEvent('layout:changed', { detail: layout });
    this.element.dispatchEvent(event);
  }
}
