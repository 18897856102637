import { Controller } from 'stimulus';

export default class CsvImportsController extends Controller {
  static targets = [
    'uploadForm',
    'showUploadFormBtn',
  ];

  toggleUploadFormVisibility() {
    this.uploadFormTarget.classList.toggle('show');
    this.showUploadFormBtnTarget.classList.toggle('show');
  }
}
