/**
 * Flippable
 * Add `data-flippable-container` to the containing element
 * Add `data-flippable` to child elements. Only the first 2 elements will be toggled.
 * Add the class of `d-none` to the second element to have it hidden on load.
*/

function flipper(event) {
  const [front, back] = this.querySelectorAll('[data-flippable]');
  const mouseover = event.type === 'mouseover';
  front.classList.toggle('d-none', mouseover);
  back.classList.toggle('d-none', !mouseover);
}

function initFlippable() {
  const profileNodes = document.querySelectorAll('[data-flippable-container]') || [];
  Array.from(profileNodes).forEach(profile => {
    profile.addEventListener('mouseover', flipper);
    profile.addEventListener('mouseout', flipper);
  });
}

document.addEventListener('DOMContentLoaded', initFlippable);
