import { Controller } from 'stimulus';
import consumer from '../../channels/consumer';
import { replaceOrAppendHtmlFromString } from '../../utils';

export default class DigestSchedulesCardController extends Controller {
  static targets = ['scheduleContainer'];

  connect() {
    const digestSchedulesController = this;
    const { notificationId } = this.element.dataset;

    consumer.subscriptions.create(
      { channel: 'NotificationDigestSchedulesChannel', notification_id: notificationId },
      {
        received(data) {
          digestSchedulesController.received(data);
        },
      },
    );
  }

  async received(data) {
    const { action } = data;
    if (action === 'new_digest_schedule') {
      const { payload } = data;
      const partialUrl = payload.partial_url;

      const response = await fetch(partialUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      });

      const json = await response.json();
      const { html } = json;

      replaceOrAppendHtmlFromString(html, this.scheduleContainerTarget);
    } else if (action === 'delete_digest_schedule') {
      const { notificationId } = this.element.dataset;
      const { payload } = data;
      const digestScheduleId = payload.digest_schedule_id;

      const schedule = document.getElementById(`notifications-${notificationId}-schedules-${digestScheduleId}`);
      schedule.style.transition = '1s';
      schedule.style.opacity = 0;

      setTimeout(() => {
        schedule.remove();
      }, 1000);
    }
  }
}
