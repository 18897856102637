import {Controller} from 'stimulus'

export default class NewsletterController extends Controller {
  static targets = ['modal'];

  connect() {
    if (!this.hasModalTarget) {
      return;
    }

    setTimeout(() => {
      $(this.modalTarget).modal('show');
    }, 1000);
  }

  close(event) {
    $(this.modalTarget).modal('hide');
  }
}

