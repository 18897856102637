import {Controller} from "stimulus";
import {getMetaValue} from "../utils";

export class DropzoneBaseController extends Controller {
  static targets = ["input"];

  /**
   * @public
   * @returns {{"X-CSRF-Token": *}}
   */
  get headers() {
    return {"X-CSRF-Token": getMetaValue("csrf-token")};
  }

  /**
   * @public
   * @returns {string}
   */
  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  /**
   * @public
   * @returns {number/null}
   */
  get maxFiles() {
    const value = this.data.get("maxFiles");

    if (value === "null") {
      return null;
    }
    return value ? parseInt(value) : 1;
  }

  /**
   * @public
   * @returns {number/null}
   */
  get maxFileSize() {
    const value = this.data.get("maxFileSize");
    return value ? parseInt(value) : null;
  }

  /**
   * @public
   * @returns {string}
   */
  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  /**
   * @public
   * @returns {boolean}
   */
  get addRemoveLinks() {
    const value = this.data.get("addRemoveLinks");
    return value ? value === "true" : true;
  }
}
