import {Controller} from 'stimulus'
import consumer from "../channels/consumer";
import "fullscreen-polyfill";

export default class DeviceController extends Controller {
  deviceChannel = null;

  connect() {
    const device_id = this.data.get('id');
    this.deviceChannel = consumer.subscriptions.create(
      { channel: 'DeviceChannel', device_id },
      { connected() { }, received(data) { } },
    );
  }
}
