import { DropzoneBaseController } from '../src/dropzone/dropzone_base_controller';
import { createDirectUploadController, createDropZone } from '../src/dropzone/utils';
import { removeElement } from '../src/utils';

export default class DropzoneController extends DropzoneBaseController {
  get form() {
    return this.element.closest("form");
  }

  get submits() {
    return this.form ? this.form.querySelectorAll("[type='submit']") : [];
  }

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
  }

  /**
   * @private
   */
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = 'none';
  }

  /**
   * @private
   */
  bindEvents() {
    this.dropZone.on('addedfile', (file) => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
      this.submits.forEach(submit => submit.disabled = true);
    });

    this.dropZone.on('removedfile', (file) => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on('canceled', (file) => {
      file.controller && file.controller.xhr.abort();
      const submits = this.element.closest("form").querySelectorAll("[type='submit']");
      submits.forEach(submit => submit.disabled = false);
    });

    this.dropZone.on('queuecomplete', () => {
      this.submits.forEach(submit => submit.disabled = false);
    });
  }
}
