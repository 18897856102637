import { Controller } from 'stimulus';

export default class TopNavController extends Controller {
  connect() {
    const outerScope = this;
    $(this.element).on('show.bs.dropdown', function () {
      outerScope.disablePageScroll();
    });

    $(this.element).on('hide.bs.dropdown', function () {
      outerScope.enablePageScroll();
    });
  }

  enablePageScroll() {
    document.querySelector('body').classList.remove('overflow-hidden-mobile');
  }

  disablePageScroll() {
    document.querySelector('body').classList.add('overflow-hidden-mobile');
  }
}
