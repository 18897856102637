import { Controller } from 'stimulus';

export default class BrowserSupportController extends Controller {
  get userAgent() {
    return window.navigator.userAgent;
  }

  get unsupportedUserAgents() {
    return [
      'MSIE ',
      'Trident/',
      'Edge/1',
    ];
  }

  get browserUnSupported() {
    return this.unsupportedUserAgents.some((unsupportedAgent) => this.userAgent.includes(unsupportedAgent));
  }

  connect() {
    if (this.browserUnSupported) {
      this.element.innerHTML = window.I18n.t('assets.javascripts.application.ie_html');
    }
  }
}
