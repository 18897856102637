import { Controller } from 'stimulus';
import * as Awesomplete from 'awesomplete';

export default class SearchController extends Controller {
  static targets = ['input'];

  async connect() {
    this.disableInput();
    this.searchInput = this.initializeAwesomplete();
    if (!this.searchInput) {
      return;
    }
    const data = await this.getListData();
    this.setSearchList(data);
  }

  open() {
    this.element.classList.add('search__wrapper--active');
  }

  close() {
    this.element.classList.remove('search__wrapper--active');
  }

  selected(event) {
    const { value } = event.text;
    let location = this.data.get('location');
    if (location.search('value') >= 0) {
      location = location.replace('value', value)
    } else {
      location = `${this.data.get('location')}/${value}`;
    }
    window.location.href = location;
  }

  /**
   * @private
   */
  disableInput() {
    this.inputTarget.disabled = true;
    this.defaultPlaceholder = this.inputTarget.placeholder;
  }

  /**
   * @private
   * @param list {Array}
   */
  setSearchList(list = []) {
    this.searchInput.list = list;
    this.inputTarget.placeholder = this.defaultPlaceholder;
    this.inputTarget.disabled = false;
  }

  /**
   * @private
   * @returns {Promise<Array>}
   */
  async getListData() {
    const response = await fetch(this.data.get('endpoint'));
    return await response.json();
  }

  /**
   * @private
   */
  initializeAwesomplete() {
    const label = this.data.get('label');
    const options = {
      data(item) {
        return { label: item[label], value: item.id };
      },
      replace(suggestion) {
        this.input.value = suggestion.label;
      },
    };
    return new Awesomplete(this.inputTarget, options);
  }
}
