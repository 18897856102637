import { Controller } from 'stimulus';

export default class AzureAdMappingsController extends Controller {
  static targets = ['searchBox', 'tableRow', 'tableBody', 'selectedGroupsCounter', 'selectAllMappingCheckbox', 'mappingCheckbox', 'deleteMappingsButton', 'mappingIds', 'mappingsSelectionsTable']

  connect() {
    this.groups = [];
    this.selectedGroupsCounter = 0;
    this.selectedGroupNames = [];

    this.fetchGroupsJson();
  }

  addGroupNameToSelectedGroupNames(groupName) {
    this.selectedGroupNames.push(groupName);
  }

  removeGroupNameFromSelectedGroupNames(groupName) {
    for (let i = 0; i < this.selectedGroupNames.length; i += 1) {
      if (this.selectedGroupNames[i] === groupName) {
        this.selectedGroupNames.splice(i, 1);
      }
    }
  }

  delaySearch(event) {
    const source = event.target;
    let timer = source.getAttribute('data-timer');

    clearTimeout(timer);
    timer = setTimeout(this.searchGroup.bind(this), 1000);
    source.setAttribute('data-timer', timer);
  }

  searchGroup() {
    const searchText = this.searchBoxTarget.value.toLowerCase();
    const rows = this.tableRowTargets;
    const filteredGroups = this.groups.filter(
      (group) => group.displayName.toLowerCase().includes(searchText),
    );
    const groupIds = filteredGroups.map((group) => group.id);

    rows.forEach((row) => {
      const groupId = row.dataset.groupId;
      const rowVisible = groupIds.includes(groupId);
      row.classList.toggle('d-none', !rowVisible);
    });
  }

  async fetchGroupsJson() {
    try {
      const response = await fetch('azure_ad_mappings/azure_ad_groups_json');
      if (!response.ok) {
        throw new Error('Failed to fetch groups');
      }
      const data = await response.json();
      this.groups = data;
      this.buildGroupsTable();
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  }

  buildGroupsTable() {
    if (this.groups.length > 0) {
      this.groups.forEach((group) => {
        const row = `
          <tr data-azure-ad-mappings-target="tableRow" data-group-id="${group.id}" class="azure-ad-mappings__group-row">
            <td>
              <input type="checkbox" value='{"id":"${group.id}","name":"${group.displayName}"}' name="azure_ad_mapping[azure_team_identifier][]" id="azure_ad_mapping_azure_team_identifier_id${group.id}name${group.displayName.replace(/ /g, '_').toLowerCase()}">
            </td>
            <td>${group.displayName}</td>
          </tr>
        `;
        this.tableBodyTarget.insertAdjacentHTML('beforeend', row);
      });

      this.tableRowTargets.forEach((tableRowTarget) => {
        tableRowTarget.addEventListener('change', (event) => {
          const groupName = JSON.parse(event.target.value).name;
          if (event.target.checked === true) {
            this.selectedGroupsCounter += 1;
            this.addGroupNameToSelectedGroupNames(groupName);
          } else {
            this.selectedGroupsCounter -= 1;
            this.removeGroupNameFromSelectedGroupNames(groupName);
          }
          this.updateSelectedGroupsCounterInHTML();
        });
      });
    } else {
      this.tableBodyTarget.innerHTML = I18n.t('assets.javascripts.controllers.azure_mappings_modal.zero_groups_available');
    }
  }

  disableSubmit(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      return false;
    }
    return true;
  }

  selectAll(event) {
    const selectAllTarget = event.target;

    this.mappingsSelectionsVisibleRows().forEach((element) => {
      const mappingCheckBox = element.querySelectorAll('input[type=checkbox]')[0];
      if (selectAllTarget.checked === true && mappingCheckBox.checked === false) {
        mappingCheckBox.checked = true;
        this.selectedGroupsCounter += 1;
        this.addGroupNameToSelectedGroupNames(JSON.parse(mappingCheckBox.value).name);
      } else if (selectAllTarget.checked === false && mappingCheckBox.checked === true) {
        mappingCheckBox.checked = false;
        this.selectedGroupsCounter -= 1;
        this.removeGroupNameFromSelectedGroupNames(JSON.parse(mappingCheckBox.value).name);
      }
    });

    this.updateSelectedGroupsCounterInHTML();
  }

  updateSelectedGroupsCounterInHTML() {
    this.selectedGroupsCounterTarget.innerText = this.selectedGroupsCounterMessage();
  }

  selectedGroupsCounterMessage() {
    if (this.selectedGroupsCounter === 0) {
      return I18n.t('assets.javascripts.controllers.azure_mappings_modal.zero_groups_selected');
    }
    if (this.selectedGroupsCounter === 1) {
      const endOfSentence = I18n.t('assets.javascripts.controllers.azure_mappings_modal.singular_selected');
      return `${this.selectedGroupNames[0]} ${endOfSentence}`;
    }
    const andWord = I18n.t('assets.javascripts.controllers.azure_mappings_modal.and');
    if (this.selectedGroupsCounter === 2) {
      const endOfSentence = I18n.t('assets.javascripts.controllers.azure_mappings_modal.singular_selected');
      return `${this.selectedGroupNames[0]} ${andWord} ${this.selectedGroupNames[1]} ${endOfSentence}`;
    }
    if (this.selectedGroupsCounter === 3) {
      const endOfSentence = I18n.t('assets.javascripts.controllers.azure_mappings_modal.three_groups_selected');
      return `${this.selectedGroupNames[0]}, ${this.selectedGroupNames[1]}, ${andWord} 1 ${endOfSentence}`;
    }
    const endOfSentence = I18n.t('assets.javascripts.controllers.azure_mappings_modal.more_than_three_groups_selected');
    return `${this.selectedGroupNames[0]}, ${this.selectedGroupNames[1]}, ${andWord} ${this.selectedGroupsCounter - 2} ${endOfSentence}`;
  }

  toggleAllMappingCheckboxes() {
    if (this.selectAllMappingCheckboxTarget.checked) {
      this.mappingCheckboxTargets.forEach((mappingCheckbox) => {
        const element = mappingCheckbox;
        element.checked = true;
      });
      this.enableDeleteButton();
    } else {
      this.mappingCheckboxTargets.forEach((mappingCheckbox) => {
        const element = mappingCheckbox;
        element.checked = false;
      });
      this.disableDeleteButton();
    }

    this.updateIds();
  }

  mappingCheckboxToggled() {
    let count = 0;

    this.mappingCheckboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        count += 1;
      }
    });

    if (count === this.mappingCheckboxTargets.length) {
      this.selectAllMappingCheckboxTarget.checked = true;
    } else {
      this.selectAllMappingCheckboxTarget.checked = false;
    }

    if (count > 0) {
      this.enableDeleteButton();
    } else {
      this.disableDeleteButton();
    }

    this.updateIds();
  }

  enableDeleteButton() {
    this.deleteMappingsButtonTarget.classList.remove('azure-ad-mapping__button--disabled');
    this.deleteMappingsButtonTarget.classList.add('azure-ad-mapping__button--delete-all');
  }

  disableDeleteButton() {
    this.deleteMappingsButtonTarget.classList.remove('azure-ad-mapping__button--delete-all');
    this.deleteMappingsButtonTarget.classList.add('azure-ad-mapping__button--disabled');
  }

  updateIds() {
    const mappingIds = [];
    this.mappingCheckboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        mappingIds.push(Number(checkbox.id.split('mapping_id_')[1]));
      }
    });
    this.mappingIdsTarget.value = mappingIds;
  }

  mappingsSelectionsVisibleRows() {
    const table = this.mappingsSelectionsTableTarget.getElementsByTagName('tbody')[0];
    return table.querySelectorAll('tr:not(.d-none).azure-ad-mappings__group-row');
  }
}
