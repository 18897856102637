import TypeaheadController from '../typeahead_controller';
import {replaceOrInsertHtmlFromString, incrementCountElement} from "../../utils";

export default class SearchController extends TypeaheadController {
  static targets = ['addContainer', 'button']

  get role() {
    return this.data.get('role') || 'participant';
  }

  get rolePartialKey() {
    return `${this.role}_html`;
  }

  connect() {
    super.connect();
  }

  onSelection(feedback) {
    const {selection: {value: {partials: {group_member}}}} = feedback;
    const html = group_member[this.rolePartialKey];

    // If addContainerTarget is "+ Children" button container in the form
    if (this.addContainerTarget.classList.contains('add-member')) {
      this.formSelection(html);
    } else {
      this.sidebarSelection(html);
    }

    const groupFormEvent = new CustomEvent('group_form:change', {
      detail: {
        memberId: feedback.selection.value.id,
        quantityChange: +1,
      },
      bubbles: true,
    });
    document.dispatchEvent(groupFormEvent);
  }

  /**
   * Usual selection from the search input within the form
   *
   * @param html
   */
  formSelection(html) {
    replaceOrInsertHtmlFromString(html, this.element, this.addContainerTarget, "before")
  }

  /**
   * Selection from sidebar search input
   *
   * @param html
   */
  sidebarSelection(html) {
    const participantsContainer = document.getElementById('groupParticipantsContainer');
    replaceOrInsertHtmlFromString(html, participantsContainer, participantsContainer.querySelector('#add-member'), "before");
  }

  showSearch(event) {
    this.buttonTarget.classList.add("collapse")
    this.destinationTarget.classList.remove("collapse")
    this.searchTarget.focus()
  }

  reset(event) {
    this.destinationTarget.classList.add("collapse")
    this.buttonTarget.classList.remove("collapse")
    super.reset(event);
  }

  sidebarReset(event) {
    super.reset(event);
  }
}
